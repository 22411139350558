import { mapState, mapGetters } from 'vuex'
export default {
	data() { return {
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		editing_enabled() {return vapp.case_tree_component.editing_enabled },
	},
	watch: {
	},
	methods: {
		initialize_shortcuts() {
			// add listener for keyboard shortcuts (the .off at start eases debugging)
			$(window).off('keydown.shortcuts').on('keydown.shortcuts', evt=>{ this.check_for_shortcut(evt) })
		},

		check_for_shortcut(evt) {
			let handled = false

			// many shortcuts will depend on a framework being open; get the open framework record if there is one
			let open_fr = (!this.framework_list_component) ? null : this.framework_records.find(x=>x.lsdoc_identifier == this.framework_list_component.case_tree_lsdoc_identifier)

			// many shortcuts will depend on the item or document editor being open
			// more specifically, if (the framework viewer is open, the user has editing rights for the framework, and a node or the document is being edited)
			let editor_open = (open_fr && this.is_granted('edit_framework', open_fr.lsdoc_identifier) && (this.case_tree_component.edited_node || this.case_tree_component.editing_document))

			// key on metaKey ("command") for macos and altKey ("alt") for windows ("alt" on mac keyboard *isn't* what we want)
			// also require shift key to be down for all shortcuts
			let shortcut_key = (evt.shiftKey === true && U.meta_or_alt_key(evt))

			if (shortcut_key) {
				// console.log(evt.which, evt)

				// e: edit
				if (evt.which == 69) {
					// skip if editor is already open
					if (editor_open) return

					// if the user doesn't have editing rights or the framework viewer isn't open, we don't do anything
					if (open_fr && this.is_granted('edit_framework', open_fr.lsdoc_identifier)) {
						// if editing isn't already enabled, enable it
						
						if (!this.editing_enabled) {
							// we have to call check_out_for_editing in CASEFrameworkViewer (vapp.case_tree_component), so that we save an archive if appropriate
							vapp.case_tree_component.check_out_for_editing()
							handled = true

						// if an item's tile is showing...
						} else if (open_fr.active_node) {
							// then initiate editing the open tile (document or item)
							if (open_fr.active_node == 1) {
								this.case_tree_component.edit_document('')
								handled = true
							} else {
								// for items, you can't edit mirrors
								if (!vapp.case_tree_component.is_mirror) {
									let item = open_fr.cfo.tree_nodes_hash[open_fr.active_node]
									// also for items, you can't edit derived items in derivative frameworks
									if (!vapp.case_tree_component.derivative_original_json || vapp.case_tree_component.derivative_original_json.CFItems.findIndex(x=>x.identifier == item.cfitem.identifier) == -1) {
										this.case_tree_component.edit_item(item, '')
										handled = true
									}
								}
							}
						}
					}

				// s: Save edits or Suggest associations or Show search results
				} else if (evt.which == 83) {
					// if the editor is open...
					if (editor_open) {
						// save the changes, if there are any
						this.case_tree_component.current_editor.save_changes()
					
					// else if we're showing the associations editor, make suggestions
					} else if (open_fr && this.case_tree_component.show_make_associations_interface) {
						this.case_tree_component.$refs.make_associations_editor.run_assistant()

					// else if there are search results, show the results
					} else if (open_fr && this.case_tree_component.search_result_entries.length > 0) {
						this.case_tree_component.reveal_search_bar()
					}

					// always cancel the cmd-shift-s keydown; it doesn't really make sense to save this webpage anyway
					handled = true
				
				// > (cmd-shift-.): toggle move mode if in edit mode
				} else if (evt.which == 190) {
					if (this.editing_enabled) {
						this.case_tree_component.toggle_move_mode()
					}
					handled = true

				// 9 (left-paren): create a new child item if in edit mode
				} else if (evt.which == 57) {
					if (this.editing_enabled && open_fr.active_node) {
						let item = open_fr.cfo.tree_nodes_hash[open_fr.active_node]
						if (open_fr.active_node == 1) {
							this.case_tree_component.edit_document('create_top_level_item')
						} else {
							this.case_tree_component.edit_item(item, 'add_child')
						}
					}
					handled = true

				// 0 (right-paren): create a new sibling item if in edit mode
				} else if (evt.which == 48) {
					if (this.editing_enabled && open_fr.active_node) {
						let item = open_fr.cfo.tree_nodes_hash[open_fr.active_node]
						this.case_tree_component.edit_item(item, 'add_sibling')
					}
					handled = true

				// c: copy from this or another framework if in edit mode
				} else if (evt.which == 67) {
					if (this.editing_enabled && open_fr.active_node) {
						let item = open_fr.cfo.tree_nodes_hash[open_fr.active_node]
						this.case_tree_component.edit_item(item, 'show_copy_interface')
					}
					handled = true

				// i: batch import if in edit mode
				} else if (evt.which == 73) {
					if (this.editing_enabled && open_fr.active_node) {
						let item = open_fr.cfo.tree_nodes_hash[open_fr.active_node]
						this.case_tree_component.edit_item(item, 'show_text_import_interface')
					}
					handled = true

				// enter: make the association
				} else if (evt.which == 13) {
					if (open_fr && this.case_tree_component.show_make_associations_interface) {
						this.case_tree_component.$refs.make_associations_editor.make_association_from_shortcut()
					}

				// d: done editing (we do the same thing here as saving edits)
				} else if (evt.which == 68) {
					// if the editor is open
					if (editor_open) {
						// save the changes, if there are any, and force the editor to close right away
						this.case_tree_component.current_editor.save_changes('and_done')
						handled = true
					}

				// o: add a comment to the tile that's currently showing (can't use cmd-c because that's copy)
				} else if (evt.which == 79) {
					if (open_fr && this.signed_in) {
						if (!this.case_tree_component.show_comments) {
							// if tile_comments_component is empty, the user probably hasn't turned comment mode on
							this.case_tree_component.show_comments = true
						}

						// delay this for a tick in case we just turned show_comments on
						setTimeout(x=>{
							if (vapp.tile_comments_component && vapp.tile_comments_component.create_new_comment) {
								vapp.tile_comments_component.create_new_comment()
							}
						}, 50)
					}

				// up(38)/down(40) arrow: move to the previous/next item in the tree
				} else if (evt.which == 38 || evt.which == 40) {
					// skip if editor is open
					if (editor_open) return

					if (open_fr) {
						let new_node

						// if no node is marked as last_clicked_node or active_node, open the document (node 1)
						if (!open_fr.last_clicked_node && !open_fr.active_node) {
							new_node = open_fr.cfo.cftree

						// if we have a last_clicked_node but don't have an active_node, go to the last_clicked_node
						} else if (open_fr.last_clicked_node && !open_fr.active_node) {
							new_node = open_fr.cfo.tree_nodes_hash[open_fr.last_clicked_node]

						} else {
							// get the active node
							let node = open_fr.cfo.tree_nodes_hash[open_fr.active_node]

							// and try to move up...
							if (evt.which == 38) {
								// if last_clicked_node is 1, we can't move up (so we should do nothing); else find the previous item
								if (open_fr.last_clicked_node != 1) {
									// if this node is the first child of its parent, move to the parent
									let index = node.parent_node.children.findIndex(x=>x.tree_key == node.tree_key)
									if (index == 0) {
										new_node = node.parent_node

									} else {
										// else temporarily move to the previous child of the parent
										node = node.parent_node.children[index-1]

										// but if the previous child has children, move down as far as we can to the last possibile descendent
										while (node.children.length > 0) {
											node = node.children[node.children.length-1]
										}

										new_node = node
									}
								}

							// or down
							} else {
								// if this node has children, go to the first child
								if (node.children.length > 0) {
									new_node = node.children[0]

								} else {
									// if this node isn't the last child of its parent, move to it
									let index = node.parent_node.children.findIndex(x=>x.tree_key == node.tree_key) + 1
									if (index < node.parent_node.children.length) {
										new_node = node.parent_node.children[index]

									} else {
										// else move up until we find a parent that has a next sibling
										node = node.parent_node
										// if we get to the root, we're at the bottom of the tree so we can't go anywhere
										while (node.tree_key != 1) {
											// if the parent has a next sibling, go to it
											index = node.parent_node.children.findIndex(x=>x.tree_key == node.tree_key) + 1
											if (index < node.parent_node.children.length) {
												new_node = node.parent_node.children[index]
												break
											}

											// else move up one another parent
											node = node.parent_node
										}
									}
								}

							}
						}
						// console.log(sr('last_clicked: $1, active: $2, new_identifier: $3', open_fr.last_clicked_node, open_fr.active_node, new_identifier))
						if (new_node) {
							// show the next item
							this.case_tree_component.make_node_parents_open(new_node.tree_key)
							this.case_tree_component.make_node_open(new_node.tree_key)
							this.case_tree_component.make_node_active(new_node.tree_key, true)
							this.$store.commit('set', [open_fr, 'last_clicked_node', new_node.tree_key])

							// if we're making associations, select the newly-selected node
							if (this.case_tree_component.show_make_associations_interface && !this.case_tree_component.viewing_archive && this.case_tree_component.show_chooser_fn) {
								this.case_tree_component.show_chooser_fn(null, new_node, null)
							}
						}

						handled = true
					}

				// left/right arrow plus shift key: move to the previous/next item in the search list
				} else if (evt.shiftKey && (evt.which == 37 || evt.which == 39)) {
					// skip if editor is open
					if (editor_open) return

					if (this.case_tree_component.search_result_entries.length > 0) {
						this.case_tree_component.search_panel_to_back()
						let direction = (evt.which == 37) ? -1 : 1
						this.case_tree_component.jump_to_search(direction)
						handled = true
					}

				// ! (shift-1): run custom script
				} else if (evt.which == 49) {
					if (open_fr) {
						this.case_tree_component.run_custom_script()
						handled = true
					}
				}
			}

			if (handled) {
				evt.stopPropagation()
				evt.preventDefault()
			}
		},
	}
}
