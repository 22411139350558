<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template><v-dialog v-model="dialog_open" overlay-opacity="0.9" :max-width="stage==1?640:860" persistent scrollable content-class="k-canvas-import-dialog"><v-card>
<v-card-title style="border-bottom:1px solid #999">
	<div><b class="mr-4">Export to Canvas Spreadsheet Format</b> Step {{ stage }}</div>
	<v-spacer></v-spacer>
	<v-btn color="secondary" x-small fab @click="$emit('dialog_cancel')"><v-icon>fas fa-xmark</v-icon></v-btn>
</v-card-title>
<v-card-text class="k-canvas-import-card mt-3" style="font-size:16px; line-height:21px;">
	<div>
		<div style="background-color:#eee; border-radius:6px" class="pa-2">This tool will generate a CSV file in Canvas <a href="https://canvas.instructure.com/doc/api/file.outcomes_csv.html" target="_blank">“Outcomes Import Format”</a>, which you can then upload to a Canvas course or district Canvas implementation. See this <a href="https://community.canvaslms.com/t5/Instructor-Guide/How-do-I-import-outcomes-for-a-course/ta-p/702">Canvas help document</a> for more information.</div>
	</div>
	<div v-if="stage==1">
		<div class="mt-3"><b>Step 1:</b> To start the process, <b>choose which “branches” of the framework you wish to import to Canvas</b>, by checking the boxes in the framework tree shown at right. If you don’t choose any branches and click the “MOVE TO STAGE 2” button, the entire framework will be imported.</div>
		<div class="mt-3" v-if="branches_to_import.length==0"><li><b>Entire framework</b> will be imported</li></div>
		<div class="mt-3" v-else><li><b>{{ branches_to_import.length }} {{ U.ps('branch', branches_to_import.length, 'branches') }}</b> selected</li></div>
		<div class="mt-3 text-right">
			<v-btn small color="primary" @click="go_to_stage_2">Go To Step 2 <v-icon small class="ml-2">fas fa-arrow-right</v-icon></v-btn>
		</div>
	</div>
	<div v-if="stage==2">
		<div class="mt-3"><b>Step 2:</b> Next, <b>choose which framework item types should be imported</b>, as well as whether each type should be considered <b>“outcomes” and “groups”</b> in Canvas. Canvas “groups” preserve, at least to some extent, the “tree structure” of the framework as represented in {{site_config.app_name}}; see the Canvas help docs linked above.</div>
		<div class="mt-3">By default, the “lowest-level” item type will be selected as outcomes, and all other types will be selected as groups. But you may wish to change this default. For example, many frameworks include item types such as “Grade Level”, “Strand”, “Cluster”, “Standard”, and “Component”. You might choose to import Grade Levels, Strands, and Clusters as groups, import Standards as outcomes, and not import Components at all.</div>
		<div class="mt-3">Note that at least one item type must be designated as “outcome” to proceed.</div>
		<div class="d-flex align-end">
			<div style="flex:1 0 auto">
				<h3 class="mt-3 mb-2 grey--text text--darken-3"><b>Item Types:</b></h3>
				<div v-for="(val, item_type) in item_types" :key="item_type" class="py-2 pl-2" style="border-bottom:1px solid #ccc">
					<div class="d-flex align-center">
						<div><b class="grey--text text--darken-3">{{ item_type }}</b> ({{item_types[item_type].count}})</div>
						<div class="ml-2"><v-btn x-small text color="secondary" @click="get_item_type_example(item_type)">Sample<v-icon v-if="item_type_examples[item_type]" small class="ml-2">fas fa-recycle</v-icon></v-btn></div>
						<v-spacer/>
						<v-checkbox class="mt-0 pt-0 ml-3" label="Group" v-model="item_types[item_type].group" @change="item_type_updated(item_type, 'group')" hide-details></v-checkbox>
						<v-checkbox class="mt-0 pt-0 ml-3 mr-2" label="Outcome" v-model="item_types[item_type].outcome" @change="item_type_updated(item_type, 'outcome')" hide-details></v-checkbox>
					</div>
					<div v-show="item_type_examples[item_type]" class="ml-4 mt-1" style="font-size:14px; line-height:18px; max-width:560px;" v-html="item_type_examples[item_type]"></div>
				</div>
			</div>
			<div style="flex:0 0 160px" class="ml-4">
				<v-btn small class="k-tight-btn mb-2" color="primary" block @click="go_to_stage_1"><v-icon small class="mr-2">fas fa-arrow-left</v-icon>Back</v-btn>
				<v-btn small class="k-tight-btn" :disabled="!ready_to_process" color="primary" block @click="go_to_stage_3">Go To Step 3 <v-icon small class="ml-2">fas fa-arrow-right</v-icon></v-btn>
			</div>
		</div>
	</div>
	<div v-if="stage==3" class="mt-4">
		<div class="mt-3"><b>Step 3:</b> Next, choose from the following options. Each of these settings will be applied to every outcome in the exported file.</div>
		<div class="d-flex align-end mt-3">
			<div style="flex:1 0 auto">
				<div class="d-flex align-center">
					<div class="mr-2">Calculation Method:</div>
					<div style="width:200px"><v-select v-model="settings.calculation_method" :items="calculation_methods" dense outlined hide-details></v-select></div>
				</div>
				<div v-if="['decaying_average','n_mastery'].includes(settings.calculation_method)" class="mt-2 pt-2 d-flex align-center" style="border-top:1px solid #ccc">
					<div class="mr-2">Calculation Integer:</div>
					<div style="width:80px"><v-text-field dense outlined hide-details label="" v-model="settings.calculation_int" placeholder=""></v-text-field></div>
					<div class="ml-2" style="font-size:14px; line-height:18px; max-width:400px">(required if Calculation Method is decaying_average or n_mastery; see Canvas help docs)</div>
				</div>
				<div class="mt-2 pt-2 d-flex align-center" style="border-top:1px solid #ccc">
					<div class="mr-2">Mastery Points:</div>
					<div style="width:80px"><v-text-field dense outlined hide-details label="" v-model="settings.mastery_points" placeholder=""></v-text-field></div>
					<div class="ml-2" style="font-size:14px; line-height:18px; max-width:400px">(can be blank, but usually specified if “ratings” are specified below)</div>
				</div>
				<div style="border-top:1px solid #ccc" class="mt-2 pt-2">
					<div>Ratings: (specify up to 6 ratings levels and descriptions)</div>
					<div v-for="(o, index) in settings.ratings" :key="index" class="mt-3 d-flex align-center">
						<div style="width:80px" class="mr-2"><v-text-field dense outlined hide-details label="Points" v-model="o.points" placeholder=""></v-text-field></div>
						<div style="width:460px"><v-text-field dense outlined hide-details label="Description" v-model="o.description" placeholder=""></v-text-field></div>
					</div>
				</div>
			</div>
			<div style="flex:0 0 160px" class="ml-4">
				<v-btn small class="k-tight-btn mb-2" color="primary" block @click="go_to_stage_2"><v-icon small class="mr-2">fas fa-arrow-left</v-icon>Back</v-btn>
				<v-btn small class="k-tight-btn" color="primary" block @click="go_to_stage_4">Prepare CSV <v-icon small class="ml-2">fas fa-arrow-right</v-icon></v-btn>
			</div>
		</div>
	</div>
	<div v-if="stage==4" class="mt-4">
		<div class="d-flex align-end">
			<div>
				<h3 class="pink--text text--darken-4">Ready to Download</h3>
				<div class="mt-3"><b>Step 4:</b> Click to download a CSV file with:
					<ul>
						<li>{{rows.length}} total {{U.ps('row',rows.length)}}<ul>
							<li>{{group_count}} “{{U.ps('group', outcome_count)}}”</li>
							<li>{{outcome_count}} “{{U.ps('outcome', outcome_count)}}”</li>
						</ul></li>
					</ul>
				</div>
			</div>
			<v-spacer/>
			<div style="flex:0 0 160px" class="ml-4">
				<v-btn small class="k-tight-btn mb-2" color="primary" block @click="go_to_stage_3"><v-icon small class="mr-2">fas fa-arrow-left</v-icon>Back</v-btn>
				<v-btn class="k-tight-btn" color="pink darken-4" dark block @click="download_csv">Download CSV <v-icon small class="ml-2">fas fa-file-arrow-down</v-icon></v-btn>
			</div>
		</div>
	</div>
</v-card-text>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
// components: { CanvasImport },
	props: {
		framework_record: { type: Object, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		stage: 1,
		ready_to_process: false,
		rows: [],
		outcome_count: 0,
		group_count: 0,
		item_types: {},	// structure: {'Cluster':{group:true, outcome:false}, 'Standard':{group:false, outcome:true}, 'Component':{group:false, outcome:false} }
		item_type_examples: {},
		branches_to_import: [],

		calculation_methods: [
			'average',
			'decaying_average',
			'n_mastery',
			'highest',
			'latest',
			'none [blank]'
		],

		settings: {},
	}},
	computed: {
		...mapState(['site_config']),
		...mapGetters([]),
	},
	watch: {
		item_types: {immediate: false, deep: true, handler(val) {
			this.$store.commit('lst_set_hash', ['canvas_export_item_types', this.framework_record.lsdoc_identifier, this.item_types])
			// this.ready_to_download = false
			for (let i in this.item_types) {
				if (this.item_types[i].outcome) {
					this.ready_to_process = true
					return
				}
			}
			this.ready_to_process = false
		}},
	},
	created() {
		vapp.canvas_import_component = this

		// pull initial branches_to_import from lst
		this.branches_to_import = this.$store.state.lst.canvas_branches_to_import[this.framework_record.lsdoc_identifier] ?? []

		// get initial settings from lst
		let o = object_copy(this.$store.state.lst.canvas_export_settings ?? {})
		o = {}
		if (empty(o.calculation_method)) o.calculation_method = 'average'
		if (empty(o.calculation_int)) o.calculation_int = 1
		if (empty(o.mastery_points)) o.mastery_points = 3
		if (empty(o.ratings)) o.ratings = [
			{points:'4', description:'Highly Proficient'},
			{points:'3', description:'Proficient'},
			{points:'2', description:'Partially Proficient'},
			{points:'1', description:'Minimally Proficient'},
			{points:'', description:''},
			{points:'', description:''},
		]
		this.settings = o

		// start on stage 1; have to delay this a scoche so that the hide_fn (see below) doesn't immediately return true and close SatchelInline
		setTimeout(x=>this.go_to_stage_1())
	},
	mounted() {
	},
	methods: {
		show_branch_chooser() {
			// push the dialog over to the left, so we have room for the chooser on the right
			setTimeout(x=>$('.k-canvas-import-dialog').parent().css('justify-content', 'flex-start'), 100)

			let data = {
				framework_identifier: this.framework_record.lsdoc_identifier,
				selected_items: object_copy(this.branches_to_import),
			}
			console.log(data)

			let show_data = { 
				// fn called continuously while embedded satchel is open; if it returns true, embedded satchel will be closed
				hide_fn: ()=>{ return ($('.k-canvas-import-card').is(':visible') == false) },

				show_left_controls: false,
				force_size: 'small',
				// small_frame_top: 60,
				// small_frame_width: this.satchel_embed_width
			}

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((chosen_item) => {
						let i = this.branches_to_import.findIndex(identifier=>identifier==chosen_item.cfitem.identifier)
						if (i > -1) {
							// if user previously chose this item, remove it
							this.branches_to_import.splice(i, 1)
						} else {
							// else add it
							this.branches_to_import.push(chosen_item.cfitem.identifier)
						}
						this.$store.commit('lst_set_hash', ['canvas_branches_to_import', this.framework_record.lsdoc_identifier, object_copy(this.branches_to_import)])
						this.show_branch_chooser()
					})
				})
			})
		},

		hide_branch_chooser() {
			vapp.$refs.satchel.execute('hide')
			$('.k-canvas-import-dialog').parent().css('justify-content', 'center')
		},

		go_to_stage_1() {
			this.show_branch_chooser()
			this.stage = 1
		},

		go_to_stage_2() {
			this.hide_branch_chooser()

			// recursive fn for finding types
			let arr = [], counts = {}
			let find_item_types = (node) => {
				let type = U.item_type_string(node.cfitem)
				if (!empty(type)) {
					if (!arr.includes(type)) {
						arr.push(type)
						counts[type] = 0
					}
					++counts[type]
				}

				for (let child of node.children) {
					find_item_types(child)
				}
			}

			if (this.branches_to_import.length == 0) {
				find_item_types(this.framework_record.cfo.cftree)
			} else {
				for (let branch_identifier of this.branches_to_import) {
					find_item_types(this.framework_record.cfo.cfitems[branch_identifier].tree_nodes[0])
				}
			}

			// make the order of it_arr match the order in cfo's item_types
			let it_arr = []
			for (let type of this.framework_record.cfo.item_types) {
				if (arr.includes(type)) it_arr.push(type)
			}
			console.warn(counts)

			// get previously-stored item type settings for this framework
			let oo = this.$store.state.lst.canvas_export_item_types[this.framework_record.lsdoc_identifier] || {}
			// oo = {}	// debug -- reset instead of restoring

			// construct updated item type settings
			let o = {}
			for (let i = 0; i < it_arr.length; ++i) {
				let item_type = it_arr[i]
				let t = oo[item_type]
				if (t) {
					o[item_type] = {group: t.group, outcome: t.outcome}
				} else {
					if (i < it_arr.length - 1) {
						o[item_type] = {group: true, outcome: false}
					} else {
						o[item_type] = {group: false, outcome: true}
					}
				}
				o[item_type].count = counts[item_type]
			}
			this.item_types = o

			this.stage = 2
		},

		item_type_updated(item_type, cls) {
			let o = this.item_types[item_type]

			// when user changes cls to ON, make sure the other cls is OFF
			if (cls == 'group' && o.group) o.outcome = false
			if (cls == 'outcome' && o.outcome) o.group = false
		},

		get_item_type_example(item_type) {
			let keys = Object.keys(this.framework_record.cfo.cfitems)
			U.shuffle_array(keys)
			for (let key of keys) {
				let cfi = this.framework_record.cfo.cfitems[key]
				if (U.item_type_string(cfi) == item_type) {
					this.$set(this.item_type_examples, item_type, U.generate_cfassociation_node_uri_title(cfi, 150, true))
					return
				}
			}
			this.$set(this.item_type_examples, item_type, '???')
		},

		go_to_stage_3() {
			this.stage = 3
		},

		go_to_stage_4() {
			// sort ratings and trim descriptions
			this.settings.ratings.sort((a,b) => b.points - a.points)
			for (let i = 0; i < this.settings.ratings.length; ++i) this.settings.ratings[i].description = $.trim(this.settings.ratings[i].description)

			// save settings to lst
			this.$store.commit('lst_set', ['canvas_export_settings', object_copy(this.settings)])

			// https://canvas.instructure.com/doc/api/file.outcomes_csv.html
			// vendor_guid, object_type, title,             description,             display_name, calculation_method, calculation_int, workflow_state, parent_guids, 
			// b,           group,       Child group,       child group description, G-1.1,        XX,                 XX,              active,         a,
			// c,           outcome,     Learning Standard, outcome description,     LS-100,       decaying_average,   40,              active,         a b, 

			// https://docs.google.com/spreadsheets/d/1EjskpRdNPQ0clxav8Wd9mdGOwcd-fjQG/edit?gid=940659245#gid=940659245
			// Deer Valley puts HCS in "title"; has a combo of HCS and fullStatement in both "description" and "display_name"; does not use "friendly_description"

			let rows = [], outcome_count = 0, group_count = 0
			let header = [
				'vendor_guid',
				'object_type',
				'title',
				'description',
				'display_name',
				'calculation_method',
				'calculation_int',
				'workflow_state',	// NOTE: Canvas lists 'parent_guids' before 'workflow_state' in their table of fields, but their sample has workflow_state first; looks like the order of columns doesn't matter
				'parent_guids',		// NOTE: Canvas's sample implies that this must be a list of all the parent guids, but DV's sample implies that we just need the immediate parent
				'mastery_points',
			]
			if (this.settings.ratings[0].points > 0) header.push('ratings')
			rows.push(header)

			/////////////////////////////
			// recursive function for adding rows
			let add_row = (node, parent_guid, single_item) => {
				let cfi = node.cfitem

				let object_type = 'skipped'
				let ito = this.item_types[U.item_type_string(cfi)]
				// don't add rows for types that aren't specified as either groups or outcomes (unless single_item is true); but we do process children of skipped types
				if (single_item || ito?.group || ito?.outcome) {
					let fs = cfi.title || cfi.fullStatement

					let title = cfi.humanCodingScheme ? cfi.humanCodingScheme : fs
					object_type = (ito && ito.outcome) ? 'outcome' : 'group'
					let description = (cfi.humanCodingScheme ? cfi.humanCodingScheme + ': ' : '') + fs

					// display_name is limited to max 255 characters
					let display_name = U.truncate_to_word(description, 255)

					let calculation_method, calculation_int, mastery_points
					if (object_type == 'group') {
						// for 'group's, Canvas doesn't show the description, so have to put the fullStatement in the title; use the truncated version in case Canvas doesn't like long values
						title = display_name
						// these should always be empty for groups
						calculation_method = ''
						calculation_int = ''
						mastery_points = ''

						group_count += 1

					} else {	// outcome
						calculation_method = this.settings.calculation_method
						calculation_int = ['decaying_average','n_mastery'].includes(this.settings.calculation_method) ? this.settings.calculation_int : ''
						mastery_points = this.settings.mastery_points

						outcome_count += 1
					}

					let row = [
						cfi.identifier,		// vendor_guid
						object_type,		// object_type
						title,				// title
						description,		// description
						display_name,		// display_name
						calculation_method,	// calculation_method
						calculation_int,	// calculation_int
						'active',			// workflow_state
						parent_guid,		// parent_guids
						mastery_points,		// mastery_pointss
					]

					// add ratings for outcomes, or blank columns for groups
					for (let r of this.settings.ratings) {
						if (r.points > 0) {
							if (object_type == 'outcome') {
								row.push(r.points)
								row.push(r.description)
							} else {
								row.push('')
								row.push('')
							}
						}
					}

					rows.push(row)
				}

				// if single_item is true, return here
				if (single_item) return

				// children are put under this item if this item is a group, or under the incoming parent_guid if this item is an outcome
				let child_parent = (object_type == 'group') ? cfi.identifier : parent_guid

				for (let child_node of node.children) {
					add_row(child_node, child_parent)
				}
			}

			// import the whole tree, or branches if specified
			if (this.branches_to_import.length == 0) {
				add_row(this.framework_record.cfo.cftree, '')
			} else {
				// always add a row for the framework document first
				add_row(this.framework_record.cfo.cftree, '', true)
				for (let branch_identifier of this.branches_to_import) {
					// each branch's parent is the document
					add_row(this.framework_record.cfo.cfitems[branch_identifier].tree_nodes[0], this.framework_record.lsdoc_identifier)
				}
			}
			console.log(rows)

			this.rows = rows
			this.group_count = group_count
			this.outcome_count = outcome_count

			// show the loader for a tick so the user thinks we're doing something hard
			U.loading_start()
			setTimeout(x=>{
				U.loading_stop()
				this.stage = 4
				this.ready_to_process = false
			}, 500)
		},

		download_csv() {
			let date_string = date.format(new Date(), 'YYYY-MM-DD')
			let filename = `${this.framework_record.json.CFDocument.title}-CANVAS-OUTCOMES-${date_string}.csv`
			U.download_file(CSV.stringify(this.rows), filename)
		}
	}
}
</script>

<style lang="scss">
.k-canvas-import-dialog {
	
}
</style>