<template>
	<v-dialog v-model="dialog_open" max-width="800" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Clean Framework JSON</b></v-card-title>
			<v-card-text class="mt-3 k-reduce-file-size-checkboxes" style="font-size:16px">
				<div>Use any combination of the following options to “clean” the framework’s JSON file. Most options will serve to reduce the size of the framework’s JSON file, in ways that do not lose any significant data.</div>
				<div>
					<v-checkbox v-model="options_basic"><template v-slot:label><div style="align-self:flex-bottom">Basic cleanup<div class="k-reduce-file-size-label-details">Remove parameters from Items and Associations that are both optional and empty. This is all completely safe; no data is lost.</div></div></template></v-checkbox>
					<v-checkbox v-model="options_fix_sequenceNumber"><template v-slot:label><div style="align-self:flex-bottom">Fix sequenceNumbers<div class="k-reduce-file-size-label-details">Fix any “isChildOf” associations that are missing sequenceNumbers, or have sequenceNumber = “0” (0 sequenceNumbers cause problems in OpenSALT, and missing sequenceNumbers may be problematic for other CASE consumers).</div></div></template></v-checkbox>
					<v-checkbox v-model="options_keep_valid"><template v-slot:label><div style="align-self:flex-bottom">Remove additional superfluous parameters<div class="k-reduce-file-size-label-details">Remove additional superfluous parameters that are not generally needed in the CASE JSON (more specifically, this additional step will remove “conceptKeywordsURI” fields, expressing them in the more compact “conceptKeywords” fields; and will remove any “licenseURI” fields from items, since licenses are more efficiently specified at the document level [licenses in the document node will not be touched]).</div></div></template></v-checkbox>
					<v-checkbox v-model="options_remove_orphans"><template v-slot:label><div style="align-self:flex-bottom">Remove “orphans”<div class="k-reduce-file-size-label-details">Remove any items or associations that are not viewable in your framework—that is, items that are not linked via “isChildOf” relationship(s) to the document root, and any associations that refer to such items. Do not do this if your framework purposely includes items from other frameworks that aren’t actually part of the document tree structure.</div></div></template></v-checkbox>
					<v-checkbox v-model="options_remove_duplicate_is_child_ofs"><template v-slot:label><div style="align-self:flex-bottom">Remove duplicate isChildOf associations<div class="k-reduce-file-size-label-details">Remove duplicate “isChildOf” associations—that is, “isChildOf” associations that have exactly the same origin and destination as another isChildOf association in the CFAssociations array. If such duplicate isChildOf associations exist it is almost certainly because of some bug or mistake in coding, so they should be removed.</div></div></template></v-checkbox>
				</div>

				<div class="mt-2 elevation-3 pa-3" style="border-radius:8px; border:1px solid #999;" v-if="original_json_length>0">
					<div v-if="reduction==0">
						This process will not reduce the size of the framework’s JSON file ({{final_json_length.toLocaleString()}} bytes).
						<div v-if="cmd_clicked" class="mt-2"><i>Since you held the cmd/ctrl key down while clicking the “SIMULATE” button, you can save the processed JSON anyway by clicking the button below.</i></div>
					</div>
					<div v-else>
						<div>This process will reduce the size of the framework’s JSON file from {{original_json_length.toLocaleString()}} bytes to {{final_json_length.toLocaleString()}} bytes (or possibly smaller), a savings of at least <b>{{reduction}}%</b>.</div>
						<div v-if="options_remove_orphans" class="my-2"><ul>
							<li>Orphaned items to be removed: {{remove_orphan_items_results.CFItems.length}} <v-btn x-small color="primary" @click="show_orphaned_items">show</v-btn></li>
							<li>Orphaned associations to be removed: {{remove_orphan_items_results.CFAssociations.length}} <v-btn x-small color="primary" @click="show_orphaned_assocs">show</v-btn></li>
						</ul></div>
						<div>Click “Save Reduced JSON” below to save the reduced JSON file.</div>
					</div>
					<div v-if="fixed_sequenceNumbers > -1">{{fixed_sequenceNumbers}} association(s) will have their sequenceNumbers fixed.</div>
				</div>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-btn small color="secondary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn small color="primary" @click="simulate"><v-icon small class="mr-2">fas fa-eye</v-icon> Simulate File Size Reduction</v-btn>
				<v-btn small v-if="reduction > 0 || fixed_sequenceNumbers > 0 || cmd_clicked" class="ml-3" color="primary" @click="save"><v-icon small class="mr-2">fas fa-save</v-icon> Save Reduced JSON</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	// components: { ReduceFileSize },
	props: {
		framework_record: { type: Object, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		options_basic: true,
		options_fix_sequenceNumber: true,
		options_keep_valid: false,
		options_remove_orphans: false,
		options_remove_duplicate_is_child_ofs: false,
		report: '',
		original_json_length: 0,
		final_json_length: 0,
		remove_orphan_items_results: {},
		fixed_sequenceNumbers: -1,
		reduction: 0,
		new_json: {},
		cmd_clicked: false
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		show_orphaned_items() {
			let s = '<table style="border-collapse:collapse">'
			for (let item of this.remove_orphan_items_results.CFItems) {
				item = this.framework_record.json.CFItems.find(x=>x.identifier == item.identifier)
				s += sr('<tr style="border:1px solid #ccc;"><td style="padding:2px;font-size:9px;vertical-align:top;white-space:nowrap;">$1</td><td style="padding:2px 8px;font-size:12px;vertical-align:top;"><b>$2</b></td><td style="padding:2px;font-size:12px;vertical-align:top;">$3</td></tr>', item.identifier, item.humanCodingScheme, item.fullStatement)
			}
			s += '</table>'
			this.$alert({text: s, dialogMaxWidth: '90vw'})
		},
		show_orphaned_assocs() {
			let s = '<table style="border-collapse:collapse">'
			for (let assoc of this.remove_orphan_items_results.CFAssociations) {
				assoc = this.framework_record.json.CFAssociations.find(x=>x.identifier == assoc.identifier)
				s += sr('<tr style="border:1px solid #ccc;"><td style="padding:2px;font-size:9px;vertical-align:top;white-space:nowrap;">$1</td><td style="padding:2px 8px;font-size:12px;vertical-align:top;"><b>$2</b></td><td style="padding:2px;font-size:12px;vertical-align:top;">$3</td><td style="padding:2px;font-size:12px;vertical-align:top;">$4</td></tr>', assoc.identifier, assoc.associationType, assoc.originNodeURI.title, assoc.destinationNodeURI.title)
			}
			s += '</table>'
			this.$alert({text: s, dialogMaxWidth: '90vw'})
		},
		simulate(evt) {
			this.cmd_clicked = (evt && evt.metaKey === true)

			// build list of fns to run
			let fns = []

			if (this.options_basic) {
				fns.push('remove_empty_properties')
				fns.push('remove_CFDocumentURIs')
			}

			if (this.options_fix_sequenceNumber) {
				fns.push('remove_zero_sequenceNumbers')
			}

			if (this.options_keep_valid) {
				// fns.push('remove_ItemTypeURIs')
				fns.push('remove_ConceptKeywordsURIs')
				fns.push('remove_licenseURIs')
				fns.push('shorten_node_titles')
			}

			if (this.options_remove_duplicate_is_child_ofs) {
				fns.push('remove_duplicate_is_child_ofs')
			}

			if (this.options_remove_orphans) {
				fns.push('remove_orphan_items')
			}

			if (this.options_max_compress) {
				fns.push('remove_uris')
				fns.push('reduce_nodes')
				fns.push('remove_timezones')
				fns.push('reduce_key_lengths')
			}

			U.reduce_case_json(this.$worker, this.framework_record.json, fns).then((rv)=>{
				this.new_json = rv.json
				let fn_return_vals = rv.fn_return_vals
				console.log(this.new_json, fn_return_vals)

				this.fixed_sequenceNumbers = -1
				if (fn_return_vals && fn_return_vals.remove_zero_sequenceNumbers) {
					this.fixed_sequenceNumbers = fn_return_vals.remove_zero_sequenceNumbers.zero_update_count + fn_return_vals.remove_zero_sequenceNumbers.empty_update_count
				}

				this.original_json_length = JSON.stringify(this.framework_record.json).length
				this.final_json_length = JSON.stringify(this.new_json).length
				this.reduction = Math.round((1 - this.final_json_length / this.original_json_length) * 10000) / 100
				this.remove_orphan_items_results = fn_return_vals.remove_orphan_items

				// in the event remove_zero_sequenceNumbers adds a few bytes, make sure this.reduction isn't less than 0
				if (this.reduction < 0) this.reduction = 0
			})
		},

		save() {
			U.loading_start('Saving JSON…')

			// set lsdoc_identifier in the json, then save using the same save_framework_data fn that we use for creating new frameworks and saving changes to frameworks
			this.new_json.lsdoc_identifier = this.new_json.CFDocument.identifier
			this.new_json.replace_objects = 'yes'	// replace everything, as opposed to only updating fields that get sent in

			this.$store.dispatch('save_framework_data', this.new_json).then((result)=>{
				// reload the page to make sure we get everything updated
				vapp.reload_window()
			})
		},
	},
}
</script>

<style lang="scss">
.k-reduce-file-size-checkboxes {
	.v-input__slot { align-items: flex-start; }
}

.k-reduce-file-size-label-details {
	margin-top:4px;
	font-size:14px;
}
</style>
