// to run this script: tap cmd-shift-1 on keyboard; enter `update_derivative_use_aliases`
if (!window.custom_scripts) window.custom_scripts = {}
window.custom_scripts.convert_help_docs = {
	input_required: false,
	payload: {},
	log: '',

	original_help_toc: {
		// 16 incomplete
		overview: 				{ search_match:false, title: 'Overview', expanded: false, children: {
			framework_index: 	{ search_match:false, title: this.framework_list_title },
		// overview: 				{ search_match:false, title: '[[app_name]] Overview', expanded: false, children: {
		// 	framework_index: 	{ search_match:false, title: '[[framework_list_title]]' },
			adoption_status: 	{ search_match:false, title: 'Adoption status' },
			signing_in: 		{ search_match:false, title: 'Signing in' },
			framework_notification: { search_match:false, title: 'Framework notification preferences' },
			framework_update: 	{ search_match:false, title: 'Framework update report', expanded: false, children:{
				framework_update_archives:    {search_match:false, title: 'Show All Archives'},
				framework_update_table:    {search_match:false, title: 'Archive Comparison - Table View'},
				framework_update_track:    {search_match:false, title: 'Archive Comparison - Track Changes View'},
			} },
			privacy_policy: { search_match:false, title: 'Privacy Policy' },
		}},
		explorer: 				{ search_match:false, title: this.browsing_interface_title, expanded: false, children: {
		// explorer: 				{ search_match:false, title: '[[browsing_interface_title]]', expanded: false, children: {
			treeview: 			{ search_match:false, title: 'Tree view', position: 'right', children: {
				document_card: 	{ search_match:false, title: 'Document card', position: 'left' },
				item_card: 		{ search_match:false, title: 'Item card', position: 'left' },
				expanded_card: 	{ search_match:false, title: 'Expanded cards' },
				more_info: 		{ search_match:false, title: 'Item metadata/Change history', position: 'right' },
				view_assoc: 	{ search_match:false, title: 'View associations' },
				view_rubrics: 	{ search_match:false, title: 'View Rubrics' },
				pinning: 		{ search_match:false, title: 'Pinning items' },
				tree_toolbar: 	{ search_match:false, title: 'Tree view toolbar', position: 'right' },
			}},
			tileview: 			{ search_match:false, title: 'Tile view', position: 'right' },
			search: 			{ search_match:false, title: 'Searching standards and other items', position: 'left' },
			keyboard_shortcuts: { search_match:false, title: 'Keyboard shortcuts' },
			tableitemsview: 	{ search_match:false, title: 'Items table' },
			tableassociationsview: { search_match:false, title: 'Associations table' },
			kebab_menu: 		{ search_match:false, title: 'Kebab menu', position: 'left' },
			archives: 			{ search_match:false, title: 'Framework archives', children: {
				archive_compare: { search_match:false, title: 'Comparing archives' }, 
			}},
			export_options: 	{ search_match:false, title: 'Printing/exporting options', children:{
				print_pdf:    {search_match:false, title: 'Print (or save as PDF)'},
				export_spreadsheet: {search_match:false, title: 'Export to a Spreadsheet'},
				export_case_json: {search_match:false, title: 'Export CASE JSON'},
				export_canvas_spreadsheet: {search_match:false, title: 'Export to Canvas Spreadsheet Format'},
				copy_case_api: {search_match:false, title: 'Copy CASE package API link'},
				satchel_text_format: {search_match:false, title: 'Satchel text format'},
			}},
		}},
		reviewing: 				{ search_match:false, title: 'Reviewing frameworks', expanded: false, children: {
			add_comments: 		{ search_match:false, title: 'Commenting on items', position: 'left' },
			show_all_comments: 	{ search_match:false, title: 'Show all comments' },
			comment_groups: 	{ search_match:false, title: 'Comment groups' },
			comment_attn: 		{ search_match:false, title: 'Tagging comment group members' },
			comment_mark_as_read: { search_match:false, title: 'Marking comments as read' },
			suggest_edits: 		{ search_match:false, title: 'Suggesting edits', position: 'left' },
			public_review_settings: { search_match:false, title: 'Public Review Settings', position: 'left' },
			public_review_comments: { search_match:false, title: 'Public Review Comments', position: 'left' },
		}},
		editing: 				{ search_match:false, title: 'Authoring/editing standards', expanded: false, children: {
			create_framework: 	{ search_match:false, title: 'Creating frameworks' },
			edit_document: 		{ search_match:false, title: 'Editing document properties', position: 'left' },
			edit_item: 			{ search_match:false, title: 'Edit Items', position: 'left' },
			supplemental_info: 	{ search_match:false, title: 'Supplemental item information' },
			supplemental_branches: { search_match:false, title: 'Supplemental branches' },
			orphaned_items: { search_match:false, title: 'Orphaned Items' },
			add_item: 			{ search_match:false, title: 'Adding new items' },
			create_sandbox: 			{ search_match:false, title: 'Create a Sandbox Copy' },
			copy_item: 			{ search_match:false, title: 'Copy items' },
			batch_add_from_text: { search_match:false, title: 'Batch import items from text input', position:'left' },
			batch_add_from_csv: { search_match:false, title: 'Batch import items from CSV file', position:'left' },
			move_item: 			{ search_match:false, title: 'Move items' },
			sort_children: 		{ search_match:false, title: 'Sort items' },
			batch_update: 		{ search_match:false, title: 'Batch update items' },
			create_assoc: 		{ search_match:false, title: 'Create associations' },
			batch_import_associations: 	{ search_match:false, title: 'Batch import associations' },
			create_edit_rubrics:	{ search_match:false, title: 'Create & Edit Rubrics' },
			delete_item: 		{ search_match:false, title: 'Delete items' },
			delete_framework: 	{ search_match:false, title: 'Delete frameworks' },
			mirrors: 			{ search_match:false, title: 'Mirror frameworks' },
			
		}},
		admin: 					{ search_match:false, title: 'Admin functions', expanded: false, children: {
			roles_system: 		{ search_match:false, title: 'System user roles', position:'right' },
			roles_framework: 	{ search_match:false, title: 'Framework user roles', position:'right' },
			manage_users: 	{ search_match:false, title: 'Manage Users', position:'right' },
			// user_roles_admin: 	{ search_match:false, title: 'Manage user roles' },
			framework_categories: { search_match:false, title: 'Manage framework categories', position:'right' },
			access_reports: 	{ search_match:false, title: 'Framework access report', position:'right' },
		}},
		case: 					{ search_match:false, title: 'What is CASE? / The CASE Ecosystem', expanded: false, children: {
			case_json:	 		{ search_match:false, title: 'CASE JSON' , expanded: false, children: {
				case_document:	{ search_match:false, title: 'CASE Document' },
				case_items:		{ search_match:false, title: 'CASE Items' },
				case_associations: { search_match:false, title: 'CASE Associations' },
				identifiers: 	{ search_match:false, title: 'CASE Item identifiers (GUIDs)' },
			}},
			case_rest_api: 		{ search_match:false, title: 'CASE REST APIs' },
			case_network: 		{ search_match:false, title: 'CASE Network 2' },
			satchel_plans: 		{ search_match:false, title: 'Satchel Publishing Options' },
		}},
	},

	// 1600 / 0.9: 5440k
	// 1600 / 0.8: 4280k - OK
	// 1000 / 0.9: 4488k
	// 1200 / 0.8: 3875.4k - blurry
	// 1600 / 0.7: 3749.6k - better
	// 1600 / 0.6: 3522.3k
	// 1600 / 0.5: 3307.4k
	async convert_image(filename) {
		return new Promise((resolve, reject) => {
			fetch(`/src/filestore/docimages/${filename}`)
				.then(response => response.blob())  // Convert to Blob
				.then(blob => {
					// console.warn('here', blob)
					U.create_image_data_url(blob, {
						image_format: 'webp', 
						max_width: 1600, 
						compression_level: 0.7, 
						callback_fn: o => { 
							if (o.error) {
								resolve('UNPROCESSED-' + filename)
							} else {
								resolve([o.img_url, o.natural_width]) 
							}
						}
					})
				})
				.catch(error => console.error('Error fetching image:', error));
		})
	},

	async get_file(filename) {
		return new Promise((resolve, reject) => {
			U.retrieve_file(filename, (s)=>{
				resolve(s)
			})
		})
	},

	pre_fn: function() {
		console.warn('in pre_fn')
		return new Promise((resolve, reject)=>{
			this.fn('pre').then((output)=>resolve(output))
		})
	},
	fn: function(input) {
		return new Promise((resolve, reject)=>{
			let framework_record = vapp.case_tree_component.framework_record

			let add_to_log = (s) => { this.log += `${s}\n` }

			let docs = {}
			let total_size = 0

			let process_toc = async (parent, children, level) => {
				// go through each child
				let seq = 0
				for (let help_id in children) {
					++seq
					let o = children[help_id]
					let indent = ''
					for (let i = 0; i < level; ++i) indent += '    '

					let contents = await this.get_file(`helpdocs/${help_id}.vue`)

					// extract html
					contents = contents.replace(/[\s\S]*<template><div>([\s\S]*)<\/div><\/template>[\s\S]*/, '$1')

					// convert links
					contents = contents.replace(/<span\s*v-html="link\('(.*?)',\s*'(.*?)'\)"\s*>\s*<\/span>/g, '[$2]($1)')

					// convert variable refs
					contents = contents.replace(/<span\s*v-html="(.*?)"\s*\/>/g, '[[$1]]')
					contents = contents.replace(/\{\{(.*?)\+'s'\}\}/g, '[[$1]]s')
					contents = contents.replace(/\{\{(.*?)\}\}/g, '[[$1]]')

					// convert icons
					contents = contents.replace(/<v-icon.+?>(.*?)<\/v-icon>/g, '<i class="$1">&nbsp</i>')

					// remove initial <h3>, because with Satchel we'll show the fullStatement as the title
					contents = contents.replace(/<h3>([\s\S]*?)<\/h3>/, '')

					// use sanitize_html to remove comments
					contents = U.sanitize_html(contents)

					// process images
					let i = 0
					while (i < 50 && contents.search(/<img srcset="\/docimages\/(\S+)\s*(.*?)?" class="(.*?)">/) > -1) {
						let filename = RegExp.$1, mod = RegExp.$2, cls = RegExp.$3
						// add_to_log(`${indent}image ${filename}`)
						// console.warn(filename, mod, cls)

						// convert image to base64 webp
						let [img_src, img_width] = await this.convert_image(filename)	

						// use incoming classes as-is
						// if we got a `mod` value that matches this pattern, use it to scale the img_width
						if (mod && mod.search(/(\S+)x/) == 0) {
							let scale = RegExp.$1 * 1
							if (isNaN(scale)) add_to_log(`${indent}ERROR: bad scale: ${filename} / ${mod}`)
							else {
								img_width = Math.round(img_width / scale)
							}
						}

						contents = contents.replace(/<img srcset="\/docimages\/(\S+)\s*(.*?)?" class="(.*?)">/, `<img src="${img_src}" class="${cls}" style="width:${img_width}px">`)
						++i
					}

					// trim
					contents = contents.trim()
					total_size += contents.length

					add_to_log(`${indent}${help_id} (${(contents.length/1000).toFixed(1)}k): ${o.title}`)

					if (help_id == 'treeview') {
						// console.log(contents)
					}

					// add to payload...
					let cfitem = framework_record.json.CFItems.find(x=>x.humanCodingScheme == help_id)

					if (empty(cfitem)) {
						let cfitem = new CFItem({
							fullStatement: o.title,
							humanCodingScheme: help_id,
							CFItemType: "wikidoc",
							language: "en",
							extensions: {
								supplementalNotes: contents
							},
						})

						// generate new identifier, corresponding uri, and date
						cfitem.complete_data(framework_record.json.CFDocument)
						this.payload.CFItems.push(cfitem.to_json())

						// generate isChildOf association FROM this doc TO its parent
						let assoc = {
							associationType: 'isChildOf',
							sequenceNumber: seq,
						}
						assoc.originNodeURI = {
							title: help_id,
							identifier: cfitem.identifier,
							uri: cfitem.uri,
						}
						assoc.destinationNodeURI = {
							title: parent.title ? parent.title : parent.humanCodingScheme,
							identifier: parent.identifier,
							uri: parent.uri,
						}

						// fill in other parts of the CFAssociation object
						assoc = new CFAssociation(assoc)
						assoc.complete_data(framework_record.json.CFDocument)
						this.payload.CFAssociations.push(assoc.to_json())

					} else {
						// if item already exists, we just need to update it
						cfitem = new CFItem(cfitem)
						cfitem.extensions.supplementalNotes = contents
						this.payload.CFItems.push(cfitem.to_json_for_update())
					}

					// if (level == 1) return

					if (o.children) {
						await process_toc(cfitem, o.children, level + 1)
					}

					// if (level == 0) console.log('here: ', hash)
					// break
				}
			}

			if (input == 'pre') {
				this.payload = {
					update_document_date: 'yes',
					check_out_and_in: 'yes',
					lsdoc_identifier: framework_record.lsdoc_identifier,
					CFItems: [],
					CFAssociations: [],
				}

				U.loading_start()
				process_toc(framework_record.json.CFDocument, this.original_help_toc, 0)
				setTimeout(x=>{
					U.loading_stop()
					add_to_log(`\nTOTAL SIZE: ${(total_size/1000).toFixed(1)}k`)
					console.log(this.payload)
					resolve(this.log)
				}, 7000)
			
			// input isn't 'pre', so user has clicked to save changes
			} else {
				vapp.$store.dispatch('save_framework_data', this.payload).then(()=>{
					vapp.$alert('Reload to see updated framework data').then(x=>{
						vapp.reload_window()
					})
				})
				resolve(this.log)
			}
		})
	},

}