// Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams
import { mapState, mapGetters } from 'vuex'

export default {
	data() { return {
		wiki_mode_force_normal_view: false,
	}},
	computed: {
		// if framework is flagged for wiki mode, we'll change some display things; some additional things are also changed if we're in wiki mode and not editing...
		// if the frameworkType is 'wiki', this framework is running in 'wiki_mode'
		wiki_mode() { 
			return this.framework_record.json.CFDocument.frameworkType == 'wiki' 
		},
		// this signals whether to make changes to the UI to fit wiki mode; use the wiki UI under these conditions...
		wiki_mode_view() { 
			// if this is a wiki framework...
			if (this.wiki_mode) {
				// if the user has edit access...
				if (this.user_can_edit) {
					// allow them to use a toggle in the kebab menu to force the "normal" CASE UI
					if (this.wiki_mode_force_normal_view) return false
					// and if editing is turned on, force the normal UI
					if (this.editing_enabled) return false
				}
				// if the user doesn't have edit access, or the two coniditions above fail, show in the wiki UI
				return true
			}
			// not a wiki framework, so don't use wiki view
			return false
		},
		wiki_flavor() { return this.$store.state.wiki_flavor },

		wiki_variables() {
			let hash = {}

			// find the variable_collection for the default variables
			let dv_item = this.framework_record.json.CFItems.find(x=>x.CFItemType == 'variable_collection' && x.humanCodingScheme == 'default')
			if (empty(dv_item)) {
				console.error('Couldn’t find default wiki_mode variables')
				return hash
			}

			// create hash of variables/values for the default item
			for (let node of this.cfo.cfitems[dv_item.identifier].tree_nodes[0].children) {
				let key = node.cfitem.humanCodingScheme
				hash[key] = {
					re: new RegExp(`\\[\\[${key}\\]\\]`, 'g'),
					val: node.cfitem.fullStatement,
				}
			}

			// now if we have a "wiki_flavor", overwrite values for that flavor
			if (!empty(this.wiki_flavor)) {
				let fv_item = this.framework_record.json.CFItems.find(x=>x.CFItemType == 'variable_collection' && x.humanCodingScheme == this.wiki_flavor)
				if (!empty(fv_item)) {
					for (let node of this.cfo.cfitems[fv_item.identifier].tree_nodes[0].children) {
						let key = node.cfitem.humanCodingScheme
						hash[key] = {
							re: new RegExp(`\\[\\[${key}\\]\\]`, 'g'),
							val: node.cfitem.fullStatement,
						}
					}
				}
			}

			// recurse (one level deep!) variables, so that, e.g., `banner_title` can be `[[app_name]] User Guide
			for (let key in hash) {
				for (let key2 in hash) {
					if (key == key2) continue
					let o = hash[key2]
					hash[key].val = hash[key].val.replace(o.re, o.val)
				}
			}

			// console.log('wiki_variables', hash)
			return hash
		},
	},
	watch: {
	},
	methods: {
		// a framework in wiki mode can define “variables” that are replaced with their values in wiki content
		wiki_variable_substitutions(s) {
			// sub variable values; format for inserting a variable value is:
			// Using [[app_name]], users can...
			for (let key in this.wiki_variables) {
				let o = this.wiki_variables[key]
				// if val is specified as a hyphen ('-'), replace with empty string
				let val = (o.val == '-') ? '' : o.val
				s = s.replace(o.re, val)
			}

			// highlight subs that *should* have happened
			s = s.replace(/(\[\[[^\]]+\]\])/, '<b style="color:red">$1</b>')
			s = s.replace(/({{[^}]+}})/, '<b style="color:red">$1</b>')

			// this fn also deals with creating links to other wiki pages; format for inserting a link is:
			// users can [print or export](export_options) standards
			s = s.replace(/\[([^\]]+)\]\(([^)]+)\)/g, ($0, text, doc_hcs) => {
				// try to find the doc page for this doc_hcs
				let doc_item = this.framework_record.json.CFItems.find(x=>x.CFItemType == 'wikidoc' && x.humanCodingScheme == doc_hcs)
				if (empty(doc_item)) {
					console.error(`BAD WIKI LINK: doc_hcs “${doc_hcs}” / text ${text}`)
					return `<b style="color:red">[BAD WIKI LINK: doc_hcs “${doc_hcs}” / text “${text}”]</b>`
				}
				
				return `<a class="k-wiki-mode-link" href="javascript:vapp.go_to_wiki_link('${doc_item.identifier}')"><b>${text}</b></a>`
			})
			

			return s
		},

	}
}
