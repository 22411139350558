FroalaEditor.DefineIconTemplate('mark_highlight_btn', '<div class="k-fr-mark_highlight-text" style="margin:0 3px; min-width:24px; font-size:9px; line-height:14px; text-align:center;">  </div>');
FroalaEditor.DefineIcon('mark_highlight', {NAME: 'mark_highlight', template:'mark_highlight_btn'});
FroalaEditor.RegisterCommand('mark_highlight', {
	title: 'Highlight',
	focus: true,
	undo: true,
	refreshAfterCallback: true,
	callback: function () {
		let html = this.selection.text()
		html = sr('<span class="k-fr-mark_highlight-text">$1</span>', html)
		this.html.insert(html)
	}
});

FroalaEditor.DefineIconTemplate('mark_add_btn', '<div class="k-fr-mark_add-text" style="margin:0 3px; min-width:24px; font-size:9px; line-height:14px; text-align:center;">add</div>');
FroalaEditor.DefineIcon('mark_add', {NAME: 'mark_add', template:'mark_add_btn'});
FroalaEditor.RegisterCommand('mark_add', {
	title: 'Mark as addition',
	focus: true,
	undo: true,
	refreshAfterCallback: true,
	callback: function () {
		let html = this.selection.text()
		html = sr('<span class="k-fr-mark_add-text">$1</span>', html)
		this.html.insert(html)
	}
});

FroalaEditor.DefineIconTemplate('mark_delete_btn', '<div class="k-fr-mark_delete-text" style="margin:0 3px; min-width:24px; font-size:9px; line-height:14px; text-align:center;">del</div>');
FroalaEditor.DefineIcon('mark_delete', {NAME: 'mark_delete', template:'mark_delete_btn'});
FroalaEditor.RegisterCommand('mark_delete', {
	title: 'Mark as deletion',
	focus: true,
	undo: true,
	refreshAfterCallback: true,
	callback: function () {
		let html = this.selection.text()
		html = sr('<span class="k-fr-mark_delete-text">$1</span>', html)
		this.html.insert(html)
	}
});

///////////////////////////////////////////////////////////////
// whenever a FroalaWrapper is inserted, if the component wants to do anything "special" for the froala, like customizing the available buttons,
// the component should call this fn first to get the standard config, then it can overwrite anything it wishes to
U.get_froala_config = function(params) {
	// params could include, e.g.:
	// placeholderText
	// heightMin
	// heightMax
	// toolbarInline: true,

	// first we add incoming params to the standard params for Sparkl...
	if (empty(params)) params = {}
	let config = $.extend({
		theme: 'light',
		charCounterCount: true,
		zIndex: 1000,	// may be needed to make image popup (and other popups) show up properly

		toolbarButtons: {
			moreRich: {buttons: ['bold', 'italic', 'insertLink', 'insertImage', 'insertmathtype',
				'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'align',]
				, buttonsVisible: 9},	// , 'quote', 'outdent', 'indent'
			moreMisc: {buttons: ['fullscreen', 'paragraphFormat', 'insertVideo', 'specialCharacters', 'formatUL', 'formatOL', 'insertHR', 'insertTable', 'clearFormatting', 'html'], buttonsVisible: 1, align:'right'}
		},
		imageEditButtons: ['imageDisplay', 'imageAlign', 'imageStyle', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageAlt', 'imageSize', 'imageReplace', 'imageRemove'],	// , 'imageCaption' doesn't seem to work consistently
	}, params)

	// ... then we combine that with everything from get_froala_config_cglt_standard
	return U.get_froala_config_cglt_standard(config)
}
