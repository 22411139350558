<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="700" persistent scrollable>
		<v-card>
			<v-card-title style="background-color:#ddd"><b>Create New Account</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px;line-height:21px;">
				<div>By creating an account and signing in, you will be able to use {{site_config.app_name}}'s commenting features to annotate learning standard frameworks. In addition, administrators will be able to grant you access to view private frameworks.</div>
				<div class="mt-2 pt-2" style="border-top:1px solid #999">Enter your name and email address, then click “CREATE NEW ACCOUNT”. You should then receive an email with a link to activate your new account. Upon clicking the link, you will be automatically signed in to {{site_config.app_name}}, then asked to establish a password.</div>
				<div>
					<div class="d-flex">
						<v-text-field outlined hide-details label="First name" class="mt-4 mr-1" v-model="first_name" placeholder="" data-cy="create_account_fname"></v-text-field>
						<v-text-field outlined hide-details label="Last name" class="mt-4 ml-1" v-model="last_name" placeholder="" data-cy="create_account_lname"></v-text-field>
					</div>
					<div class="d-flex">
						<v-spacer/>
						<div style="flex:0 0 400px"><v-text-field outlined hide-details label="Email" class="mt-4" v-model="email" placeholder="" data-cy="create_account_email"></v-text-field></div>
						<v-spacer/>
					</div>
				</div>
			</v-card-text>
			<v-card-actions class="pa-3" style="background-color:#ddd">
				<v-btn color="secondary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-xmark</v-icon>Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="create_new_account">Create New Account<v-icon small class="ml-2" data-cy="submit_create_account">fas fa-arrow-right</v-icon></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		first_name: '',
		last_name: '',
		email: '',
		account_type: '',
		dialog_open: true,
	}},
	computed: {
		...mapState(['site_config']),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		create_new_account() {
			if (empty(this.first_name)) {
				this.$alert('Please enter your first name.')
				return
			}
			if (empty(this.last_name)) {
				this.$alert('Please enter your last name.')
				return
			}
			if (empty(this.email)) {
				this.$alert('Please enter your email address.')
				return
			}

			let payload = {
				first_name: this.first_name,
				last_name: this.last_name,
				user_email: this.email,
				account_type: this.account_type,
				token_duration: 60,
				token_type: 'create_account'
			}
			U.ajax('send_token_signin_email', payload, result=>{
				U.loading_stop()
				let msg
				if (result.status != 'ok') {
					if (result.status == 'account_previously_created') {
						msg = 'An account for this email already exists! Click on Forgot Password to reset password.'
					} else {
						console.log(sr('Error in token create account ajax call: $1', result.status))
						msg = 'The one-time sign-in link could not be sent.'
						//msg = result.status
					}

				} else {
					msg = 'New account verification email sent. <b>Please check your spam or junk folder</b> if you don’t receive the link within one minute, and remember that <b>the link will expire after one hour</b>.'
				}
				this.$alert(msg).then(x=>this.$emit('dialog_cancel'))
			})
		}
	}
}
</script>

<style lang="scss">
</style>