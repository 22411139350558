<template>
	<v-dialog v-model="dialog" max-width="900" persistent scrollable :fullscreen="$vuetify.breakpoint.xs">
		<v-card class="k-case-item-importer mt-0 d-flex flex-column">
			<v-card-title style="background: white;">
				<span style="font-size:18px; font-weight: bold;">
					Import Rubric
				</span>
				<v-spacer></v-spacer>
				<v-btn icon @click="close_dialog">
					<v-icon>fas fa-close</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text class="flex-grow-1 overflow-y-auto" style="overflow: visible; background: white;">
				<div v-if="!parsed_results">
					<v-btn x-small class="float-right k-tight-btn" color="primary" @click="toggle_example_text">
						{{ is_showing_example ? 'Hide' : 'Show' }} Example Import Text
					</v-btn>
					<br/>
					<pre v-if="is_showing_example" class="font-monospace example-box mb-4">
RUBRIC: Example Rubric
QUALITY: 1: Minimally Proficient
QUALITY: 2: Very Proficient
CRITERION: Example Criterion
LEVEL: 1:
Example Level 1
LEVEL: 2:
Example Level 2
					</pre>
					<v-textarea v-model="import_text" outlined label="Paste rubric text here" rows="15"
						class="font-monospace  mt-2" :error-messages="validation_error ? [validation_error] : []"></v-textarea>
				</div>

				<div v-else>
					<v-alert v-if="parsed_results.errors.length > 0" type="error" border="left" colored-border class="mb-4">
						<div class="text-h6 mb-2">Parsing Errors:</div>
						<div v-for="(error, index) in parsed_results.errors" :key="index" class="ml-4">
							• Line {{ error.line }}: {{ error.message }}
						</div>
					</v-alert>

					<template v-else>
						<v-alert type="success"  outlined text class="mb-4">
							Successfully parsed {{ parsed_results.rubrics.length }} rubric{{ parsed_results.rubrics.length > 1 ? 's' : ''
							}}
						</v-alert>

						<div v-for="(rubric, rubric_index) in parsed_results.rubrics" :key="rubric_index" class="mb-6">
							<v-card outlined>
								<v-card-title>
									<div class="d-flex">
										<div @click="toggle_collapse(`r${rubric_index}`)">
											<v-icon v-if="!is_collapsed(`r${rubric_index}`)" small class="mr-2">
												fas fa-chevron-down
											</v-icon>
											<v-icon  v-else small class="mr-2">
												fas fa-chevron-up
											</v-icon>
										</div>
										<div>
											<div class="import-preview-title text-h5 ">
												<span class="font-weight-bold">Rubric: </span>
												<span>{{ rubric.title }}</span>
											</div>
											<div v-if="rubric.identifier" class="text-caption grey--text">
												<span>
													<strong>GUID / HRC:</strong>
													{{ rubric.identifier }}
												</span>
											</div>
										</div>
									</div>
								</v-card-title>

								<v-card-text v-if="!is_collapsed(`r${rubric_index}`)">
									<div class="d-flex flex-column" style="gap: 16px;">
										<!-- Rubric Description -->
										<div v-if="rubric.description" class="blue-grey lighten-5 rounded pa-2">
											<div class="d-flex" style="align-items: center;">
												<div @click="toggle_collapse(`r${rubric_index}d`)">
													<v-icon v-if="!is_collapsed(`r${rubric_index}d`)" small class="mr-2">
														fas fa-chevron-down
													</v-icon>
													<v-icon  v-else small class="mr-2">
														fas fa-chevron-up
													</v-icon>
												</div>
												<div class="text-h6 font-weight-bold">Description</div>
											</div>
											<div class="text-body-2" v-if="!is_collapsed(`r${rubric_index}d`)">
												{{ rubric.description }}
											</div>
										</div>
										<!-- Rubric Notes -->
										<div v-if="rubric.notes" class="blue-grey lighten-5 rounded pa-2">
											<div class="d-flex" style="align-items: center;">
												<div @click="toggle_collapse(`r${rubric_index}n`)">
													<v-icon v-if="!is_collapsed(`r${rubric_index}n`)" small class="mr-2">
														fas fa-chevron-down
													</v-icon>
													<v-icon  v-else small class="mr-2">
														fas fa-chevron-up
													</v-icon>
												</div>
												<div class="text-h6 font-weight-bold">Notes</div>
											</div>
											<div
												v-if="!is_collapsed(`r${rubric_index}n`)"
												class="text-body-2 preserve-whitespace" 
												v-html="rubric.notes.trim()"
											/>
										</div>
										<!-- Rubric Qualities -->
										<div class="blue-grey lighten-5 rounded pa-2">
											<div class="d-flex" style="align-items: center;">
												<div @click="toggle_collapse(`r${rubric_index}q`)">
													<v-icon v-if="!is_collapsed(`r${rubric_index}q`)" small class="mr-2">
														fas fa-chevron-down
													</v-icon>
													<v-icon  v-else small class="mr-2">
														fas fa-chevron-up
													</v-icon>
												</div>
												<div class="text-h6 font-weight-bold">Quality Levels</div>
											</div>
											<ul class="ml-4" v-if="!is_collapsed(`r${rubric_index}q`)">
												<li v-for="[score, quality] in Array.from(
														rubric.qualities.entries()
													)" :key="score">
													<div class="text-subtitle-2">
														{{ quality }}
													</div>
													<div class="text-caption">
														<span class="text-caption font-weight-bold">Score:</span>
														<span class="text-body-2">{{ score }}</span>
													</div>
												</li>
											</ul>
										</div>
										<!-- Rubric Criteria -->
										<div v-if="rubric.criteria && rubric.criteria.length" class="blue-grey lighten-5 rounded pa-2">
											<div class="d-flex" style="align-items: center;">
												<div @click="toggle_collapse(`r${rubric_index}c`)">
													<v-icon v-if="!is_collapsed(`r${rubric_index}c`)" small class="mr-2">
														fas fa-chevron-down
													</v-icon>
													<v-icon  v-else small class="mr-2">
														fas fa-chevron-up
													</v-icon>
												</div>
												<div class="text-h6 font-weight-bold">Criteria ({{ rubric.criteria.length }})</div>
											</div>
											<div v-if="!is_collapsed(`r${rubric_index}c`)">
												<!-- Rubric Criterion -->
												<div v-for="(criterion, criterion_index) in rubric.criteria" :key="criterion_index" class="ml-4 white rounded pa-2 mt-4">
													<!-- Criterion Description -->
													<div class="d-flex">
														<div @click="toggle_collapse(`r${rubric_index}c${criterion_index}`)" class="mr-2 ml-2">
															<v-icon v-if="!is_collapsed(`r${rubric_index}c${criterion_index}`)" small>
																fas fa-chevron-down
															</v-icon>
															<v-icon  v-else small>
																fas fa-chevron-up
															</v-icon>
														</div>
														<div>
															<div>
																<span class="text-subtitle-1 font-weight-bold">
																	Criterion:
																</span>
																<span class="text-body-2">
																	{{ criterion.description }}
																</span>
															</div>
															<div v-if="criterion.identifier" class="text-caption grey--text">
																<span>
																	<strong>GUID / HRC:</strong>
																	{{ criterion.identifier }}
																</span>
															</div>
														</div>
													</div>
													<div v-if="!is_collapsed(`r${rubric_index}c${criterion_index}`)" class="d-flex flex-column mt-2" style="gap: 16px">
														<!-- Criterion Weight -->
														<div v-if="criterion.weight" class="ml-4 blue-grey lighten-5 rounded pa-2">
															<span class="text-subtitle-2 font-weight-bold">Weight:</span>
															<span class="text-body-2">{{ criterion.weight }}</span>
														</div>
														<!-- Criterion Notes -->
														<div v-if="criterion.notes" class="ml-4 blue-grey lighten-5 rounded pa-2">
															<div class="d-flex" style="align-items: center;">
																<div @click="toggle_collapse(`r${rubric_index}c${criterion_index}n`)">
																	<v-icon v-if="!is_collapsed(`r${rubric_index}c${criterion_index}n`)" small class="mr-2">
																		fas fa-chevron-down
																	</v-icon>
																	<v-icon  v-else small class="mr-2">
																		fas fa-chevron-up
																	</v-icon>
																</div>
																<div class="text-subtitle-2 font-weight-bold">Notes</div>
															</div>
															<div
																v-if="!is_collapsed(`r${rubric_index}c${criterion_index}n`)"
																class="text-body-2 preserve-whitespace"
																v-html="criterion.notes.trim()"
															/>
														</div>
														<!-- Criterion Levels -->
														<div v-if="criterion.levels" class="ml-4 blue-grey lighten-5 rounded pa-2">
															<div class="d-flex" style="align-items: center;">
																<div @click="toggle_collapse(`r${rubric_index}c${criterion_index}l`)">
																	<v-icon v-if="!is_collapsed(`r${rubric_index}c${criterion_index}l`)" small class="mr-2">
																		fas fa-chevron-down
																	</v-icon>
																	<v-icon  v-else small class="mr-2">
																		fas fa-chevron-up
																	</v-icon>
																</div>
																<div class="text-subtitle-2 font-weight-bold">
																	Criterion Levels ({{ criterion.levels.length }})
																</div>
															</div>
															<div v-if="!is_collapsed(`r${rubric_index}c${criterion_index}l`)">
																<div v-for="(level, level_index) in criterion.levels" :key="level_index" class="ml-4 white rounded pa-2 mt-4">
																	<div class="d-flex">
																		<div @click="toggle_collapse(`r${rubric_index}c${criterion_index}l${level_index}`)" class="mr-2 ml-2">
																			<v-icon v-if="!is_collapsed(`r${rubric_index}c${criterion_index}l${level_index}`)" small>
																				fas fa-chevron-down
																			</v-icon>
																			<v-icon  v-else small>
																				fas fa-chevron-up
																			</v-icon>
																		</div>
																		<div>
																			<div class="text-subtitle-2 font-weight-bold">
																				Level:
																				<span class="text-body-2">
																					{{ level.quality }}
																				</span>
																			</div>
																			<div v-if="level.identifier" class="text-caption grey--text">
																				<span>
																					<strong>GUID / HRC:</strong>
																					{{ level.identifier }}
																				</span>
																			</div>
																		</div>
																	</div>
																	<div class="ml-4 d-flex flex-column" v-if="!is_collapsed(`r${rubric_index}c${criterion_index}l${level_index}`)" style="gap:16px;">
																		<!-- Level Description -->
																		<div v-if="level.description" class="blue-grey lighten-5 rounded pa-2">
																			<div class="text-subtitle-2 font-weight-bold">Description</div>
																			<div class="text-body-2 preserve-whitespace" v-html="level.description.trim()" />
																		</div>
																		<!-- Level Score -->
																		<div v-if="level.score" class="blue-grey lighten-5 rounded pa-2">
																			<span class="text-subtitle-2 font-weight-bold">Score:</span>
																			<span class="text-body-2">{{ level.score }}</span>
																		</div>
																		<!-- Level Notes -->
																		<div v-if="level.feedback" class="blue-grey lighten-5 rounded pa-2">
																			<span class="text-subtitle-2 font-weight-bold">Notes</span>
																			<div class="text-body-2 preserve-whitespace" v-html="level.notes.trim()" />
																		</div>
																		<!-- Level Feedback -->
																		<div v-if="level.feedback" class="blue-grey lighten-5 rounded pa-2">
																			<span class="text-subtitle-2 font-weight-bold">Feedback</span>
																			<div class="text-body-2 preserve-whitespace" v-html="level.feedback.trim()" />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</v-card-text>
							</v-card>
						</div>

						<v-card outlined class="pa-4">
							<div class="d-flex">
								<div @click="toggle_collapse('processing_log')">
									<v-icon v-if="!is_collapsed('processing_log')" small class="mr-2">
										fas fa-chevron-down
									</v-icon>
									<v-icon  v-else small class="mr-2">
										fas fa-chevron-up
									</v-icon>
								</div>
								<div class="text-subtitle-1 font-weight-bold">
									Processing Log
								</div>
							</div>
							<div v-if="!is_collapsed('processing_log')">
								<div v-for="(log, index) in parsed_results.log" :key="index" class="text-caption">
									Line {{ log.line }}: {{ log.message }}
								</div>
							</div>
						</v-card>
					</template>
				</div>
			</v-card-text>

			<v-card-actions class="pa-4 flex-grow-0" style="border-top: 1px solid #e0e0e0; background: white;">
				<v-spacer></v-spacer>
				<template v-if="!parsed_results">
					<v-btn small color="secondary" class="mr-2" @click="close_dialog">
						Cancel Import
					</v-btn>
					<v-btn small color="primary" :disabled="!import_text.trim()" @click="parse_input">
						Process Imported Rubric Text…
					</v-btn>
				</template>
				<template v-else>
					<v-btn text @click="reset_parser"> Back </v-btn>
					<v-btn v-if="parsed_results.errors.length === 0" color="primary" @click="import_rubrics">
						Import
					</v-btn>
				</template>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { RubricParser } from "@/js/rubric_import_parser";

/***
 * RubricImportDialog handles the import of rubrics from text input, allowing users to:
 * - Parse formatted text into rubric structures
 * - Create new rubrics with criteria and levels
 * - Establish associations between existing items and newly created rubric elements
 * 
 * The component maintains a hierarchical structure:
 * Rubric → Criteria → Criterion Levels
 * Each level can be associated with existing framework items through CFAssociations
 */
export default {
	name: "RubricImportDialog",

	props: {
		/** Controls the visibility of the dialog */
		value: {
			type: Boolean,
			default: false,
		},
		// The "top-level" identifier for the CFRubric
		rubric_identifier: { 
			type: String, 
			required: true 
		},
		// Represents the current framework context where rubrics will be imported
		framework_record: { 
			type: Object, 
			required: true 
		},
		// The item that initiated the import process - used for establishing initial associations
		initiating_cfitem: { 
			type: Object, 
			required: true 
		},
		existing_rubric: {
			type: Object,
			default: null
		},
		existing_association: {
			type: Object, 
			default: null
		},
	},

	data() {
		return {
			import_text: "",
			parsed_results: null,
			validation_error: "",
			// Tracks pending associations to be created between existing items and new rubric elements
			new_associations: [],
			// Manages UI state for collapsible sections
			collapsed_ids: [],
			is_showing_example: false
		};
	},

	computed: {
		// v-model implementation for dialog visibility
		dialog: {
			get() {
					return this.value;
			},
			set(value) {
					this.$emit("input", value);
			},
		},
		// Reference to the case tree component for updating the UI after import
		viewer() {
			return vapp.case_tree_component
		}
	},


	methods: {
			toggle_example_text() {
				this.is_showing_example = !this.is_showing_example
			},
			toggle_collapse(id) {
					const index = this.collapsed_ids.indexOf(id);
					if (index > -1) {
						this.collapsed_ids.splice(index, 1);
					} else {
						this.collapsed_ids.push(id);
					}
			},
			is_collapsed(id) {
					return this.collapsed_ids.includes(id);
			},
			parse_input() {
					this.validation_error = "";

					if (!this.import_text.trim()) {
						this.validation_error = "Please enter text to parse";
						return;
					}

					try {
						const parser = new RubricParser(this.framework_record);
						this.parsed_results = parser.parse(this.import_text);
					} catch (error) {
						this.validation_error = `Parsing error: ${error.message}`;
					}
			},
			/**
			 * Orchestrates the import process by:
			 * 1. Creating new rubrics with their criteria and levels
			 * 2. Establishing associations with existing framework items
			 * 3. Updating the framework state and UI
			 * 
			 * The lastChangeDateTime is propagated through the hierarchy to maintain
			 * consistency in change tracking
			 */
			async import_rubrics() {
				if (!this.parsed_results?.rubrics) return;

				if (this.existing_rubric) {
					await this.remove_preexisting_rubric();
				}

				const new_rubrics = []
				
				this.parsed_results.rubrics.forEach(rubric => { 
					const new_rubric = this.create_rubric(rubric)
					this.create_association(rubric.identifier, new_rubric, "ext:hasRubric")
					new_rubrics.push(new_rubric)
				})
				
				const payload = {
					lsdoc_identifier: this.framework_record.lsdoc_identifier,
					CFRubrics: new_rubrics,
					CFAssociations: this.new_associations
				}

				// Save and update framework data, ensuring all timestamps are synchronized
				await this.$store.dispatch('save_framework_data', payload);

				payload.CFRubrics.forEach(rubric => {
					rubric.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime

					rubric.CFRubricCriteria.forEach(rubric_criterion => {
						rubric_criterion.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
						rubric_criterion.CFRubricCriterionLevels.forEach(criterion_level => {
							criterion_level.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
						})
					})

					const rubric_json = rubric.to_json()
					U.update_rubric_entity_hash(rubric_json, this.framework_record)
					this.$store.commit('set', [this.framework_record.json.CFRubrics, 'PUSH', rubric_json])         
				})

				// Update association state and hashes
				payload.CFAssociations.forEach((association) => {
					association.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					let assoc_json = association.to_json()
					this.$store.commit('set', [this.framework_record.json.CFAssociations, 'PUSH', assoc_json])
					U.update_associations_hash(this.framework_record.cfo, assoc_json)
				})
				// Update UI to reflect new associations
				this.viewer.update_association_display({
					framework_id: this.framework_record.lsdoc_identifier, 
					assoc: payload.CFAssociations[0], 
					actions: ['add', 'display']
				})
				this.new_associations = []

				// After successful import, emit event
				this.$root.$emit('rubric-imported')
					
				this.close_dialog();
			},

			async remove_preexisting_rubric() {
				if (!this.existing_rubric || !this.existing_association) return;

				const payload = {
					lsdoc_identifier: this.framework_record.lsdoc_identifier,
					CFRubrics: [{
						identifier: this.existing_rubric.identifier,
						delete: 'yes'
					}],
					CFAssociations: []
				};

				// Collect all associations to delete
				const associationsToDelete = this.framework_record.json.CFAssociations.filter(assoc => {
					// Match rubric association
					if (assoc.identifier === this.existing_association.identifier) return true;
					
					// Match criterion associations
					const criterionIds = this.existing_rubric.CFRubricCriteria.map(c => c.identifier);
					if (assoc.associationType === 'ext:hasRubricCriterion' &&  criterionIds.includes(assoc.destinationNodeURI.identifier)) {
						return true;
					}

					// Match level associations
					const levelIds = this.existing_rubric.CFRubricCriteria.flatMap(c => 
						c.CFRubricCriterionLevels.map(l => l.identifier)
					);
					if (assoc.associationType === 'ext:hasRubricCriterionLevel' && levelIds.includes(assoc.destinationNodeURI.identifier)) {
						return true;
					}

					return false;
				});

				payload.CFAssociations = associationsToDelete.map(assoc => ({
					identifier: assoc.identifier,
					delete: 'yes'
				}));

				await this.$store.dispatch('save_framework_data', payload);

				// Clean up framework data
				U.delete_from_rubric_entity_hash(this.existing_rubric, this.framework_record);
				
				associationsToDelete.forEach(assoc => {
					U.remove_from_associations_hash(this.framework_record.cfo, assoc);
					U.delete_association_from_json(this.framework_record, assoc.identifier);
				});

				const rubric_index = this.framework_record.json.CFRubrics.findIndex(x => 
					x.identifier == this.existing_rubric.identifier
				);
				if (rubric_index > -1) {
					this.$store.commit('set', [this.framework_record.json.CFRubrics, 'SPLICE', rubric_index]);
				}

				this.viewer.update_association_display({
					framework_id: this.framework_record.lsdoc_identifier, 
					actions: ['remove']
				});
			},

			/**
			 * Creates a new rubric with generated UUIDs and completed framework data
			 * The rubric maintains relationships with its criteria through identifiers
			 */
			create_rubric(rubric_data) {
				// const rubric_identifier = U.new_uuid()
				// PW: instead of creating a new rubric_identifier, we can just use the rubric_identifier prop from CFRubricModal, 
				// since we're making the simplifying assumption now that we only import one rubric at a time
				console.warn('creating rubric for identifier ' + this.rubric_identifier)

				const criteria = this.create_criterion(
					rubric_data.criteria,
					this.rubric_identifier
				)

				let rubric = new CFRubric({
					identifier: this.rubric_identifier,
					title: $.trim(rubric_data.title),
					description: $.trim(rubric_data.description),
					notes: $.trim(rubric_data.notes),
					CFRubricCriteria: criteria
				})

				rubric.complete_data(this.framework_record.json.CFDocument)
				return rubric
			},

			/**
			 * Creates criteria for a rubric, establishing the parent-child relationship
			 * through the rubric_identifier. Each criterion maintains its position
			 * in the sequence and can be optionally associated with existing framework items
			 */
			create_criterion(criteria_data, rubric_identifier) {
				const criteria = []

				criteria_data.forEach((criterion_data, index) => {

					const criterion = new CFRubricCriterion()
					const criterion_identifier = U.new_uuid()

					const criterion_levels = this.create_criterion_levels(
						criterion_data.levels,
						criterion_identifier
					)

					criterion.identifier = criterion_identifier
					criterion.position = index + 1
					criterion.rubricId = rubric_identifier
					criterion.weight = criterion_data.weight
					criterion.notes = criterion_data.notes
					criterion.description = criterion_data.description
					criterion.CFRubricCriterionLevels = criterion_levels
					criterion.complete_data(this.framework_record.json.CFDocument)

					// Only create associations if an existing item identifier was provided
					if (criterion_data.identifier) {
						this.create_association(criterion_data.identifier, criterion, "ext:hasRubricCriterion")
					}

					criteria.push(criterion)
				})
				return criteria
			},

			/**
			 * Creates levels for a criterion, maintaining the hierarchical relationship
			 * through criterion_identifier. Levels represent the scoring rubric with
			 * quality descriptions and numerical scores
			 */
			create_criterion_levels(levels_data, criterion_identifier) {
				const levels = []

				levels_data.forEach((level_data, index) => {
					const level = new CFRubricCriterionLevel()
					const level_identifier = U.new_uuid()

					level.identifier = level_identifier
					level.notes = level_data.notes
					level.description = level_data.description
					level.quality = level_data.quality
					level.score = level_data.score
					level.position = index + 1
					level.rubricCriterionId = criterion_identifier
					level.complete_data(this.framework_record.json.CFDocument)
					levels.push(level)

					// Only create associations if an existing item identifier was provided
					if (level_data.identifier) {
						this.create_association(level_data.identifier, level, "ext:hasRubricCriterionLevel")
					}
				})

				return levels
			},

			/**
			 * Creates associations between existing framework items and newly created
			 * rubric elements. If no specific identifier is provided, associates with
			 * the initiating item. The association type determines the relationship
			 * (hasRubric, hasRubricCriterion, hasRubricCriterionLevel)
			 */
			create_association(identifier_for_item_association, destination_item, type) {
				
				const should_associate_root_item = identifier_for_item_association == null

				if (should_associate_root_item) {
					identifier_for_item_association = this.initiating_cfitem.identifier
				}

				const item_for_association = this.framework_record.lsdoc_identifier == identifier_for_item_association
					? this.framework_record.json.CFDocument
					: this.framework_record.cfo.cfitems[identifier_for_item_association]

				// Use title for rubrics, description for criteria and levels
				const destination_title = type == 'ext:hasRubric' ? destination_item.title : destination_item.description
				
				const new_association = new CFAssociation({
					originNodeURI: {
						title: U.generate_cfassociation_node_uri_title(item_for_association),
						identifier: item_for_association.identifier,
						uri: item_for_association.uri
					},
					associationType: type,
					destinationNodeURI: {
						title: destination_title,
						identifier: destination_item.identifier,
						uri: destination_item.uri
					}
				})

				new_association.complete_data(this.framework_record.json.CFDocument)
				this.new_associations.push(new_association)
			},

			reset_parser() {
				this.parsed_results = null;
				this.validation_error = "";
			},

			close_dialog() {
					this.dialog = false;
					this.import_text = "";
					this.parsed_results = null;
					this.validation_error = "";
			},
	},
};
</script>

<style scoped>
.font-monospace {
		font-family: monospace;
}

.preserve-whitespace {
	white-space: preserve-breaks;
	/* word-wrap: break-word;  */
	text-align: left;
}

.import-preview-title {
	display: flex;
	gap: 8px;
	align-items: baseline;
}

.example-box {
	padding: 8px;
	border: 1px solid gray;
	color: gray;
	border-radius: 8px;
	font-size: 12px;
	line-height: 120%;
	white-space: preserve-breaks
}
</style>
