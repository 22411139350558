// sample searches:
//    energy ask questions: string contains "energy" AND "ask" AND "questions"
//    energy "ask questions": string contains "energy" AND "ask questions"
U.create_search_re = function(search_terms) {
	let search_term_res = []

	if (empty(search_terms)) search_terms = ''
	else search_terms = $.trim(search_terms+'')

	if (empty(search_terms)) {
		return search_term_res
	}

	// remove all punctuation except a few things, to make sure regexps work
	search_terms = search_terms.replace(/[^0-9a-zA-Z+\-–,;:'" .*?]/g, '')	// note that we have hyphen and en-dash here
	// escape some punctuation marks we explicitly want to work
	let st = search_terms.replace(/\./g, '\\.')
	st = st.replace(/\*/g, '\\*')
	st = st.replace(/\+/g, '\\+')
	st = st.replace(/\?/g, '\\?')

	// combine hyphen/en-dash, so that if you search using either one it finds either one
	st = st.replace(/[-–]/g, '[-–]')

	// ditto for quotes
	st = st.replace(/['‘’]/g, '[\'‘’]')
	st = st.replace(/["“”]/g, '["“”]')

	st = $.trim(st)

	if (!empty(st)) {
		// "hard-code" spaces within double-quotes: "foo bar" > foo\sbar
		let re_string = st.replace(/"([^"]+)"/g, function($0, $1) {
			return "\\b" + $1.replace(/ +/g, "\\s") + "\\b";
		})
		re_string = $.trim(re_string)
		if (!empty(re_string)) {
			// split the re_string into individual and_re_strings on spaces: “ask questions” => ['ask', 'questions']
			let and_re_strings = re_string.split(/\s+/)
			for (let and_re_string of and_re_strings) {
				and_re_string = $.trim(and_re_string)
				if (!empty(and_re_string)) {
					// create a regexp for this and_re_string
					search_term_res.push(new RegExp('(' + and_re_string + ')', 'gi'))
				}
			}
		}
	}

	return search_term_res
}

U.strings_match_search_term_res = function(search_term_res, string_arr) {
	// at least one of the search_term_res must match at least one of the strings of string_arr

	// now go through each string
	for (let string of string_arr) {
		string = $.trim(string)
		if (empty(string)) continue

		// now go through each "re" in the search_term_res array; each re must be in the string for it to match
		let match = true
		for (let re of search_term_res) {
			// if this re isn't in the string, it's not a match!
			if (string.search(re) == -1) {
				match = false
				break
			}
		}

		// if match is true, this string includes all the re's from this set of and_res, so we can return true overall
		if (match == true) return true

		// otherwise we keep looking for matches in other strings and/or with other sets of and_res
	}

	// if we haven't returned true somewhere above, return false -- no match
	return false
}

/*
// sample usages...

this.search_term_res = U.create_search_re(this.search_terms)

execute_search(node) {
	if (empty(node)) return false

	// by default return false (item doesn't meet criteria)
	let rv = false

	// if the node has children, search the children
	if (!empty(node.children) && node.children.length > 0) {
		for (let i = 0; i < node.children.length; ++i) {
			let child = node.children[i]
			if (this.execute_search(child)) {
				// if a child matches and this isn't the document node, open this parent item
				if (!empty(node.parent_node)) {
					this.$store.commit('set', [this.open_nodes, node.tree_key+'', true])
				}
				rv = true
			}
		}
	}

	// assuming the node has a cfitem, determine if it should be highlighted as a search result
	if (!empty(node.cfitem)) {
		// check fullStatement and humanCodingScheme, and identifier if it looks like it might actually be an identifier (> 16 chars)
		let arr = [node.cfitem.fullStatement, node.cfitem.humanCodingScheme]
		if (this.search_terms.length > 16) arr.push(node.cfitem.identifier)

		if (U.strings_match_search_term_res(this.search_term_res, arr)) {
			this.search_results[node.tree_key] = true
			++this.search_result_count
			rv = true
		}
	}

	return rv
}

*/
