<template>
<v-app v-show="app_initialized" :class="`k-app-embedded-${embedded_mode} k-app-cureum_standards_tabbed-${cureum_standards_tabbed}`">
	<div v-if="site_config.agency_img_index_page_html&&(!framework_list_component||!framework_list_component.case_tree_lsdoc_identifier)&&(!embedded_mode&&!cureum_standards_tabbed)" class="k-toolbar-agency-framework-not-showing" v-html="site_config.agency_img_index_page_html"></div>
	<v-app-bar v-if="!embedded_mode&&!cureum_standards_tabbed" app class="k-app-toolbar k-banner-color" height="60">
		<div v-visible="agency_logo_img_src" class="k-toolbar-agency-img-outer" @click="go_to_route('')"><img class="k-toolbar-agency-img" :src="agency_logo_img_src"></div>
		<div v-if="show_app_name_with_logo!='false'" class="k-toolbar-app-title k-app-title-color" translate="no" @click="go_to_route('')">{{app_name_for_banner}}</div>
		<v-spacer />

		<!-- google translate element is rendered here -->
		<div class="d-flex align-center mr-3" :style="(gt_enabled&&!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm)?'opacity:1':'opacity:0'">
			<div id="google_translate_element_wrapper"><div id="google_translate_element"></div></div>
			<v-btn v-if="gt_restore_btn_available" x-small outlined class="ml-1 k-tight-btn" color="#fff" @click.stop="restore_to_original_language">Restore To Original</v-btn>
		</div>

		<div v-show="app_initialized" style="display:flex;align-items:center;">
			<v-btn v-show="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" ref="help_icon" icon color="light-blue accent-2" class="k-app-help-icon mr-3" @click="toggle_help"><v-icon large>fas fa-circle-info</v-icon></v-btn>
			<div v-if="signed_in" class="k-signed-in-as" v-show="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" :style="{color: U.get_contrast_color(site_config.banner_color)}">Signed in as <b>{{user_info.first_name}} {{user_info.last_name}}</b></div>
			<div v-else v-show="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" :style="{color: U.get_contrast_color(site_config.banner_color)}"><v-btn small @click="sign_in">Sign In</v-btn></div>

			<v-menu :transition="false" bottom offset-y left z-index="1002"><template v-slot:activator="{on}"><v-btn v-on="on" icon color="#fff" class="ml-3"><v-icon :style="{color: U.get_contrast_color(site_config.banner_color)}">fas fa-bars</v-icon></v-btn></template>
				<v-list dense min-width="200" class="pb-0">
					<v-list-item v-show="$vuetify.breakpoint.xs||$vuetify.breakpoint.sm" @click="toggle_help"><v-list-item-icon><v-icon small color="light-blue accent-4">fas fa-circle-info</v-icon></v-list-item-icon><v-list-item-title class="light-blue--text text--accent-4">{{app_name}} Help</v-list-item-title></v-list-item>

					<v-list-item v-if="show_resources_toggle" v-show="!($vuetify.breakpoint.xs||$vuetify.breakpoint.sm)"><v-list-item-title class="text-center">
						<v-btn-toggle dense active-class="k-toggle-btn-active-class" color="primary" class="k-toggle-btn" v-model="frameworks_or_resources_toggle" mandatory>
							<v-btn small light value="frameworks">Frameworks</v-btn>
							<v-btn small light value="resources">Resources</v-btn>
						</v-btn-toggle>
					</v-list-item-title></v-list-item>

					<v-list-item v-show="!($vuetify.breakpoint.xs||$vuetify.breakpoint.sm)" @click="show_framework_update_report"><v-list-item-title class="text-center pb-1"><v-btn small color="orange darken-2" dark @click=""><v-icon small class="mr-2">fas fa-bolt-lightning</v-icon>Framework Update Report</v-btn></v-list-item-title></v-list-item>

					<v-list-item v-for="(link, index) in burger_links" :key="index"><v-list-item-icon><v-icon small>fas fa-info-circle</v-icon></v-list-item-icon><v-list-item-title><a class="k-plain-link" :href="link.url" target="_case_front" v-html="link.text"></a></v-list-item-title></v-list-item>

					<v-menu offset-x left :open-on-hover="false" style="display: block;">
						<template v-slot:activator="{on}"><v-list-item v-on="on" style="cursor:pointer">
							<v-list-item-icon><v-icon small>fas fa-project-diagram</v-icon></v-list-item-icon><v-list-item-title>CASE Links</v-list-item-title>
							<v-list-item-action class="justify-end"><v-icon small>fas fa-chevron-right</v-icon></v-list-item-action>
						</v-list-item></template>

						<v-list dense>
							<v-list-item><v-list-item-icon><v-icon small>fas fa-info-circle</v-icon></v-list-item-icon><v-list-item-title><a class="k-plain-link" href="https://www.imsglobal.org/activity/case" target="_case_front">1EdTech CASE Front Page</a></v-list-item-title></v-list-item>
							<v-list-item><v-list-item-icon><v-icon small>fas fa-info-circle</v-icon></v-list-item-icon><v-list-item-title><a class="k-plain-link" href="https://www.imsglobal.org/case-10-specification-information-model" target="_case_spec">1EdTech CASE 1.0 Spec (Information Model)</a></v-list-item-title></v-list-item>
							<v-list-item><v-list-item-icon><v-icon small>fas fa-info-circle</v-icon></v-list-item-icon><v-list-item-title><a class="k-plain-link" href="https://www.imsglobal.org/case-10-specification-rest-api" target="_case_spec">1EdTech CASE 1.0 Spec (REST API)</a></v-list-item-title></v-list-item>
							<!-- link to casenetwork everywhere *except* case network-->
							<v-list-item v-show="!$store.state.use_auth0_login"><v-list-item-icon><v-icon small>fas fa-map</v-icon></v-list-item-icon><v-list-item-title><a class="k-plain-link" href="https://casenetwork.1edtech.org/" target="_casenetwork">CASE Network 2</a></v-list-item-title></v-list-item>
						</v-list>
					</v-menu>

					<!-- <v-list-item @click="single_color_scheme_on=!single_color_scheme_on"><v-list-item-icon><v-icon small>fas fa-palette</v-icon></v-list-item-icon><v-list-item-title>Use {{single_color_scheme_on?'multi':'single'}}-color framework scheme</v-list-item-title></v-list-item> -->

					<!-- <v-list-item v-if="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" @click="toggle_google_translate"><v-list-item-icon><v-icon small>fas fa-language</v-icon></v-list-item-icon><v-list-item-title><div class="d-flex align-center">Toggle Google Translate</div></v-list-item-title></v-list-item> -->

					<v-divider/>
					<div v-if="is_granted('super')">
						<v-list-item @click="show_user_admin_tool=true"><v-list-item-icon><v-icon small color="red">fas fa-users</v-icon></v-list-item-icon><v-list-item-title class="red--text text--darken-3">Manage Users</v-list-item-title></v-list-item>
						<v-list-item v-if="framework_categories.length" @click="show_category_admin_tool=true"><v-list-item-icon><v-icon small color="red">fas fa-layer-group</v-icon></v-list-item-icon><v-list-item-title class="red--text text--darken-3">Manage Framework Categories</v-list-item-title></v-list-item>
						<v-list-item @click="show_framework_access_report=true"><v-list-item-icon><v-icon small color="red">fas fa-info-circle</v-icon></v-list-item-icon><v-list-item-title class="red--text text--darken-3">Framework Access Report</v-list-item-title></v-list-item>
						<v-divider/>
					</div>

					<v-list-item class="k-signed-in-as-menu-item" v-if="signed_in" v-show="$vuetify.breakpoint.xs||$vuetify.breakpoint.sm"><v-list-item-title>Signed in as <b>{{user_info.first_name}} {{user_info.last_name}}</b></v-list-item-title></v-list-item>
					<v-list-item v-if="!signed_in" v-show="$vuetify.breakpoint.xs||$vuetify.breakpoint.sm" @click="sign_in"><v-list-item-icon><v-icon small>fas fa-sign-in-alt</v-icon></v-list-item-icon><v-list-item-title>Sign In</v-list-item-title></v-list-item>

					<v-list-item v-if="signed_in" @click="show_user_prefs=!show_user_prefs"><v-list-item-icon><v-icon small>fas fa-bolt</v-icon></v-list-item-icon><v-list-item-title>Framework Notification Preferences</v-list-item-title></v-list-item>

					<!-- cgrt login only on casenetwork-->
					<v-list-item v-show="signed_in&&$store.state.use_auth0_login" @click="open_cgrt"><v-list-item-icon><v-icon small>fas fa-stream</v-icon></v-list-item-icon><v-list-item-title class="k-plain-link">CASE Network GUID Translator Tool</v-list-item-title></v-list-item>

					<v-list-item v-if="signed_in&&!$store.state.use_oidc_login&&!$store.state.use_auth0_login" @click="change_password"><v-list-item-icon><v-icon small>fas fa-key</v-icon></v-list-item-icon><v-list-item-title>Change Password</v-list-item-title></v-list-item>
					<v-list-item v-if="signed_in" @click="sign_out"><v-list-item-icon><v-icon small>fas fa-sign-out-alt</v-icon></v-list-item-icon><v-list-item-title>Sign Out</v-list-item-title></v-list-item>

					<v-divider/>
					<v-list-item class="text-center pr-0"><v-list-item-title><div class="d-flex align-center mt-1 mb-2">
						<div>
							<div class="mt-0" style="font-size:18px" translate="no"><b class="grey--text text--darken-1">Standards Satchel v{{$store.state.app_version}}</b></div>
							<div class="mt-2" translate="no">
								&copy;2024 <a href="https://commongoodlt.com" target="_commongood">Common Good Learning Tools</a>
							</div>
							<div class="mt-0" v-if="site_config.terms_of_use_url||site_config.copyright_org.name"><span v-if="site_config.copyright_org.name"> and <a :href="site_config.copyright_org.url" target="_copyright_org">{{site_config.copyright_org.name}}</a></span><v-btn v-if="site_config.terms_of_use_url" class="ml-4 mt-2" x-small color="secondary" :href="site_config.terms_of_use_url" target="_terms_of_use"><b>Terms of Use</b></v-btn></div>
						</div>
						<div>
							<v-tooltip bottom><template v-slot:activator="{on}"><a v-on="on" href="https://www.1edtech.org/li/awards/2023" target="_blank"><img src="/images/learning_impact.svg" style="width:72px;margin:4px 4px 0 8px;"></a></template><div class="text-center">SuitCASE, Georgia’s implementation of Satchel,<br>won a 2023 1EdTech Learning Impact Award</div></v-tooltip>
						</div>
					</div></v-list-item-title></v-list-item>
				</v-list>
			</v-menu>
		</div>
	</v-app-bar>

	<!-- google translate element is rendered here for cureum_standards_tabbed mode -->
	<div v-if="cureum_standards_tabbed" style="position:absolute; right:12px; bottom:4px; z-index:100" class="d-flex align-center mr-3" :style="(gt_enabled&&!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm)?'opacity:1':'opacity:0'">
		<div id="google_translate_element_wrapper"><div id="google_translate_element"></div></div>
		<v-btn v-if="gt_restore_btn_available" x-small outlined class="ml-1 k-tight-btn" color="#333" @click.stop="restore_to_original_language">Restore To Original</v-btn>
	</div>

	<v-main class="k-content" v-if="app_initialized">
		<router-view :key="router_key"></router-view>

		<!-- iframe used for signout hack -->
		<iframe style="display:none" name="sign_out_window"></iframe>
	</v-main>
	<SignInDialog v-if="show_sign_in_dialog" @dialog_cancel="show_sign_in_dialog=false" />
	<UserPrefs v-if="show_user_prefs" @dialog_cancel="show_user_prefs=false" />
	<AdminUsers v-if="show_user_admin_tool" @dialog_cancel="show_user_admin_tool=false" />
	<AdminFrameworkCategories v-if="show_category_admin_tool" @dialog_cancel="show_category_admin_tool=false" />
	<FrameworkAccessReport v-if="show_framework_access_report" @dialog_cancel="show_framework_access_report=false" />
	<UpdateReport ref="framework_update_report" v-if="framework_update_report_showing" @dialog_cancel="framework_update_report_showing=false" />
	<FrameworkSwitcherDialog v-if="framework_switcher_showing" @dialog_cancel="framework_switcher_showing=false" />
	<SVGLines ref="svg_lines" />
	<div v-show="saved_indicator_showing" class="k-case-tree-saved-indicator"><v-icon small class="mr-2" color="#fff" style="margin-top:-3px">fas fa-check</v-icon>SAVED</div>
	<SatchelInline ref="satchel" />			<!-- for showing actual frameworks -->
	<SatchelInline ref="help_satchel" />	<!-- for showing the Sparkl help wiki framework (rendered using Satchel) -->
	<MathLiveEditor v-if="math_live_editor_original_latex!==null" 
		:original_latex="math_live_editor_original_latex" 
		:edit_alt_text="math_live_editor_edit_alt_text" 
		:original_alt_text="math_live_editor_original_alt_text" 
		@dialog_cancel="math_live_editor_original_latex=null" 
		@mathlive_cancel="math_live_editor_save_fn(null)" 
		@mathlive_save="math_live_editor_save_fn($event)" 
	/>

</v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SignInDialog from '@/components/login/SignInDialog'
import AdminUsers from '@/components/admin/AdminUsers'
import AdminFrameworkCategories from '@/components/admin/AdminFrameworkCategories'
import FrameworkAccessReport from '@/components/admin/FrameworkAccessReport'
import UpdateReport from '@/components/admin/UpdateReport'
import SVGLines from '@/components/SVGLines/SVGLines'
import AppShortcutsMixin from '@/js/AppShortcutsMixin.js'
import PostMessageMixin from '@/js/PostMessageMixin'
import HelpMixin from '@/js/HelpMixin'
import FrameworkSwitcherDialog from '@/components/frameworks/FrameworkSwitcherDialog'
import SatchelInline from './components/utilities/SatchelInline'
import UserPrefs from './components/admin/UserPrefs'
import MathLiveEditor from './components/mathlive/MathLiveEditor'

export default {
	name: 'App',
	components: { SignInDialog, AdminUsers, AdminFrameworkCategories, SVGLines, FrameworkAccessReport, UpdateReport, FrameworkSwitcherDialog, SatchelInline, UserPrefs, MathLiveEditor},
	mixins: [AppShortcutsMixin, PostMessageMixin, HelpMixin],
	data() { return {
		framework_list_component: null,
		case_tree_component: null,
		loading_error: '',
		show_sign_in_dialog: false,
		show_user_prefs: false,
		show_user_admin_tool: false,
		show_category_admin_tool: false,
		show_framework_access_report: false,
		framework_update_report_showing: false,
		framework_switcher_showing: false,
		saved_indicator_showing: false,

		gt_restore_btn_available: false,

		math_live_editor_original_latex: null,
		math_live_editor_edit_alt_text: false,
		math_live_editor_original_alt_text: '',
		math_live_editor_save_fn: null,

		ping_timeout: null,
		ping_timeout_time: 60 * 1000 * 10,	// every 10 minutes
	}},
	computed: {
		...mapState(['app_initialized', 'user_info', 'framework_records', 'framework_categories', 'site_config', 'embedded_mode', 'cureum_standards_tabbed']),
		...mapGetters(['signed_in']),
		show_resources_toggle() {
			// currently showing this toggle if site_config.resource_alignment_enabled is 'yes' AND the user has (at least) system reviewer rights
			return (this.site_config.resource_alignment_enabled == 'yes' && this.is_granted('resources', 'view_sets'))
			// limit the toggle control for showing the resource alignment interface to certain people for now (we'll expand later)
			if (['pepper@commongoodlt.com', 'mathew@commongoodlt.com', 'sunil.williams.4@gmail.com', 'artfishal@gmail.com'].includes(this.user_info.email)) return true
			return false
		},
		frameworks_or_resources_toggle: {
			get() { 
				if (!this.show_resources_toggle) return 'frameworks'
				return this.$store.state.lst.frameworks_or_resources_toggle 
			},
			set(val) { 
				this.$store.commit('lst_set', ['frameworks_or_resources_toggle', val]) 
				if (this.frameworks_or_resources_toggle == 'resources') this.go_to_route('resources')
				else this.go_to_route('')
			}
		},
		router_key() {
			let path = this.$route.fullPath
			// note: we do something fancy with this in henryconnects
		},
		single_color_scheme_on: {
			get() { return this.$store.state.lst.single_color_scheme_on },
			set(val) { this.$store.commit('lst_set', ['single_color_scheme_on', val]) }
		},
		gt_enabled: {
			get() { 
				// currently we will always show the google translate option; this could be re-enabled if we want to hide it for some reason
				return true
				return this.$store.state.lst.gt_enabled 
			},
			set(val) { this.$store.commit('lst_set', ['gt_enabled', val]) }
		},
		agency_logo_img_src() {
			if (!this.site_config.agency_logo) return ''
			return this.site_config.agency_logo
		},
		app_name_for_banner() {
			if (!empty(this.site_config.app_name_for_banner)) return this.site_config.app_name_for_banner
			return this.site_config.app_name
		},
		app_name() { return this.site_config.app_name },
		burger_links() { return this.site_config.burger_links },
		show_app_name_with_logo() { return this.site_config.show_app_name_with_logo },
		show_cn2_signin_options() { 
			return false
			return this.$store.state.site_config.app_name == 'CASE Network 2' 
		},
	},
	watch: {
		'$route.name':{immediate: true, handler(val) {
			this.$nextTick(x=>{
				if (['framework', 'framework_item', 'framework_item_with_key'].find(x=>x==this.$route.name)) {
					if (this.$store.state.lst.viewer_mode == 'tiles') {
						vapp.set_current_app_help_doc('tileview')
					} else if (this.$store.state.lst.viewer_mode == 'table') {
						if (this.$store.state.lst.viewer_table_mode == 'items') {
							vapp.set_current_app_help_doc('tableitemsview')
						} else {
							vapp.set_current_app_help_doc('tableassociationsview')
						}
					} else {
						vapp.set_current_app_help_doc('treeview')
					}
				} else {
					vapp.set_current_app_help_doc('framework_index', 'hide')
				}
			})
		}},
	},
	created() {
		// we could call MathLive.renderMathInDocument here, before we even show anything. This injects the styles into the dom, so that when we later inject mathml into the dom, it will render properly
		// MathLive.renderMathInDocument()
		// instead, for now at least, we have a hard-coded version of mathlive-core that we inject here, so that we can also inject this in PrintItems
		U.inject_mathlive_styles()

		console.log(sr('\nStandards Satchel v$1, ©2024 Common Good Learning Tools, LLC\n\n', this.$store.state.app_version), window.location.toString())
		window.vapp = this

		// set up site_config, which is brought in via a special php file included in index.html so we get it right away
		this.$store.commit('write_site_config')

		// check_session as quickly as possible
		this.check_session()
	},
	mounted() {
		this.initialize_app()
	},
	methods: {
		initialize_app(payload) {
			if (empty(payload)) payload = {}

			// AppShortcutsMixin
			this.initialize_shortcuts()

			// get search params from url string
			let params = (new URL(document.location)).searchParams
			
			// embedded mode
			if (params.get('embedded') != undefined) {
				console.log('--------------------- embedded mode')
				this.$store.commit('set', ['embedded_mode', true])

				// initialize postMessages
				this.pm_initialize()
			}

			// cureum_standards_tabbed mode
			if (params.get('cureum_standards_tabbed') != undefined) {
				console.log('--------------------- cureum_standards_tabbed')
				this.$store.commit('set', ['cureum_standards_tabbed', true])

				// initialize postMessages
				this.pm_initialize()
			}

			// if url search string includes 'assoc', make sure associations are showing
			if (params.get('assoc') != undefined) {
				this.$store.commit('lst_set', ['show_associations', true])
			} else if (this.embedded_mode) {
				// also always show associations (at least at the start) for embedded mode
				this.$store.commit('lst_set', ['show_associations', true])
			}

			// if url search string includes 'help=xxx', show the specified help document, with iframe shown in max view
			if (params.get('help') != undefined) {
				setTimeout(x=>{
					this.show_help(params.get('help'), 'large')
				}, 50)
			}
			
			// if url search string includes 'track_changes', that's the filename of an archive to track changes from
			if (params.get('track_changes') != undefined) {
				// the url has to also have the framework identifier; extract that and save the given filename to lst
				if (window.location.toString().search(/^.*?\/([a-f0-9-]{36})/) > -1) {
					let lsdoc_identifier = RegExp.$1
					if (U.is_uuid(lsdoc_identifier)) {
						let o = {}
						let s = this.$store.state.lst.track_changes_fn
						if (s) o = JSON.parse(s)
						o[lsdoc_identifier] = {
							fn: params.get('track_changes')
						}
						this.$store.commit('lst_set', ['track_changes_fn', JSON.stringify(o)])

						// if search string includes track_changes_fields, apply them
						if (params.get('track_changes_fields') != undefined) {
							this.$store.commit('lst_set', ['track_changes_fields', JSON.parse(params.get('track_changes_fields'))])
						}
						
						// if search string has 'sbshi', that's an indicator that the user wants to view the side-by-side display, starting at that identifier
						if (params.get('sbshi') != undefined) {
							this.$store.commit('lst_set_hash', ['side_by_side_editor_head_identifier', lsdoc_identifier, params.get('sbshi')])
						}
					}
				}
			}

			let resource_alignment_validation = false
			if (params.get('resource_alignment_validation') != undefined) {
				// set things up appropriately for resource alignment validation [can't set to lst until initialize_app service has completed though]
				resource_alignment_validation = true
			}

			// token login
			if (params.get('email') != undefined && params.get('token') != undefined) {
				payload.email = params.get('email')
				payload.token = params.get('token')
			}

			// sso from cglt application
			if (params.get('remote_sessid') != undefined && params.get('src_app') != undefined) {
				payload.remote_sessid = params.get('remote_sessid')
				payload.src_app = params.get('src_app')
				//console.log('incoming remote sessid from ' + payload.src_app  + ': ' + payload.remote_sessid)
				
				// clear sso params (if for some reason we stop clearing below)
				//U.clear_location_search()
			}

			// clear search string?
			U.clear_location_search()

			this.$store.dispatch('initialize_app', payload).then((token_result)=>{
				// initialize, and possibly signin, successful
				// set show_sign_in_dialog to false in case the user just signed in
				this.show_sign_in_dialog = false

				// set resource_alignment_validation appropriately
				this.$store.commit('lst_set', ['resource_alignment_validation', resource_alignment_validation])

				// if we just attempted a token signin...
				if (token_result) {
					// for token errors, we just inform the user of the error and proceed with initialization
					if (token_result != 'ok') {
						console.log(token_result)
						let msg = 'The one-time sign-in link you clicked did not work'
						if (token_result == 'token_expired') msg += ', because the link has expired'
						else if (token_result == 'token_not_found') msg += ', possibly because the link was already used once'
						else if (token_result == 'head_request') msg += ' (bad request type)'	// this should never happen in the real world
						msg += '.'
						// open the sign in dialog after they dismiss the error message
						vapp.$alert({title:'<span class="red--text text--darken-2">Sign-In Link Error</span>', text:msg}).then(x=>vapp.sign_in())
					} else {
						// else offer to let the user change their password
						let msg = 'You have been signed in by the one-time link you clicked. Would you like to change your ' + this.app_name + ' password at this time?'
						// Confirmation with property overrides
						this.$confirm({
							text: msg,
							acceptText: 'Change Password',
							cancelText: 'No thank you',
							dialogMaxWidth: 600,
						}).then(y => {
							this.change_password()
						}).catch(n=>{console.log(n)}).finally(f=>{
							// regardless of which option is chosen, call clear_login_token service to clear the login token now
							U.ajax('clear_login_token', {email:payload.email, token:payload.token})
						})
					}
				}
				// http://localhost:6051/?email=xxx&token=yyy

				// if user just signed in, start pinging normally
				// else user may have reloaded page, ping w/no_signout
				if (payload.hasOwnProperty('password') || token_result == 'ok') vapp.ping()
				else vapp.ping('no_signout')

				// if the user tried to go directly to a private framework and needed to sign in, they'd be back here...
				if (this.case_tree_component) {
					// so call refresh_lsdoc on the case_tree_component to show the framework
					this.case_tree_component.refresh_lsdoc()
				}

			}).catch((result)=>{
				// if login_error is empty, alert here; otherwise the signin window should be open and showing the error
				if (empty(this.$store.state.login_error)) {
					this.$alert('An error occurred when attempting to initialize the application. Please try refreshing your browser window.')
				}
			})
		},

		show_saved_indicator() { 
			// if we're already showing the saved indicator, cancel the timeout to hide it
			clearTimeout(this.show_saved_indicator_timeout)
			this.saved_indicator_showing = true
			this.show_saved_indicator_timeout = setTimeout(x=>this.saved_indicator_showing = false, 2000)
		},

		show_framework_switcher() { this.framework_switcher_showing = true },
		hide_framework_switcher() { this.framework_switcher_showing = false },

		go_to_route(new_route, event) {
			if (!empty(event) && !empty(event.target)) $(event.target).closest('button').blur()

			if (empty(new_route)) new_route = this.frameworks_or_resources_toggle	// 'resources' or 'frameworks'

			new_route = '/' + new_route
			if (this.$route.path == new_route) return

			this.$router.push({ path: new_route + window.location.search })
		},

		go_to_wiki_link(wiki_doc_identifier) {
			// in wiki mode, links from one wikidoc to another wikidoc will call this fn with the identifier of the doc to go to
			let route = `${this.case_tree_component.lsdoc_identifier}/${wiki_doc_identifier}`
			this.go_to_route(route)
		},

		// this fn can be used by other components that need to open a framework, then execute some other fn, taking into account the fact that the framework might or might not already be showing
		open_framework_then(framework_identifier, viewer_post_load_execute_fn) {
			// if the given framework is already showing, just execute the provided fn right away
			if (this.case_tree_component && this.case_tree_component.lsdoc_identifier == framework_identifier && window.location.pathname.includes(framework_identifier)) {
				if (viewer_post_load_execute_fn) setTimeout(()=>viewer_post_load_execute_fn())
			} else {
				// else stash the fn in store, then open the framework; then then fn will be executed in CASEFrameworkViewer.vue->initialize_tree_post_build_cfo
				if (viewer_post_load_execute_fn) this.$store.commit('set', ['viewer_post_load_execute_fn', viewer_post_load_execute_fn])

				// if a different framework was loaded previously, we have to first remove the CASEFrameworkViewer component, then re-open it a tick later, to force the new framework to load
				if (this.case_tree_component?.hide_tree) {
					this.case_tree_component.hide_tree()
					setTimeout(()=>{
						this.framework_list_component.view_framework([framework_identifier, framework_identifier])
					}, 10)

				} else {
					// else we can just tell framework_list_component to load the framework now
					this.framework_list_component.view_framework([framework_identifier, framework_identifier])
				}
				// this.go_to_route(`${framework_identifier}/${framework_identifier}`)
			}
		},

		// this can be called to determine if a case tree component is open and is currently visible on the screen
		case_tree_component_is_showing() {
			return (this.case_tree_component && $(this.case_tree_component.$el).is(':visible'))
		},

		is_granted(verb, noun) {
			let system_role = this.user_info.system_role
			let user_id = this.user_info.user_id

			// general note for below: when in cureum_standards_tabbed mode, we disable admin, editing, and reviewing functionality here

			// users with system_role 'admin' can do anything; call is_granted('super') if only admins are supposed to do what you're checking on
			if (system_role == 'admin' && !this.cureum_standards_tabbed) return true

			// users with system_role 'editor' (and admins) can create new frameworks, via import or from scratch
			if (verb == 'create_new_framework') {
				return (system_role == 'editor' && !this.cureum_standards_tabbed)
			}

			// for viewing the resource alignment interface... (I know we're switching verb and noun here)
			if (verb == 'resources') {
				// to view resource alignments, user must be signed in with admin, editor, or reviewer privileges
				if (noun == 'view_sets') return (system_role == 'reviewer' || system_role == 'editor' || system_role == 'admin')

				// to create new sets, user must be signed in with admin or editor privileges
				if (noun == 'create_sets') return (system_role == 'editor' || system_role == 'admin')

				// note that whether you're allowed to edit alignments is currently controlled directly in ResourceSet
			}

			// for viewing frameworks...
			if (verb == 'view_framework') {
				// get adoptionStatus of framework; noun == framework identifier
				let fr = this.framework_records.find(x=>x.lsdoc_identifier == noun)
				if (empty(fr)) return false	// shouldn't happen

				// anyone can see frameworks whose adoptionStatus doesn't start with 'Private'
				if (!U.framework_is_private(fr)) return true

				// roles editor and admin can see any framework
				if (system_role == 'editor') return true
				if (system_role == 'admin') return true

				// system role reviewer can also see 'Private X' frameworks (meaning anything)
				if (system_role == 'reviewer') return true

				// check to see if this user has been granted editor or reviewer rights to this particular framework
				let ur = fr.ss_framework_data.user_rights[user_id] || 'none';
				if (['reviewer', 'editor', 'admin'].includes(ur)) return true
			}

			// for reviewing frameworks...
			if (verb == 'review_framework' && !this.cureum_standards_tabbed) {
				// system roles editor and reviewer (and admin, which is handled above) can review any framework
				if (system_role == 'editor' || system_role == 'reviewer') return true

				// get user_rights of framework; noun == framework identifier
				let fr = this.framework_records.find(x=>x.lsdoc_identifier == noun)
				if (empty(fr)) return false	// shouldn't happen
				let fr_user_rights = fr.ss_framework_data.user_rights

				// check to see if this user has been granted admin, editor, or reviewer rights to this particular framework
				let ur = fr_user_rights[user_id] || 'none';
				if (ur == 'admin' || ur == 'editor' || ur == 'reviewer') return true

				// note that for most purposes, the 'reviewer' role is just used to allow a user to view a private framework, which is handled above
				// but there are some things (i.e. seeing associations when the associations have been marked as hidden to most people) where we explicitly check the reviewer permission level
			}

			// for editing frameworks...
			if (verb == 'edit_framework' && !this.cureum_standards_tabbed) {
				// system role editor (and admin, which is handled above) can edit any framework
				if (system_role == 'editor') return true

				// get user_rights of framework; noun == framework identifier
				let fr = this.framework_records.find(x=>x.lsdoc_identifier == noun)
				if (empty(fr)) return false	// shouldn't happen
				let fr_user_rights = fr.ss_framework_data.user_rights

				// check to see if this user has been granted admin or editor rights to this particular framework
				let ur = fr_user_rights[user_id] || 'none';
				if (ur == 'admin' || ur == 'editor') return true
			}

			// for administering frameworks...
			if (verb == 'admin_framework' && !this.cureum_standards_tabbed) {
				// system_role admin, which is handled above, can admin any framework

				// get user_rights of framework; noun == framework identifier
				let fr = this.framework_records.find(x=>x.lsdoc_identifier == noun)
				if (empty(fr)) return false	// shouldn't happen
				let fr_user_rights = fr.ss_framework_data.user_rights

				// check to see if this user has been granted admin rights to this particular framework
				if (fr_user_rights[user_id] == 'admin') return true
			}

			return false
		},

		reset_app() {
			// this should be called when a component detects that the user is not authorized for something
			this.go_to_route('')
			this.initialize_app()
		},

		// determine if the user's session is still active; if not, show a message and call the sign out process
		ping(type) {
			// we want to automatically call this fn every ping_timeout_time ms. if it's manually called by something else, we reset the timeout
			clearTimeout(this.ping_timeout)

			let sign_user_out = () => {
				let msg = 'You are not signed in. You may have been automatically signed out due to inactivity.'
				this.$alert({
				    text: msg,
				}).finally(f=>{
					this.$store.dispatch('sign_out')
				})
			}

			// use plain-vanilla XMLHttpRequest to call the ping service
			var xhr = new XMLHttpRequest()
			xhr.onreadystatechange = function() {
			    if (xhr.readyState === 4) {		// fetch operation is done
					if (xhr.responseText == 'ok') {
						console.log('ping OK')
						// set the timeout to re-call this fn after ping_timeout_time
						vapp.ping_timeout = setTimeout(()=>{ vapp.ping() }, vapp.ping_timeout_time)

					} else {
						if (type != 'no_signout') {
							sign_user_out()
							console.log('ping FAIL (user signed out)')
						} else {
							// don't show sign out if user loading or refreshing page but not logged in
							console.log('ping FAIL (ping w/no_signout flag)')
						}
					}
				}
			}
			xhr.open('GET', '/src/ping.php')
			xhr.send()
		},

		reload_window() {
			// when we need to reload, if we are in embedded or cureum_standards_tabbed mode, we have set the search string to make sure we open back up in that mode
			if (this.embedded_mode) window.location.search = 'embedded'
			else if (this.cureum_standards_tabbed) window.location.search = 'cureum_standards_tabbed'
			// otherwise just use location.reload
			else window.location.reload()
		},

		check_session() {
			// if we're not using OAuth login, we have to pass the session_id back and forth.
			// this code will have no effect if we're NOT using OAuth.

			// when the application first loads after the user has signed in, we should get a session_id in the search string of window.location...
			// if we have 'not_authorized=true', it means OAuth said the user wasn't authorized to sign in (even though they entered proper credentials)
			if (window.location.search.search(/not_authorized=true/) != -1) {
				// this is currently used for CASE Network only
				this.$alert({title:'Sorry!', text:'You are not authorized by 1EdTech for registered access to CASE Network 2. Please go to <a href="https://www.1edtech.org/form/cn2" target="_blank">this webpage</a> to request registered access.<div class="mt-2">In the meantime, you are welcome to browse all frameworks in CASE Network 2 without signing in.</div>'})

				// look for a pathname stored previously; if not found we'll go to path '/'
				let pathname = U.local_storage_get('satchel_pathname_after_login', '/')

				// clear the stored pathname out if there, then go to the route
				U.local_storage_clear('satchel_pathname_after_login')
				console.log('restoring to pathname ' + pathname)
				this.$router.replace({ path: pathname })

			} else if (window.location.search.search(/session_id=(.*)\b/) == -1) {
				// if we *don't* find a session_id here...

				// look for the session_id in localstorage, where we would have stored it the previous time the user signed in
				U.session_id = U.local_storage_get('satchel_session_id', '')

				// if we still don't have a session_id, the user isn't signed in (which is fine for satchel)

			} else {
				// else we *did* find a session_id in the search string, so...

				// save session_id in U.session_id
				U.session_id = RegExp.$1
				console.log('found session_id: ' + U.session_id)

				// store the session_id in localstorage
				U.local_storage_set('satchel_session_id', U.session_id)

				// look for a pathname stored previously; if not found we'll go to path '/'
				let pathname = U.local_storage_get('satchel_pathname_after_login', '/')

				// clear the stored pathname out if there, then go to the route
				U.local_storage_clear('satchel_pathname_after_login')
				this.$router.replace({ path: pathname })
			}
		},

		sign_in(evt) {
			// if we're using OIDC/Auth0 for login, redirect to a back-end script to initiate the signin process (unless shift is held down, or unless this is CN2, where we give special options)
			if (evt == 'oauth' || ((this.$store.state.use_auth0_login || this.$store.state.use_oidc_login) && !(evt && evt.shiftKey == true) && !this.show_cn2_signin_options)) {
				// before we go, remember the pathname we're trying to get to in localstorage, so we can restore to it when we get back
				U.local_storage_set('satchel_pathname_after_login', window.location.pathname)

				let url
				if (this.$store.state.use_auth0_login) url = '/src/auth/auth0_login.php?route=login' // local testing http://127.0.0.1:8002/src/auth/auth0_login.php?route=login
				else if (this.$store.state.use_oidc_login) url = '/src/oidclogin.php'
				window.document.location.assign(url)

			} else {
				this.show_sign_in_dialog = true
			}
		},

		change_password() {
			this.$prompt({
				title: 'Change Password',
				text: 'Enter the new password you would like to use for your <nobr>' + this.app_name + '</nobr> account:',
				password: true,
				acceptText: 'Use this password',
			}).then(password => {
				if (!empty(password)) {
					this.$prompt({
						title: 'Confirm New Password',
						text: 'Please confirm the new password you just entered:',
						password: true,
						acceptText: 'Confirm and Save new password',
					}).then(new_password => {
						if (password != new_password) {
							this.$alert('The two passwords you entered do not match!').then(x=>this.change_password())
							return
						}

						let payload = {
							user_id: this.user_info.user_id,
							password: password,
						}

						U.loading_start()
						U.ajax('change_password', payload, result=>{
							U.loading_stop()
							if (result.status != 'ok') {
								console.log('Error in admin ajax call'); vapp.ping(); return;
							}

							this.$alert({title:'Success!', text:'Password changed.'})
						});

					}).catch(n=>{console.log(n)}).finally(f=>{})
				}
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		signed_in_only(msg) {
			if (!this.signed_in && this.$store.state.require_sign_in_for_full_functionality) {
				// this is currently used only for CASE Network
				if (empty(msg)) msg = 'perform this action'

				msg = `You must be signed in with registered access to CASE Network 2 in order to ${msg}. Please:<ul>`
				msg += `<li>go to <a href="https://www.1edtech.org/form/cn2" target="_blank">this webpage</a> to request registered access,</li>`
				msg += `<li>contact <a href="mailto:contact@commongoodlt.com">Common Good Learning Tools</a> for more assistance,`
				msg += `<li>or continue to browse frameworks in CASE Network 2 without signing in.</li>`
				msg += `</ul>`
				this.$alert({title:'Sorry!', text:msg, dialogMaxWidth:600})
				return true
			}
			return false
		},

		sign_out() {
			this.$store.dispatch('sign_out')
		},

		// open the guid translator, send session_id for sso to cgrt
		open_cgrt() {
			let session_id = U.local_storage_get('satchel_session_id', '')
			let cgrt_ip = this.$store.state.cgrt_ip

			if (empty(session_id)) {
				// this just returns the user's php session_id
				U.ajax('get_sid', {}, result=>{

							if (result.status != 'ok') {
								console.log('Unable to get session_id'); vapp.ping(); return;
							}
							window.open(sr('$1/?session_id=$2', cgrt_ip, result.session_id))
						});
			} else {
				window.open(sr('$1/?session_id=$2', cgrt_ip, session_id))
			}	
		},

		// cglt sso login, user must be signed in to source app e.g. ccbo satchel -> inspire
		// the target_app param is configured on server config.php
		cglt_sso(target_app, target_url_suffix) {
			let target_url = `${this.site_config.cglt_sso[target_app]}${target_url_suffix}`
			let src_app = this.site_config.cglt_sso['src_app']

			console.warn(`cglt sso: ${target_url}/?remote_sessid=${U.session_id}&src_app=${src_app}`)
			window.open(`${target_url}/?remote_sessid=${U.session_id}&src_app=${src_app}`, 'alt_cureum')
		},

		show_framework_update_report(flag, archive_val) {
			if (vapp.signed_in_only('view Framework Update Reports')) return

			this.framework_update_report_showing = true

			// if we're currently showing a framework, open to that framework
			let framework_identifier = null
			let report_type = 'last'
			if (this.case_tree_component?.lsdoc_identifier) {
				framework_identifier = this.case_tree_component.lsdoc_identifier
				// if we got an 'archives' flag, show all archives for the framework
				if (flag == 'archives') {
					report_type = 'framework_all_archives'

				// else if flag is framework_archive_compare, show that report; this is called from the bottom banner when we're in track changes mode, so the archive should already be stored in archive_compare_arr
				} else if (flag == 'framework_archive_compare') {
					report_type = 'framework_archive_compare'

				// else if lst.update_report_type is 'update_type', switch to the framework report
				} else if (this.$store.state.lst.update_report_type == 'update_type') {
					report_type = 'framework'
				}	
			}
			if (framework_identifier) {
				setTimeout(()=>this.$refs.framework_update_report.show_framework_report(framework_identifier, report_type), 50)
			}
		},

		reset_framework_update_report_archives(framework_identifier) {
			this.$store.commit('set', [this.$store.state.framework_archives, framework_identifier, null])
		},

		show_line(line_props) {
			// return the line's uuid, so the caller can kill it if they wish
			return this.$refs.svg_lines.add_line(line_props)
		},

		remove_line(uuid) {
			this.$refs.svg_lines.remove_line(uuid)
		},

		remove_all_lines() {
			this.$refs.svg_lines.remove_all_lines()
		},

		/////////////////////////
		// google translate
		initialize_google_translate() {
			// note that the translate element has to be in the dom and visible; we set its opacity to 0 to hide it until the the user says they want to see it
			U.googleTranslateElementInit()

			// once every second...
			setInterval(x=>{
				let l = U.googleTranslateGetCurrentLanguage()

				// get the "default" language for what's currently being viewed, from site_config ('en' is the default)
				let default_language = this.site_config.default_language
				
				// if we're viewing a document, use its language property if listed
				if (vapp.case_tree_component?.framework_record) {
					if (!empty(vapp.case_tree_component.framework_record.json.CFDocument.language)) {
						default_language = vapp.case_tree_component.framework_record.json.CFDocument.language
					}
				}
				// console.log(`current translation: “${l}”; default language: “${default_language}”`)

				// if the user has explicitly chosen the default language in the Google Translate menu, cancel google translate, because when GT tries to translate english into english (e.g.), wierd things happen (e.g. with latex)
				if (l == default_language) {
					this.restore_to_original_language()
					l = ''
				}

				// set gt_restore_btn_available to true iff we're viewing a translation
				this.gt_restore_btn_available = !empty(l)

				// and if google translate is showing the annoying spinner, hide it. This will likely need to be updated as google translate evolves
				let jq = $('#loom-companion-mv3').next()
				if (jq.find('svg').length > 0) {
					jq.hide()
				}
			}, 1000)
		},

		toggle_google_translate_btn(fn) {	// 'show' or 'hide'
			$('#google_translate_element_wrapper')[fn]()
		},

		restore_to_original_language() {
			U.googleTranslateRestoreOriginal()
		},

		toggle_google_translate(val) {
			if (typeof(val) != 'boolean') val = !this.gt_enabled
			this.gt_enabled = val

			// when user chooses to disable, restore to original language, in case they translated
			if (this.gt_enabled == false) {
				this.restore_to_original_language()
			}
		},

		show_math_live_editor(args) {
			// we originally accepted two arguments like so:
			if (arguments.length == 2) {
				this.math_live_editor_original_latex = arguments[0]
				this.math_live_editor_edit_alt_text = false
				this.math_live_editor_original_alt_text = ''
				this.math_live_editor_save_fn = arguments[1]
			
			// starting 1/2025, we use an args object like so:
			} else {
				this.math_live_editor_original_latex = args.original_latex
				this.math_live_editor_edit_alt_text = args.edit_alt_text || false	// default is false
				this.math_live_editor_original_alt_text = args.original_alt_text || ''	// default is empty string
				this.math_live_editor_save_fn = args.save_fn
			}

			// call like this:
			// vapp.show_math_live_editor('\\sqrt{x}', new_latex => { })
		},
	}
}
</script>

<style lang="scss">
body, html {
	font-size:18px;
	background-color:#999!important;
}

html {
	// this hides the otherwise-everpresent scroll bar on the right side of the window, which is never needed in the Satchel app
	overflow:hidden;
}

.v-application {
	// font-family added in index.html via env variable
	background-color:#fff!important;
}

.theme--dark.v-application {
	// background:transparent;
}

.v-btn {
	text-transform: none;
	font-size:16px;
}

.k-app-toolbar {
	// background-color will be added by k-banner-color
}

// .k-toolbar-agency-img-outer and .k-toolbar-agency-img are defined in index.html based on env; this lets us adjust the image and/or app name

.k-toolbar-app-title {
	position:absolute;
	left:0;
	cursor:pointer;
	font-size:28px;
	color:#fff;
	font-weight:bold;
	// padding-left is defined in .eng/index.html
	z-index:2;
}

.k-toolbar-app-logo {
	position:absolute;
	left:165px;
	top:-15px;
}

.k-signed-in-as {
	white-space:nowrap;
	font-size:14px;
}

.k-signed-in-as-menu-item {
	// min-height:30px!important;
	// height:30px!important;
	// padding-top:16px;
	text-align:center;
	font-style: italic;
}

.k-toolbar-sparkler-placeholder {
	display:inline-block;
	width:18px;
}

.k-content {
}

.k-case-tree-saved-indicator {
	position:fixed;
	left:8px;
	top:8px;
	z-index:99999999;
	background-color:$v-green-darken-3;
	color:#fff;
	font-weight:bold;
	font-size:18px;
	line-height:18px;
	padding:6px 10px;
	border-radius:8px;
	border:4px solid $v-amber-accent-4;
	transition:all 0.2s;
	opacity:1;
	box-shadow: 0 0 10px rgba(255, 230, 0, 0.8), 0 0 20px rgba(255, 230, 0, 0.6);
}

// Google translate functionality:
// fix the translate menu in the window
#google_translate_element_wrapper { 
	opacity:0.4;

	.goog-te-gadget-simple {
		border-radius:6px;
		padding:4px;
	}
}

#google_translate_element_wrapper:hover {
	opacity:1;
}

// When another language is selected, Google tries to show a bar at the top of the page; hide it
.skiptranslate iframe.skiptranslate {
	display: none !important;
} 
body {
	// google tries to set the top property of body; this has to be overridden
	top: 0px !important; 
}

</style>
