// Copyright 2023, Common Good Learning Tools LLC
import { mapState, mapGetters } from 'vuex'
// in includer
// import HelpMixin from './HelpMixin'

export default {
	// in includer
	// mixins: [HelpMixin],

	data() { return {
		help_loaded: false,
		// by default, the current_app_doc is the overview page
		current_app_doc: 'overview',
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	mounted() {
		// note that this mixin must be included in the same component (e.g. App.vue) that includes a SatchelInline component using the following signature:
		// <SatchelInline ref="help_satchel" />

		// we open Satchel to the help framework (in Satchel "wiki mode") in this component

		// allow other components to access vapp.show_help (including in @click handlers in html) by using U.show_help
		U.show_help = this.show_help
	},
	methods: {
		set_current_app_help_doc(doc_id) {
			// when this is called, we set current_app_doc so that if the 'i' btn at the top is called, we'll go to this help doc
			// send '' to clear 
			this.current_app_doc = doc_id
		},

		toggle_help() {
			// this is called when the big 'i' btn at the top of the app is loaded
			// if we're currently showing the help iframe, hide it
			if (this.$refs.help_satchel.show_satchel) {
				this.$refs.help_satchel.execute('hide')
				
			// else show the help iframe...
			} else {
				// if current_app_doc is empty, just show whatever doc was previously showing
				if (empty(this.current_app_doc)) {
					this.$refs.help_satchel.execute('show')

				} else {
					// else go to the current_app_doc page
					this.show_help(this.current_app_doc)

					// then clear current_app_doc, because if the user changes to a different doc, then opens help again via the i in the top of the app, we want them to remain where they were
					this.current_app_doc = ''
				}
			}
		},

		show_help(doc_id, start_size) {
			// to initialize the component, we set the satchel_origin as follows 
			this.$refs.help_satchel.set_satchel_origin(this.site_config.help_framework_satchel_origin)

			let show_o = {
				start_size: start_size,	// if this is empty it will have no effect
				allow_minimize: true,
			}
			this.$refs.help_satchel.execute('show', show_o).then(()=>{
				let load_o = {
					framework_identifier: this.site_config.help_framework_identifier,
					// note that Satchel will look up the item_identifier (guid) from the doc_id (humanCodingScheme) 
					item_identifier: doc_id,
					wiki_flavor: this.site_config.help_flavor,
				}
				this.$refs.help_satchel.execute('load_framework', load_o).then(()=>{
					this.help_loaded = true
				})
			})
		},
	}
}