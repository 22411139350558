import { mapState, mapGetters } from 'vuex'
export default {
	data() { return {
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
	},
	watch: {
	},
	methods: {
		mirror_update_by_user(framework_record, force_update) {
			let payload = {
				'mirror_framework_identifier': framework_record.lsdoc_identifier,
				'mirror_source_rest_api_url': 'MANUAL_UPDATE',
				'user_id': this.user_info.user_id
			}
			if (force_update === 'force_update') { payload['force_import'] = 'yes' }

			this.$store.dispatch('manual_mirror_update', payload).then((result)=>{
				// update mirror info fields on success or fail
				this.$store.commit('set', [framework_record, ['ss_framework_data', 'next_auto_update'], result.mirror_framework_data.next_auto_update])
				this.$store.commit('set', [framework_record, ['ss_framework_data', 'mirror_source_rest_api_url'], result.mirror_framework_data.mirror_source_rest_api_url])
				this.$store.commit('set', [framework_record, ['ss_framework_data', 'last_mirror_sync_date'], result.mirror_framework_data.last_mirror_sync_date])
				this.$store.commit('set', [framework_record, ['ss_framework_data', 'last_mirror_sync_status'], result.mirror_framework_data.last_mirror_sync_status])
				this.$store.commit('set', [framework_record, ['ss_framework_data', 'mirror_auto_updates'], result.mirror_framework_data.mirror_auto_updates])

				if (result.status === 'ok') {
					if (result.update_status === 'ok_updated') {
						// if we're currently viewing the framework, reload
						if (vapp.framework_list_component.case_tree_lsdoc_identifier == framework_record.lsdoc_identifier) {
							vapp.case_tree_component.refresh_lsdoc()

						} else {
							// else make sure we'll reload the next time the framework is opened
							this.$store.commit('set', [framework_record, 'framework_json_loaded', false])
						}

						this.$alert({
							text: sr('Mirror has been <b>updated</b> from source framework at <b>$1</b>; an archive of the previous version has been created.', framework_record.ss_framework_data.mirror_source_rest_api_url),
							dialogMaxWidth:600
						}).then(y=>{
						})
					} else if (result.update_status === 'ok_unchanged') {
						this.$alert({text: sr('Source framework from <b>$1</b> is <b>unchanged</b> <nobr>since last check.</nobr>', framework_record.ss_framework_data.mirror_source_rest_api_url), dialogMaxWidth:600})
					} else if (result.update_status === 'user_must_confirm') {
						this.$confirm({
							title: 'Confirm Mirror Update',
							text: 'The source framework for this mirror has been edited since it was last checked. Please confirm that the mirrored framework should be updated to reflect these edits.',
							acceptText: 'Update Mirrored Framework',
							dialogMaxWidth: 800
						}).then(y => {
							this.mirror_update_by_user(framework_record, 'force_update')
						}).catch(n=>{console.log(n)}).finally(f=>{})
					}
				}
			}).catch((e)=>{
				console.log(e)
			})
		},
	}
}
