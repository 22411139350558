/////////////////////////////////////////////////
// helper classes/fns

class LinkURI {
	constructor(data) {
		if (empty(data) || typeof(data) != 'object') data = {}

		sdp(this, data, 'title', '')
		sdp(this, data, 'identifier', '')
		sdp(this, data, 'uri', '')

		// CASE 1.1: this is only allowed in CFAssociation originNodeURI and destinationNodeURI, and is optional
		if (data.targetType) this.targetType = data.targetType
	}

	is_complete() {
		if (this.title == undefined) return false	// empty string titles are allowed
		if (!this.identifier) return false
		if (!this.uri) return false
		return true
	}
}
window.LinkURI = LinkURI

window.StringArray = function(data) {
	// if not an array,
	if (!$.isArray(data)) {
		// if empty or an object, use empty array
		if (empty(data) || typeof(data) == 'object') return []

		// else convert to string, then convert to an array, splitting on commas if there
		data = (data + '').split(/\s*,\s*/)
	}

	let arr = []
	for (let s of data) {
		// convert each array val to a string
		arr.push(s + '')
	}

	return arr
}

window.LinkURIArray = function(data) {
	// if empty, or not an array, use empty array
	if (empty(data) || !Array.isArray(data)) return []

	let arr = []
	for (let o of data) {
		arr.push(new LinkURI(o))
	}

	return arr
}

/////////////////////////////////////////////////////////
class CASEFieldDef {
	constructor(data) {
		// Default field has type 'string', default value '', is not required, and has no constructor_class
		sdp(this, data, 'type', 'string', ['string', 'boolean', 'number', 'object', 'array'])
		sdp(this, data, 'required', false)
		// if applicableItemTypes is specified, it indicates that this field should only be editable for the provided applicableItemTypes
		sdp(this, data, 'applicableItemTypes', [])
		sdp(this, data, 'description', '')
		sdp(this, data, 'always_display', 'no')		// if 'yes', always display the MD value on item tiles; if 'no', will only be displayed when identifiers are displayed
		sdp(this, data, 'display_key', '')			// used to determine what to call the key in the item tile; if empty, we'll use the key value itself (this lets us use, e.g., 'creditOption' as the key in `extensions`, but have it display as "Credit Option"
		sdp(this, data, 'constructor_class', '')
		sdp(this, data, 'constructor_fn', '')

		if (!empty(data.default)) this.default = data.default
		else if (this.type == 'string') this.default = ''
		else if (this.type == 'boolean') this.default = false
		else if (this.type == 'number') this.default = -1
		else if (this.type == 'object') {
			// if we have a constructor_class, get an empty object of that class as the default
			if (this.constructor_class) {
				this.default = new window[this.constructor_class]({})
			} else this.default = {}
		} else if (this.type == 'array') {
			this.default = []
		}

		sdp(this, data, 'legal_vals', [])
	}

	to_json_value(val) {
		// if the value is required, we've got to return something
		// otherwise check to see if we need to save the value we've got
		if (!this.required) {
			// else we check for a non-empty value
			if (this.type == 'object') {
				if (empty(val)) return null
				// if we created the object via a constructor_class (e.g. LinkURI), that class must have an is_complete method we can call here
				if (this.constructor_class) {
					if (!val.is_complete()) {
						return null
					}
				} else if (!U.object_has_keys(val)) return null
			} else if (this.type == 'array') {
				if (empty(val)) return null
				else if (val.length == 0) return null
				else if (val.length == 1 && val[0] == '') return null
			} else {
				// if empty or === default val, return null
				if (empty(val) || val === this.default) return null
			}
		}

		// if we get here we're returning the val. for objects and arrays, make a deep copy
		if (this.type == 'object') {
			val = $.extend(true, {}, val)
		} else if (this.type == 'array') {
			let o = $.extend(true, {}, {arr: val})
			val = o.arr ?? []
		}

		return val
	}

}
window.CASEFieldDef = CASEFieldDef

class CASEEntityClass {
	// when we set fields, we correct invalidities
	// so to check if an entity is valid, call is_valid on the raw json (before passing it through the class constructor
	field_val(def, key, data) {
		// note that def must be a CASEFieldDef record
		// if data is undefined, return the default value
		if (!data) return def.default

		// get value
		let val = data[key]

		// if an empty string or null or undefined, return the default value
		if (val === '' || val === null || val === undefined) {
			// but for an object or array, make a copy of the default value
			if (def.type == 'object') {
				if (def.constructor_class) return new window[def.constructor_class](def.default)
				return $.extend(true, {}, def.default)
			} else if (def.type == 'array') {
				let o = $.extend(true, {}, {arr: val})
				return o.arr ?? []
			} else return def.default
		}

		// check type; deal with arrays
		let t = typeof(data[key])
		if (t == 'object' && Array.isArray(val)) t = 'array'

		// if type doesn't match, convert to type somehow
		if (def.type != t) {
			if (def.type == 'string') {
				// string: just convert to a string
				val = '' + val

			} else if (def.type == 'boolean') {
				// boolean: string 'true' or 'TRUE' = true
				if (val == 'true' || val == 'TRUE') val = true
				// number: 0 = false; otherwise true
				else if (t == 'number') val = (val) ? true : false
				// else it's true, because if it was null or undefined we already would have used the default value above
				else val = true

			} else if (def.type == 'number') {
				// number: convert to number, then if isNaN, use default value
				val = 1 * val
				if (isNaN(val)) val = def.default

			} else if (def.type == 'object') {
				// object: nothing to do here except use an empty object
				val = {}

			} else if (def.type == 'array') {
				// for an array, if we got a non-array, create a one-item array
				val = [val]
			}
		}

		// if the field has a constructor_class or constructor_fn, use it; note that we send in both the particular val and the full data
		if (def.constructor_class) {
			val = new window[def.constructor_class](val, data)
		} else if (def.constructor_fn) {
			val = window[def.constructor_fn](val, data)

		// else if the field is an object, make a deep copy
		} else if (t == 'object') {
			val = $.extend(true, {}, val)

		// else if the field is an array, use this (hackish?) way to make a deep copy
		} else if (t == 'array') {
			let o = $.extend(true, {}, {arr: val})
			val = o.arr ?? []

		// else if the field has legal_values, check against them, and if it's illegal, use the default value
		// note that we only support legal_values for strings and numbers
		} else if (def.legal_values) {
			if (!def.legal_values.includes(val)) {
				val = def.default
			}
		}

		return val
	}

	constructor(data, fields, custom_extension_fields) {
		if (!data) data = {}

		// case 1.0/1.1 properties
		for (let key in fields.case_1_0_fields) {
			this[key] = this.field_val(fields.case_1_0_fields[key], key, data)
		}
		if (fields.case_1_1_fields) for (let key in fields.case_1_1_fields) {
			this[key] = this.field_val(fields.case_1_1_fields[key], key, data)
		}

		// extensions
		this.extensions = {}

		// standard satchel_extension_fields
		if (fields.satchel_extension_fields) for (let key in fields.satchel_extension_fields) {
			// at least temporarily, allow for converting from legacy satchel-specific fields to extension fields
			if (fields.legacy_satchel_specific_fields?.includes(key) && !empty(data[key])) {
				this.extensions[key] = this.field_val(fields.satchel_extension_fields[key], key, data)
			} else {
				this.extensions[key] = this.field_val(fields.satchel_extension_fields[key], key, data.extensions)
			}
		}

		// custom extensions for this satchel instance
		if (custom_extension_fields) for (let key in custom_extension_fields) {
			this.extensions[key] = this.field_val(custom_extension_fields[key], key, data.extensions)
		}

		// if we received any extensions that aren't satchel-defined, just pass them through
		if (data.extensions && typeof(data.extensions == 'object')) {
			for (let key in data.extensions) {
				if ((!fields.satchel_extension_fields || !fields.satchel_extension_fields[key]) && (!custom_extension_fields || !custom_extension_fields[key])) {
					this.extensions[key] = data.extensions[key]
				}
			}
		}
	}

	generate_identifier() {
		this.identifier = U.new_uuid()
	}

	// each derived class must implement a generate_uri fn that calls this; CFDocument uses a special-purpose one
	generate_uri_base(document, api_name) {	// api_name should be, e.g., 'CFItems'
		this.uri = U.generate_child_uri(document, this.identifier, api_name)
	}

	// this should be overwritten on server prior to file save
	// client will then receive server change timestamp and update doc in store
	generate_date() {
		this.lastChangeDateTime = '*NOW*'
		//this.lastChangeDateTime = U.case_current_time_string()
	}

	complete_data(param) {
		// param is a document for most entities, but a framework_domain for the CFDocument
		if (!this.identifier) this.generate_identifier()
		if (!this.uri) this.generate_uri(param)
		// note that generate_date will overwrite the existing date if there is one
		this.generate_date()
	}

	// generate a "clean" version of the entity, which only includes fields that are either required, or have a non-empty value
	// if flag is 'for_update', add '*CLEAR*' for non-required fields that have an empty value 
	to_json_base(fields, custom_extension_fields, flag) {
		let o = {}

		// case 1.0/1.1 properties
		for (let key in fields.case_1_0_fields) {
			let val = fields.case_1_0_fields[key].to_json_value(this[key])
			if (val !== null) o[key] = val
			else if (flag == 'for_update') o[key] = '*CLEAR*'
		}
		if (fields.case_1_1_fields) for (let key in fields.case_1_1_fields) {
			let val = fields.case_1_1_fields[key].to_json_value(this[key])
			if (val !== null) o[key] = val
			else if (flag == 'for_update') o[key] = '*CLEAR*'
		}

		// satchel extensions -- note that we don't explicitly *CLEAR* sub-elements of extensions
		let extensions = {}
		if (fields.satchel_extension_fields) for (let key in fields.satchel_extension_fields) {
			let val = fields.satchel_extension_fields[key].to_json_value(this.extensions[key])
			if (val !== null) extensions[key] = val
		}
		
		// custom extensions for this satchel instance
		if (custom_extension_fields) for (let key in custom_extension_fields) {
			let val = custom_extension_fields[key].to_json_value(this.extensions[key])
			if (val !== null) extensions[key] = val
		}

		// extensions that aren't satchel-defined
		for (let key in this.extensions) {
			if ((!fields.satchel_extension_fields || !fields.satchel_extension_fields[key]) && (!custom_extension_fields || !custom_extension_fields[key])) {
				let val = this.extensions[key]
				if (typeof(val) == 'object') {
					if (Array.isArray(val)) {
						let o = $.extend(true, {}, {arr: val})
						val = o.arr ?? []
					} else {
						val = $.extend(true, {}, val)
					}
				}
				extensions[key] = val
			}
		}

		// now if we got any extensions, add to o
		if (U.object_has_keys(extensions)) {
			o.extensions = extensions
		} else if (flag == 'for_update') {
			// otherwise *CLEAR* the whole extensions field
			o.extensions = '*CLEAR*'
		}

		return o
	}
}
window.CASEEntityClass = CASEEntityClass

class CFDocument extends CASEEntityClass {
	static fields = {
		// fields that are official CASE 1.0
		case_1_0_fields: {
			identifier: new CASEFieldDef({required: true}),
			uri: new CASEFieldDef({required: true}),
			creator: new CASEFieldDef({required: true}),
			title: new CASEFieldDef({required: true}),
			lastChangeDateTime: new CASEFieldDef({required: true}),
			officialSourceURL: new CASEFieldDef({}),
			publisher: new CASEFieldDef({}),
			description: new CASEFieldDef({}),
			subject: new CASEFieldDef({type:'array', constructor_fn:'StringArray'}),
			subjectURI: new CASEFieldDef({type:'array', constructor_fn:'LinkURIArray'}),
			language: new CASEFieldDef({}),
			version: new CASEFieldDef({}),
			adoptionStatus: new CASEFieldDef({}),
			statusStartDate: new CASEFieldDef({}),
			statusEndDate: new CASEFieldDef({}),
			licenseURI: new CASEFieldDef({type:'object', constructor_class:'LinkURI'}),
			notes: new CASEFieldDef({}),
		},
		
		// fields that are in CASE 1.1 but not 1.0 (in addition to `extensions`, which we deal with separately)
		case_1_1_fields: {
			frameworkType: new CASEFieldDef({}),
			caseVersion: new CASEFieldDef({}),
		},

		// satchel fields that may appear in extensions; see U.flatten_extensions
		satchel_extension_fields: {
			satchelSettings: new CASEFieldDef({type:'object'}),
			sourceFrameworkIdentifier: new CASEFieldDef({}),
			sourceFrameworkURI: new CASEFieldDef({}),
			crosswalkSourceFrameworkIdentifiers: new CASEFieldDef({type:'array'}),
		},

		// legacy satchel fields we may need to convert to extensions
		legacy_satchel_specific_fields: ['sourceFrameworkIdentifier', 'sourceFrameworkURI']
	}

	constructor(data) {
		super(data, CFDocument.fields, CASE_Custom_Extension_Fields.CFDocument)
	}
	to_json() { return this.to_json_base(CFDocument.fields, CASE_Custom_Extension_Fields.CFDocument) }
	to_json_for_update() { return this.to_json_base(CFDocument.fields, CASE_Custom_Extension_Fields.CFDocument, 'for_update') }

	generate_uri(framework_domain) {
		// old version:
		// this.uri = framework_domain + '/uri/' + cfd.identifier

		// as of 9/2022, we use the v1p0 CASE REST API for uris
		// https://satchel.commongoodlt.com/ims/case/v1p0/CFDocuments/7841079e-99b5-482f-aadb-cc04dba941c5
		this.uri = `${framework_domain}/ims/case/v1p0/CFDocuments/${this.identifier}`
	}

	is_valid() {
		if (empty(this.identifier)) return false
		if (empty(this.uri)) return false
		if (empty(this.creator)) return false
		if (empty(this.title)) return false
		if (empty(this.lastChangeDateTime)) return false
		return true
	}

	static adoption_statuses = [
		// old IMS CASE best practices says the values should be "Draft", "Adopted", or "Deprecated"
		// new best practice include the following...
		{ value:"", text:"—" },
		{ value:"Draft", text:"Draft" },
		{ value:"Pending Implementation", text:"Pending Implementation" },
		{ value:"Adopted", text:"Implemented" },
		{ value:"Deprecated", text:"Retired" },
	]

	// use the array above to determine a display value for adoptionStatus
	static adoption_status_display_value(adoptionStatus) {
		if (empty(adoptionStatus)) return ''

		adoptionStatus = adoptionStatus.replace(/^Private /, '')

		let s = adoptionStatus.toLowerCase()
		let o = this.adoption_statuses.find(x=>x.value.toLowerCase()==s)
		if (o) return o.text
		return adoptionStatus
	}

	static adoption_status_css_class(cfdocument) {
		let s = ''

		// class for adoptionStatus itself
		s += ' k-case-tree-item-adoption-status-' + cfdocument.adoptionStatus?.replace(/\W/g,'_').toLowerCase()

		// extra class if creator and publisher don't match
		if (!empty(cfdocument.publisher) && cfdocument.creator != cfdocument.publisher) {
			s += ' k-case-tree-item-adoption-status-unofficial'
		}

		return s
	}
}
window.CFDocument = CFDocument

////////////////////////////////////////////////////////////////////////////
class CFItem extends CASEEntityClass {
	static fields = {
		// fields that are official CASE 1.0
		case_1_0_fields: {
			identifier: new CASEFieldDef({required: true}),
			uri: new CASEFieldDef({required: true}),
			fullStatement: new CASEFieldDef({required: true}),

			humanCodingScheme: new CASEFieldDef({}),
			abbreviatedStatement: new CASEFieldDef({}),
			notes: new CASEFieldDef({}),

			CFItemType: new CASEFieldDef({}),
			CFItemTypeURI: new CASEFieldDef({type:'object', constructor_class:'LinkURI'}),
			educationLevel: new CASEFieldDef({type:'array', constructor_fn:'StringArray'}),
			language: new CASEFieldDef({}),

			alternativeLabel: new CASEFieldDef({}),
			listEnumeration: new CASEFieldDef({}),
			conceptKeywords: new CASEFieldDef({type:'array', constructor_fn:'StringArray'}),
			conceptKeywordsURI: new CASEFieldDef({type:'object', constructor_class:'LinkURI'}),	// seems like this should be an array, but it's not
			licenseURI: new CASEFieldDef({type:'object', constructor_class:'LinkURI'}),
			statusStartDate: new CASEFieldDef({}),
			statusEndDate: new CASEFieldDef({}),

			lastChangeDateTime: new CASEFieldDef({required: true}),
		},
		
		// fields that are in CASE 1.1 but not 1.0 (in addition to `extensions`, which we deal with separately)
		case_1_1_fields: {
			subject: new CASEFieldDef({type:'array', constructor_fn:'StringArray'}),
			subjectURI: new CASEFieldDef({type:'array', constructor_fn:'LinkURIArray'}),
		},

		// satchel fields that may appear in extensions; see U.flatten_extensions
		satchel_extension_fields: {
			supplementalNotes: new CASEFieldDef({}),
			sourceItemIdentifier: new CASEFieldDef({}),
			sourceItemURI: new CASEFieldDef({}),
			isSupplementalItem: new CASEFieldDef({type:'boolean', default:false}),
		},

		// legacy satchel fields we may need to convert to extensions
		legacy_satchel_specific_fields: ['supplementalNotes', 'sourceItemIdentifier', 'sourceItemURI', 'isSupplementalItem']
	}

	constructor(data) {
		super(data, CFItem.fields, CASE_Custom_Extension_Fields.CFItem)
	}
	to_json() { return this.to_json_base(CFItem.fields, CASE_Custom_Extension_Fields.CFItem) }
	to_json_for_update() { return this.to_json_base(CFItem.fields, CASE_Custom_Extension_Fields.CFItem, 'for_update') }
	generate_uri(document) { this.generate_uri_base(document, 'CFItems') }

	is_valid() {
		if (empty(this.identifier)) return false
		if (empty(this.uri)) return false
		if (empty(this.fullStatement)) return false
		if (empty(this.lastChangeDateTime)) return false
		return true
	}

}
window.CFItem = CFItem

////////////////////////////////////////////////////////////////////////////
class CFAssociation extends CASEEntityClass {
	static fields = {
		// fields that are official CASE 1.0
		case_1_0_fields: {
			identifier: new CASEFieldDef({required: true}),
			uri: new CASEFieldDef({required: true}),

			associationType: new CASEFieldDef({required: true, constructor_fn:'associationTypeConstructor'}),
			originNodeURI: new CASEFieldDef({required: true, type:'object', constructor_class:'LinkURI'}),
			destinationNodeURI: new CASEFieldDef({required: true, type:'object', constructor_class:'LinkURI'}),
	
			sequenceNumber: new CASEFieldDef({type:'number', default:0}),
			CFAssociationGroupingURI: new CASEFieldDef({type:'object', constructor_class:'LinkURI'}),
	
			lastChangeDateTime: new CASEFieldDef({required: true}),
		},
		
		// fields that are in CASE 1.1 but not 1.0 (in addition to `extensions`, which we deal with separately)
		case_1_1_fields: {
			notes: new CASEFieldDef({}),
		},

		// satchel fields that may appear in extensions; see U.flatten_extensions
		satchel_extension_fields: {
			// OLD; no longer used
			// ratedMatchLevel: new CASEFieldDef({type:'number'}),
		},
	}

	// in the crosswalk tool, when something is marked as "no match", we used to give it an exemplar assocition to this guid; now we translate this in associationTypeConstructor
	static nomatch_guid = '00000000-0000-0000-0000-000000000000'
	static is_nomatch_assoc(assoc) { return (assoc.associationType == 'exemplar' || assoc.associationType == 'ext:hasNoMatch') && assoc.destinationNodeURI.identifier == this.nomatch_guid }

	constructor(data) {
		super(data, CFAssociation.fields, CASE_Custom_Extension_Fields.CFAssociation)
	}
	to_json() { return this.to_json_base(CFAssociation.fields, CASE_Custom_Extension_Fields.CFAssociation) }
	to_json_for_update() { return this.to_json_base(CFAssociation.fields, CASE_Custom_Extension_Fields.CFAssociation, 'for_update') }
	generate_uri(document) { this.generate_uri_base(document, 'CFAssociations') }

	is_valid() {
		if (empty(this.identifier)) return false
		if (empty(this.uri)) return false
		if (empty(this.associationType)) return false
		if (!this.originNodeURI.is_complete()) return false
		if (!this.destinationNodeURI.is_complete()) return false
		if (empty(this.lastChangeDateTime)) return false
		return true
	}
}
window.CFAssociation = CFAssociation

window.associationTypeConstructor = function(val, data) {
	// associationType is a required field; we will use 'isRelatedTo' as the default value
	// in CASE 1.0 it was limited to a certain defined vocabulary
	// for CASE 1.1 the defined vocabulary was explicitly increased by adding 'isTranslationOf', 
	//     and also now allows for 'ext:xxx', where 'xxx' can be any value
	// note that when exporting to CASE 1.0, any value not in the original set of legal values should be replaced with 'isRelatedTo' (?)

	if (empty(val)) return 'isRelatedTo'

	if (val == 'isRelatedTo') {
		// prior to the introduction of the ext: construct, CGLT's crosswalk tool was coding the following association types using isRelatedTo combined with a 'ratedMatchLevel' extension; translate here
		let ratedMatchLevel = data.extensions?.ratedMatchLevel
		if (ratedMatchLevel) {
			// console.warn('translating for ' + ratedMatchLevel)
			// we also switched the ratedMatchLevel coding scheme at some point; hence the confusing translations here
			if (ratedMatchLevel == 11) return 'ext:isNearExactMatch'
			if (ratedMatchLevel == 8 || ratedMatchLevel == 12) return 'ext:isCloselyRelatedTo'
			if (ratedMatchLevel == 5 || ratedMatchLevel == 9) return 'ext:isModeratelyRelatedTo'
		}
	}
	if (val == 'exemplar') {
		console.warn('translating nomatch')
		// we also used 'exemplar' along with this assoc.destinationNodeURI.identifier designate 'nomatches' (associations explicitly saying that there is no association!)
		if (data.destinationNodeURI.identifier == '00000000-0000-0000-0000-000000000000') return 'ext:hasNoMatch'
	}

	// legal defined-vocabulary items
	if (['isChildOf', 'exactMatchOf', 'isRelatedTo', 'replacedBy', 'isPeerOf', 'isPartOf', 'precedes', 'exemplar', 'hasSkillLevel', 'isTranslationOf'].includes(val)) {
		return val
	}

	// legal ext: value
	if (val.indexOf('ext:') == 0) return val

	// if we get something else, put 'ext:' in front
	return 'ext:' + val
}


////////////////////////////////////////////////////////////////////////////
class CFItemType extends CASEEntityClass {
	static fields = {
		// fields that are official CASE 1.0
		case_1_0_fields: {
			identifier: new CASEFieldDef({required: true}),
			uri: new CASEFieldDef({required: true}),
			title: new CASEFieldDef({required: true}),
			description: new CASEFieldDef({required: true}),
			typeCode: new CASEFieldDef({}),
			hierarchyCode: new CASEFieldDef({required: true}),
			lastChangeDateTime: new CASEFieldDef({required: true}),
		},
		
		// fields that are in CASE 1.1 but not 1.0 (in addition to `extensions`, which we deal with separately)
		case_1_1_fields: {
			notes: new CASEFieldDef({}),	// ???
		},
	}

	constructor(data) {
		super(data, CFItemType.fields, CASE_Custom_Extension_Fields.CFItemType)

		// if only a title is specified, make description and typeCode match
		if (!empty(this.title)) {
			if (empty(this.description)) this.description = this.title
			if (empty(this.typeCode)) this.typeCode = this.title
		}
		// if hierarchyCode not provided, just use '1'
		if (empty(this.hierarchyCode)) this.hierarchyCode = '1'
	}
	to_json() { return this.to_json_base(CFItemType.fields, CASE_Custom_Extension_Fields.CFItemType) }
	to_json_for_update() { return this.to_json_base(CFItemType.fields, CASE_Custom_Extension_Fields.CFItemType, 'for_update') }
	generate_uri(document) { this.generate_uri_base(document, 'CFItemTypes') }

	is_valid() {
		if (empty(this.identifier)) return false
		if (empty(this.uri)) return false
		if (empty(this.title)) return false
		if (empty(this.description)) return false
		if (empty(this.hierarchyCode)) return false
		if (empty(this.lastChangeDateTime)) return false
		return true
	}
}
window.CFItemType = CFItemType

////////////////////////////////////////////////////////////////////////////
// custom extensions for a particular satchel instance; this will come in from the server config via the app initialize service
window.CASE_Custom_Extension_Fields = {
	// CFDocument: {
	// 	foo: new CASEFieldDef({type:'object'}),		
	// },
	// CFItem: {
	// 	foo: new CASEFieldDef({type:'object'}),		
	// },
	// CFAssociation: {
	// 	foo: new CASEFieldDef({type:'object'}),		
	// },
	// CFLicense: {
	// 	foo: new CASEFieldDef({type:'object'}),		
	// },
	// CFItemType: {
	// 	foo: new CASEFieldDef({type:'object'}),		
	// },
	// CFRubric: {
	// 	foo: new CASEFieldDef({type:'object'}),		
	// },
	// CFRubricCriterion: {
	// 	foo: new CASEFieldDef({type:'object'}),		
	// },
	// CFRubricCriterionLevel: {
	// 	foo: new CASEFieldDef({type:'object'}),		
	// },
}
window.create_CASE_Custom_Extension_Fields = function(cfg_data) {
	for (let entity in cfg_data) {
		window.CASE_Custom_Extension_Fields[entity] = {}
		for (let key in cfg_data[entity]) {
			window.CASE_Custom_Extension_Fields[entity][key] = new CASEFieldDef(cfg_data[entity][key])
		}
	}
}

////////////////////////////////////////////////////////////////////////////
class CFLicense extends CASEEntityClass {
	static fields = {
		// fields that are official CASE 1.0
		case_1_0_fields: {
			identifier: new CASEFieldDef({required: true}),
			uri: new CASEFieldDef({required: true}),
			title: new CASEFieldDef({required: true}),
			licenseText: new CASEFieldDef({required: true}),
			description: new CASEFieldDef({}),
			lastChangeDateTime: new CASEFieldDef({required: true}),
		},
		
		// fields that are in CASE 1.1 but not 1.0 (in addition to `extensions`, which we deal with separately)
		case_1_1_fields: {
			notes: new CASEFieldDef({}),	// ???
		},
	}

	constructor(data) {
		super(data, CFLicense.fields, CASE_Custom_Extension_Fields.CFLicence)
	}
	to_json() { return this.to_json_base(CFLicense.fields, CASE_Custom_Extension_Fields.CFLicence) }
	to_json_for_update() { return this.to_json_base(CFLicense.fields, CASE_Custom_Extension_Fields.CFLicence, 'for_update') }
	generate_uri(document) { this.generate_uri_base(document, 'CFLicenses') }

	is_valid() {
		if (empty(this.identifier)) return false
		if (empty(this.uri)) return false
		if (empty(this.title)) return false
		if (empty(this.licenseText)) return false
		if (empty(this.lastChangeDateTime)) return false
		return true
	}

	// {
    //   "uri": "https://api.standards.isbe.net/server/api/v1/ISBOE/ims/case/v1p0/CFLicenses/9b516ff5-1cd2-41aa-8618-f20eee60b06d",
    //   "identifier": "9b516ff5-1cd2-41aa-8618-f20eee60b06d",
    //   "lastChangeDateTime": "2019-02-06T15:48:37+00:00",
    //   "title": "IMS Global License",
    //   "description": "IMS Global License",
    //   "licenseText": "\u00a9 2018-2019 IMS Global Learning Consortium Inc. All Rights Reserved."
    // }

	// {
	//   "uri": "https://case.georgiastandards.org/uri/ce259010-07d7-11ea-a815-0242ac150004",
	//   "identifier": "ce259010-07d7-11ea-a815-0242ac150004",
	//   "lastChangeDateTime": "2019-11-15T18:43:24+00:00",
	//   "title": "Attribution 4.0 International",
	//   "licenseText": "https://creativecommons.org/licenses/by/4.0/legalcode"
	// }
}
window.CFLicense = CFLicense

////////////////////////////////////////////////////////////////////////////
class SSFrameworkData {
	constructor(data, document_identifier, cfdoc) {
		if (empty(data)) data = {}

		sdp(this, data, 'color', '0')
		sdp(this, data, 'image', '0')
		sdp(this, data, 'category', '')
		sdp(this, data, 'exemplar_label', '')
		sdp(this, data, 'exemplar_framework_identifier', '')	// this can be taken out once the dust has settled from translating from exemplar frameworks to supplementalNotes
		sdp(this, data, 'shortcuts', [])
		sdp(this, data, 'render_latex', false)
		sdp(this, data, 'hide_associations', false)
		sdp(this, data, 'show_progression_tables', false)
		sdp(this, data, 'ignore_status_dates', false)
		sdp(this, data, 'last_access_date_webapp', '1970-01-01 00:00:01')
		sdp(this, data, 'access_count_webapp', 0)
		sdp(this, data, 'last_access_date_api', '1970-01-01 00:00:01')
		sdp(this, data, 'access_count_api', 0)
		sdp(this, data, 'user_rights', {}) // per framework rights
		sdp(this, data, 'sandboxOfIdentifier', '')
		sdp(this, data, 'sandboxSyncDateTime', '')
		sdp(this, data, 'derivativeSyncDateTime', '')
		sdp(this, data, 'item_count', 0)
		sdp(this, data, 'assoc_count_not_child', 0)
		sdp(this, data, 'computed_lastChangeDateTime', '')	// the latest lastChangeDateTime from document, items, or assocs, because some publishers don't consistently update the document lastChangeDateTime
		// mirror data
		sdp(this, data, 'is_mirror', 'no')
		sdp(this, data, 'mirror_source_rest_api_url', '')
		sdp(this, data, 'last_mirror_sync_date', '')
		sdp(this, data, 'last_mirror_sync_status', '')
		sdp(this, data, 'mirror_auto_updates', '')

		sdp(this, data, 'public_review_on', false)
		sdp(this, data, 'public_review_description', '')

		/**
		 * 	is_private is a boolean that indicates whether the framework is private or not. Originally,
		 *  the visibility status of a framework was determined by the adoptionStatus field which has 8 options,
		 *  4 for public, and 4 more that are the private variants. Going forward, the visibility status of a
		 *  framework is determined by the is_private field and the adoptionStatus field will consist of only
		 *  the 4 base options.
		 */
		let is_private = false
		const current_adoption_status = cfdoc?.adoptionStatus
		if (current_adoption_status) {
			/**
			 *  If the adoptionStatus contains the word "private", then the framework is private. Otherwise, it is public.
		 	 *	This will help frameworks that exist before the is_private visibility status was implemented
			 */
			is_private = current_adoption_status.toLowerCase().includes('private')
		}
		sdp(this, data, 'is_private', is_private)
		// implement this later?
		// optional public_review_params object; if we have it, make sure we have all these fields
		// if (!empty(data.public_review_params)) {
		// 	this.public_review_params = {}
		// 	sdp(this.public_review_params, data.public_review_params, 'emails_to_receive_updates', [])
		// 	sdp(this.public_review_params, data.public_review_params, 'email_for_public_questions', '')
		// 	let's not do dates here; you can include dates in your description if needed
		// 	sdp(this.public_review_params, data.public_review_params, 'start_date', '')
		// 	sdp(this.public_review_params, data.public_review_params, 'end_date', '')
		// 	sdp(this.public_review_params, data.public_review_params, 'description', '')
		// 	sdp(this.public_review_params, data.public_review_params, 'require_name_for_comments', false)
		// 	sdp(this.public_review_params, data.public_review_params, 'require_email_for_comments', false)
		// }
	}

	to_json_for_update() {
		// for empty optional params, set to '*CLEAR*' so that the service fn will make sure to delete the params from the item
		let o = {}
		o.color = this.color
		o.render_latex = this.render_latex
		o.hide_associations = this.hide_associations
		o.show_progression_tables = this.show_progression_tables
		o.ignore_status_dates = this.ignore_status_dates
		o.public_review_on = this.public_review_on
		o.public_review_description = this.public_review_description
		o.is_private = this.is_private
		if (!empty(this.image)) o.image = this.image; else o.image = '*CLEAR*';
		if (!empty(this.category)) o.category = this.category; else o.category = '*CLEAR*';
		if (!empty(this.exemplar_label)) o.exemplar_label = this.exemplar_label; else o.exemplar_label = '*CLEAR*';
		if (!empty(this.exemplar_framework_identifier)) o.exemplar_framework_identifier = this.exemplar_framework_identifier; else o.exemplar_framework_identifier = '*CLEAR*';
		if (!empty(this.shortcuts) && this.shortcuts.length > 0) o.shortcuts = this.shortcuts; else o.shortcuts = '*CLEAR*';
		if (!empty(this.mirror_source_rest_api_url)) o.mirror_source_rest_api_url = this.mirror_source_rest_api_url; else o.mirror_source_rest_api_url = '*CLEAR*';
		if (!empty(this.last_mirror_sync_date)) o.last_mirror_sync_date = this.last_mirror_sync_date; else o.last_mirror_sync_date = '*CLEAR*';
		if (!empty(this.last_mirror_sync_status)) o.last_mirror_sync_status = this.last_mirror_sync_status; else o.last_mirror_sync_status = '*CLEAR*';
		if (!empty(this.mirror_auto_updates)) o.mirror_auto_updates = this.mirror_auto_updates; else o.mirror_auto_updates = '*CLEAR*';
		if (!empty(this.sandboxOfIdentifier)) o.sandboxOfIdentifier = this.sandboxOfIdentifier; else o.sandboxOfIdentifier = '*CLEAR*';
		if (!empty(this.sandboxSyncDateTime)) o.sandboxSyncDateTime = this.sandboxSyncDateTime; else o.sandboxSyncDateTime = '*CLEAR*';
		if (!empty(this.derivativeSyncDateTime)) o.derivativeSyncDateTime = this.derivativeSyncDateTime; else o.derivativeSyncDateTime = '*CLEAR*';
		if (!empty(this.public_review_params)) o.public_review_params = this.public_review_params; else o.public_review_params = '*CLEAR*';
		return o
	}
}
window.SSFrameworkData = SSFrameworkData

////////////////////////////////////////////////////////////////////////////
// https://www.imsglobal.org/sites/default/files/CASE/casev1p0/information_model/caseservicev1p0_infomodelv1p0.html#Main3p1
class CFRubric extends CASEEntityClass {
	static fields = {
		// fields that are official CASE 1.0
		case_1_0_fields: {
			identifier: new CASEFieldDef({required: true}),
			uri: new CASEFieldDef({required: true}),
			title: new CASEFieldDef({}),
			description: new CASEFieldDef({}),
			lastChangeDateTime: new CASEFieldDef({required: true}),
		},
		
		// fields that are in CASE 1.1 but not 1.0 (in addition to `extensions`, which we deal with separately)
		case_1_1_fields: {
			notes: new CASEFieldDef({}), 	// ???
		},
	}

	constructor(data) {
		super(data, CFRubric.fields, CASE_Custom_Extension_Fields.CFRubric)

		// Note that unlike most other structures, the CFRubric structure has unique sub-structure entities (and sub-sub-structure entities) we have to deal with
		this.CFRubricCriteria = []
		if (data.CFRubricCriteria) for (let rc of data.CFRubricCriteria) {
			this.CFRubricCriteria.push(new CFRubricCriterion(rc))
		}
	}
	to_json() {  
		let o = this.to_json_base(CFRubric.fields, CASE_Custom_Extension_Fields.CFRubric) 
		o.CFRubricCriteria = []
		for (let rc of this.CFRubricCriteria) {
			o.CFRubricCriteria.push(rc.to_json_for_update())
		}
		return o
	}
	to_json_for_update() { 
		let o = this.to_json_base(CFRubric.fields, CASE_Custom_Extension_Fields.CFRubric, 'for_update') 
		o.CFRubricCriteria = []
		for (let rc of this.CFRubricCriteria) {
			o.CFRubricCriteria.push(rc.to_json_for_update())
		}
		return o
	}
	generate_uri(document) { this.generate_uri_base(document, 'CFRubrics') }

	is_valid() {
		// todo: fill this in
		return true
	}
}
window.CFRubric = CFRubric

////////////////////////////////////////////////////////////////////////////
class CFRubricCriterion extends CASEEntityClass {
	static fields = {
		// fields that are official CASE 1.0
		case_1_0_fields: {
			identifier: new CASEFieldDef({required: true}),
			uri: new CASEFieldDef({required: true}),
			description: new CASEFieldDef({}),
			weight: new CASEFieldDef({type:'number', default:0.0}),
			position: new CASEFieldDef({type:'number', default:-1}),
			rubricId: new CASEFieldDef({}),	// this should be set to the identifier of the parent CFRubric object
			lastChangeDateTime: new CASEFieldDef({required: true}),
		},
		
		// fields that are in CASE 1.1 but not 1.0 (in addition to `extensions`, which we deal with separately)
		case_1_1_fields: {
			notes: new CASEFieldDef({}), 	// ???
		},
	}

	constructor(data) {
		super(data, CFRubricCriterion.fields, CASE_Custom_Extension_Fields.CFRubricCriterion)

		this.CFRubricCriterionLevels = []
		if (data && data.CFRubricCriterionLevels) for (let rcl of data.CFRubricCriterionLevels) {
			this.CFRubricCriterionLevels.push(new CFRubricCriterionLevel(rcl))
		}
	}
	to_json() { 
		let o = this.to_json_base(CFRubricCriterion.fields, CASE_Custom_Extension_Fields.CFRubricCriterion) 
		o.CFRubricCriterionLevels = []
		for (let rcl of this.CFRubricCriterionLevels) {
			o.CFRubricCriterionLevels.push(rcl.to_json_for_update())
		}
		return o
	}
	to_json_for_update() { 
		// note that we currently (12/5/2024) *don't* use the 'for_update' flag here, or in CFRubricCriterionLevel, because the criteria array, an each criterion's levels array, are saved "as is" in save_framework_data.
		// therefore, to_json_for_update is the same as to_json for CFRubricCriterion
		return this.to_json()
	}
	generate_uri(document) { this.generate_uri_base(document, 'CFRubricCriterions') }

	is_valid() {
		// todo: fill this in
		return true
	}
}
window.CFRubricCriterion = CFRubricCriterion

////////////////////////////////////////////////////////////////////////////
class CFRubricCriterionLevel extends CASEEntityClass {
	static fields = {
		// fields that are official CASE 1.0
		case_1_0_fields: {
			identifier: new CASEFieldDef({required: true}),
			uri: new CASEFieldDef({required: true}),
			description: new CASEFieldDef({}),
			quality: new CASEFieldDef({}),
			score: new CASEFieldDef({type:'number', default:0.0}),
			feedback: new CASEFieldDef({}),
			position: new CASEFieldDef({type:'number', default:-1}),
			rubricCriterionId: new CASEFieldDef({}),	// this should be set to the identifier of the parent CFRubricCriterionLevelCriterion object
			lastChangeDateTime: new CASEFieldDef({required: true}),
		},
		
		// fields that are in CASE 1.1 but not 1.0 (in addition to `extensions`, which we deal with separately)
		case_1_1_fields: {
			notes: new CASEFieldDef({}), 	// ???
		},
	}

	constructor(data) {
		super(data, CFRubricCriterionLevel.fields, CASE_Custom_Extension_Fields.CFRubricCriterionLevel)
	}
	to_json() { return this.to_json_base(CFRubricCriterionLevel.fields, CASE_Custom_Extension_Fields.CFRubricCriterionLevel) }
	to_json_for_update() { return this.to_json() }
	generate_uri(document) { this.generate_uri_base(document, 'CFRubricCriterionLevels') }

	is_valid() {
		// todo: fill this in
		return true
	}
}
window.CFRubricCriterionLevel = CFRubricCriterionLevel


