// Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams
import { mapState, mapGetters } from 'vuex'

export default {
	data() { return {
		search_term_res: [],

		search_panel_showing: false,
		search_panel_behind: false,

		// hash of all nodes (tree_keys) that match the basic search criteria
		basic_search_tree_key_hash: {},

		// list of tree_keys for advanced search, if we're limiting to open branches
		tree_keys_for_advanced_search: [],

		// entries to show in the search results display; there will be one entry for each fullStatement, and this entry may include multiple nodes (tree_keys)
		// NOTE: in advanced search, search_result_entries will include all items that meet the filtering criteria;
		// in a non-advanced search, search_result_entries will only include items that meet the filtering criteria AND include the search terms
		search_result_entries: [],

		// ordered list of all entries currently showing in the results panel viewing; these are paged
		active_search_result_entries: [],

		// ordered list of all nodes currently opened for viewing; each item is an object with a tree_key and an entry
		active_search_tree_keys: [],

		search_result_highlighted_tree_key: -1,

		search_results_page_length: 10,
		search_results_page_index: 0,
		search_results_panel_showing: false,

		advanced_search_showing: false,		// if this is true, we've done an advanced search
		current_search_params: '',			// used to determine if we should re-run a search
		current_search_terms: '',			// used to determine whether or not to clear search params when you type in the search box

		// advanced_search_featured_item_types: ['content standard', 'standard', 'element'],	// TODO: this could be configurable...
		// advanced_search_featured_item_types_weight: 0.2,	// sim_score weight given to items that are one of the advanced_search_featured_item_types
		advanced_search_featured_item_types_weight: 0,	// sim_score weight given to items that are one of the advanced_search_featured_item_types
		advanced_search_normal_search_weight: 0.2,
		advanced_search_lcs_weight: 0.1,		// weighting for lcs percentage

		all_item_types_option: 'ALL ITEM TYPES',
		search_field_type_options: [
			{value:'humanCodingScheme', text:'Code'},
			{value:'fullStatement', text:'Statement'},
			{value:'notes', text:'Notes'},
			// {value:'notes', text:`Notes (including ${U.field_display_string('supplementalNotes', this.framework_record)})`},
			// we automatically check the identifier, so probably no reason to include this
			// {value:'identifier', text:'Identifier'},
		],

		execute_search_start_timestamp: 0,
		search_bar_blur_timeout: null,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		search_panel_css_class() {
			if (this.search_panel_behind) {
				return 'k-case-tree-search-panel-behind elevation-1'
			} else {
				return 'elevation-5'
			}
		},
		advanced_search_featured_item_types() {
			// in the search we want to "feature" item types that are closer to leafs. So get an "average distance from bottom" value for each type
			let o = {}
			for (let index in this.framework_record.cfo.tree_nodes_hash) {
				let tn = this.framework_record.cfo.tree_nodes_hash[index]
				let item_type = U.item_type_string(tn.cfitem).toLowerCase()
				if (item_type) {
					if (!o[item_type]) o[item_type] = {item_type: item_type, n:0, sum:0}
					let d = 0
					while (tn && tn.children.length > 0) {
						++d
						let next_tn
						for (let child of tn.children) {
							// look down through the first child that has children (if any children have children)
							if (child.children.length > 0) {
								next_tn = child
								break
							}
						}
						tn = next_tn
					}
					o[item_type].n += 1
					o[item_type].sum += d
				}
			}
			
			// get the lowest and highest average value
			let hi_avg = 0
			let lo_avg = 10000
			for (let item_type in o) {
				o[item_type].avg = (o[item_type].sum / o[item_type].n)

				if (o[item_type].avg > hi_avg) hi_avg = o[item_type].avg
				if (o[item_type].avg < lo_avg) lo_avg = o[item_type].avg
			}

			// scale values so that the smallest avg (closest to bottom) is 1 and the highest avg is 0
			let range = hi_avg - lo_avg
			for (let item_type in o) {
				let val = hi_avg - o[item_type].avg - lo_avg
				o[item_type].factor = Math.pow(val/range, 0.5).toFixed(1)
			}

			let arr = []; for (let item_type in o) arr.push(o[item_type]);  arr.sort((a,b) => a.factor - b.factor); console.log('advanced_search_featured_item_types', arr);

			return o
		},
		search_terms: {
			get() { return this.framework_record.search_terms },
			set(val) { this.$store.commit('set', [this.framework_record, 'search_terms', val])}
		},
		max_search_results_page_index() {
			return Math.floor(this.search_result_entries.length / this.search_results_page_length)
		},
		search_grade_low: {
			get() {
				let s = this.$store.state.lst.search_grade_low
				if (s) {
					let o = JSON.parse(s)
					return (o[this.lsdoc_identifier]) ? o[this.lsdoc_identifier] : 0
				} else return 0
			},
			set(val) {
				// val comes in as the index of the grades array
				// if is low is 0, set high to 0
				if (val == 0 && this.search_grade_high != 0) this.$nextTick(x=>this.search_grade_high = 0)
				// if low is > high, set high to low
				else if (val > this.search_grade_high) this.$nextTick(x=>this.search_grade_high = val)

				let s = this.$store.state.lst.search_grade_low
				let o = (s) ? JSON.parse(s) : {}
				o[this.lsdoc_identifier] = val
				this.$store.commit('lst_set', ['search_grade_low', JSON.stringify(o)])

				// when this is set, clear params so that results disappear
				this.clear_search_params()
			},
		},
		search_grade_high: {
			get() {
				let s = this.$store.state.lst.search_grade_high
				if (s) {
					let o = JSON.parse(s)
					return (o[this.lsdoc_identifier]) ? o[this.lsdoc_identifier] : 0
				} else return 0
			},
			set(val) {
				// val comes in as the index of the grades array
				// if is high is 0, set low to 0
				if (val == 0 && this.search_grade_low != 0) this.$nextTick(x=>this.search_grade_low = 0)
				// if high is < low or low == 0, set low to high
				else if (val < this.search_grade_low || this.search_grade_low == 0) this.$nextTick(x=>this.search_grade_low = val)

				let s = this.$store.state.lst.search_grade_high
				let o = (s) ? JSON.parse(s) : {}
				o[this.lsdoc_identifier] = val
				this.$store.commit('lst_set', ['search_grade_high', JSON.stringify(o)])

				// when this is set, clear params so that results disappear
				this.clear_search_params()
			},
		},
		search_item_type_options() {
			// start with all_item_types_option
			let arr = [this.all_item_types_option].concat(this.framework_record.cfo.item_types)

			// sort alphabetically, with the exception that we keep standard/content standard and element at the top
			arr.sort((a,b)=>{
				if (a == this.all_item_types_option) return -1
				else if (b == this.all_item_types_option) return 1
				else if (a.toLowerCase() == 'standard' || a.toLowerCase() == 'content standard') return -1
				else if (b.toLowerCase() == 'standard' || b.toLowerCase() == 'content standard') return 1
				else if (a.toLowerCase() == 'element') return -1
				else if (b.toLowerCase() == 'element') return 1
				else if (a < b) return -1
				else if (b < a) return 1
				else return 0
			})

			return arr
		},
		search_item_types: {
			get() {
				let s = this.$store.state.lst.search_item_types
				if (s) {
					let o = JSON.parse(s)
					return (o[this.lsdoc_identifier]) ? o[this.lsdoc_identifier] : [this.all_item_types_option]
				} else {
					return [this.all_item_types_option]
				}
			},
			set(val) {
				// if val is an empty array, the user unchecked the all_item_types_option, which is illegal, so set it back to the all_item_types_option
				if (!val || val.length == 0) {
					this.$nextTick(x=>this.search_item_types = [this.all_item_types_option])

				// if the 'all' option is included in val
				} else if (val.indexOf(this.all_item_types_option) > -1) {
					// then if it was just selected (it wasn't previously in search_item_types), the only value should be 'all'
					if (this.search_item_types.indexOf(this.all_item_types_option) == -1) val = [this.all_item_types_option]
					// else it *shouldn't* be part of val
					else {
						val.splice(val.indexOf(this.all_item_types_option), 1)
					}
				}

				let s = this.$store.state.lst.search_item_types
				let o = (s) ? JSON.parse(s) : {}
				o[this.lsdoc_identifier] = val
				this.$store.commit('lst_set', ['search_item_types', JSON.stringify(o)])

				// when this is set, clear params so that results disappear
				this.clear_search_params()
			},
		},
		search_field_types: {
			get() {
				// in wiki_mode_view, always search fullStatement and notes (which includes supplementalNotes)
				if (this.wiki_mode_view) return ['fullStatement', 'notes']

				let s = this.$store.state.lst.search_field_types
				if (s) {
					let o = JSON.parse(s)
					return (o[this.lsdoc_identifier]) ? o[this.lsdoc_identifier] : ['humanCodingScheme', 'fullStatement', 'notes']
				} else {
					return ['humanCodingScheme', 'fullStatement', 'notes']
				}
			},
			set(val) {
				// don't set in wiki_mode_view
				if (this.wiki_mode_view) return

				// if val is an empty array, the user unchecked the all_item_types_option, which is illegal, so set it to fullStatement
				if (!val || val.length == 0) {
					this.$nextTick(x=>this.search_field_types = ['fullStatement'])
				}

				let s = this.$store.state.lst.search_field_types
				let o = (s) ? JSON.parse(s) : {}
				o[this.lsdoc_identifier] = val
				this.$store.commit('lst_set', ['search_field_types', JSON.stringify(o)])

				// when this is set, clear params so that results disappear
				this.clear_search_params()
			},
		},
		filter_description() {
			// let s = '<b class="grey--text text--darken-2">SEARCH</b> '
			let s = ''
			let arr = []
			if (this.search_field_types.includes('fullStatement')) arr.push('Statement')
			if (this.search_field_types.includes('humanCodingScheme')) arr.push('Code')
			if (this.search_field_types.includes('notes')) arr.push('Notes')
			s += ' ' + arr.join(', ')

			if (this.search_item_types[0] == this.all_item_types_option) {
				// s += ' <b class="grey--text text--darken-2 ml-1">IN</b> all item types '
			} else {
				s += sr(' <b class="grey--text text--darken-2 ml-1">IN $1</b> $2', U.ps('TYPE', this.search_item_types.length, 'TYPES'), this.search_item_types.join(', '))
			}

			if (!this.search_grade_low) {
				// s += ' <b class="grey--text text--darken-2 ml-1">FOR</b> all grades '
			} else {
				if (this.search_grade_low == this.search_grade_high) s += ' <nobr><b class="grey--text text--darken-2 ml-1">FOR GRADE</b> ' + this.grades[this.search_grade_low].value + '</nobr>'
				else s += ' <nobr><b class="grey--text text--darken-2 ml-1">FOR GRADES</b> ' + this.grades[this.search_grade_low].value + '-' + this.grades[this.search_grade_high].value + '</nobr>'
			}

			return s
			// {{search_field_types}} for {{search_item_types}} in {{search_grade_low}}-{{search_grade_high}}
		},
		// search_item_for_limit holds the item (node ID/tree_key -- integer) chosen by the user as a possible filter; stored in lst
		// this value is set by a btn in the tile
		search_item_for_limit: {
			get() {
				let s = this.$store.state.lst.search_item_for_limit
				if (s) {
					let o = JSON.parse(s)
					if (o[this.lsdoc_identifier]) {
						// when this is set, clear params so that results disappear
						this.clear_search_params()
						return o[this.lsdoc_identifier]
					}
				}
				return 0
			},
			set(val) {
				// never set this when we're in wiki_mode_view
				if (this.wiki_mode_view) return

				let s = this.$store.state.lst.search_item_for_limit
				let o = (s) ? JSON.parse(s) : {}
				o[this.lsdoc_identifier] = val
				this.$store.commit('lst_set', ['search_item_for_limit', JSON.stringify(o)])
			},
		},
		// search_limit_to_item holds whether or not the filter is on; also stored in lst
		// this value can be controlled by the checkbox in the framework viewer component; can also be set to on from the tile
		search_limit_to_item: {
			get() {
				// never limit when we're in wiki_mode_view
				if (this.wiki_mode_view) return false
				
				let s = this.$store.state.lst.search_limit_to_item
				if (s) {
					let o = JSON.parse(s)
					return (o[this.lsdoc_identifier]) ? true : false
				} else {
					return false
				}
			},
			set(val) {
				// never set this when we're in wiki_mode_view
				if (this.wiki_mode_view) return

				let s = this.$store.state.lst.search_limit_to_item
				let o = (s) ? JSON.parse(s) : {}
				o[this.lsdoc_identifier] = val
				this.$store.commit('lst_set', ['search_limit_to_item', JSON.stringify(o)])

				// when this is set, clear params so that results disappear
				this.clear_search_params()

				// then cancel the timeout that would hide the filters, and reveal the search bar
				clearTimeout(this.search_bar_blur_timeout)
				setTimeout(x=>this.reveal_search_bar(), 100)
			},
		},
		// hcs + start of item text for the search_item_for_limit, to show in search panel
		search_item_for_limit_descriptor() {
			if (this.search_item_for_limit == 0) return ''
			let node = this.framework_record.cfo.tree_nodes_hash[this.search_item_for_limit]
			return U.generate_cfassociation_node_uri_title(node.cfitem, 50)
		},
	},
	watch: {
		imported_search_terms: { immediate: true, handler() {
			this.search_terms = this.imported_search_terms
			if (!empty(this.imported_search_terms)) {
				// when we receive imported search terms, start a search
				this.execute_search_start()
			} else {
				this.clear_search_params()
			}
		}},
		search_panel_showing() {
			// console.log('search_panel_showing: ' + this.search_panel_showing)
			// I put this in when trying to get the focusing to work, but now I don't think I need it
			// if (this.search_panel_showing) {
			// 	console.log('clearTimeout!')
			// 	this.$nextTick(x=>clearTimeout(this.search_bar_blur_timeout))
			// }
		},
	},
	methods: {
		search_filter_menu_clicked() {
			clearTimeout(this.search_bar_blur_timeout)
		},

		search_filter_menu_area_clicked() {
			this.search_bar_blurred()
		},

		show_search_filters() {
			// hide the results panel (which blocks the filters on a small screen)
			this.search_results_panel_showing = false
			// and fire the click event for the filters btn
			$(vapp.case_tree_component.$refs.search_filter_btn.$el).click() 
		},

		reveal_search_bar() {
			// user taps to reveal the search bar on a phone; show the search bar, and focus in the textarea
			this.title_or_search='search'
			this.$nextTick(x=>{
				$(this.$refs.framework_search_bar?.$el).find('input').focus()
			})

			// make sure the timeout won't run to blur things again
			clearTimeout(this.search_bar_blur_timeout)
		},

		clear_search_terms() {
			// console.log('clear_search_terms!!!')
			// if we're in small-screen mode, show the title when the x is clicked on the search and there weren't any search terms before
			if (!this.search_terms) this.title_or_search = 'title'

			this.search_terms = ''
			this.clear_search_params()
			this.blur_text_input()
			this.search_bar_blurred()
		},

		clear_search_params() {
			this.search_term_res = []

			// for some reason, if we set basic_search_tree_key_hash to {} on every keydown, it slows down input; so check to make sure we have to clear it before clearing it
			if (Object.keys(this.basic_search_tree_key_hash).length > 0) this.basic_search_tree_key_hash = {}
			this.tree_keys_for_advanced_search = []
			this.search_result_entries = []
			this.active_search_result_entries = []
			this.active_search_tree_keys = []

			this.search_result_highlighted_tree_key = -1
			this.search_results_page_index = 0
			this.search_results_panel_showing = false

			this.advanced_search_showing = false
			this.current_search_params = ''
		},

		// when the search bar is focused, show the panel
		search_bar_focused(e) {
			if (this.viewer_mode == 'table') return		// in table mode the search bar controls searches of the table

			// console.log('search_bar_focused', e)
			this.search_panel_showing = true
			this.search_panel_to_front()
			// when you click in the search bar, it seems to first call blur, then focus, then blur again; so we have to clear the timeout here lest search_panel_showing gets immediately reset to false
			// and we have to delay 10ms so that if search terms are filled in, the text box isn't focused, and the user clicks the x btn,
			// focus will actually go to the text box so that the user can then clear the text
			setTimeout(x=>clearTimeout(this.search_bar_blur_timeout), 10)
		},

		// when the search bar is blurred, hide the filters, but allow some time for the hiding to be cancelled if the user clicked on one of the filter controls
		search_bar_blurred() {
			if (this.viewer_mode == 'table') return		// in table mode the search bar controls searches of the table

			// console.log('search_bar_blurred')
			if (empty(this.search_terms)) {
				// this.search_panel_showing = false
				// 100 doesn't seem to work, but 150 does...
				this.search_bar_blur_timeout = setTimeout(x=>this.search_panel_showing = false, 150)
			}
		},

		// Note: execute_search shouldn't be called directly; call execute_search_start
		execute_search(node) {
			if (empty(node)) return false

			// by default return false (item doesn't meet criteria)
			let rv = false

			// in wiki_mode_view, or if case_version_displayed != 'vext' don't search supplemental branches
			if (this.wiki_mode_view || this.case_version_displayed != 'vext') {
				if (node.cfitem.extensions?.isSupplementalItem) return
			}

			// if the node has children, search the children
			if (!empty(node.children) && node.children.length > 0) {
				for (let i = 0; i < node.children.length; ++i) {
					let child = node.children[i]
					if (this.execute_search(child)) {
						rv = true
					}
				}
			}

			// assuming the node has a cfitem (and isn't the document), ...
			if (!empty(node.cfitem) && empty(node.cfitem.title)) {
				let search_it = false

				// check education level if specified as a filter
				if (this.search_grade_low == 0) {
					search_it = true
				} else {
					// if the item doesn't have an educationLevel specified, it can't match
					if (!empty(node.cfitem.educationLevel) && node.cfitem.educationLevel.length > 0) {
						// check to see if one of the listed educationLevels is within search_grade_low and search_grade_high
						for (let el of node.cfitem.educationLevel) {
							el = this.grades.findIndex(x=>x.value == el)
							if (el >= this.search_grade_low && el <= this.search_grade_high) {
								search_it = true
								break
							}
						}
					}
				}

				// if we're still viable, check item_type if specified as a filter
				if (search_it && this.search_item_types[0] != this.all_item_types_option) {
					search_it = this.search_item_types.indexOf(U.item_type_string(node.cfitem)) > -1
				}

				// if search_it is true, determine if it should be highlighted as a search result.
				if (search_it) {
					// regardless of whether or not we find it here, add to tree_keys_for_advanced_search in case we're doing an advanced search
					this.tree_keys_for_advanced_search.push(node.tree_key)

					// look for regular expressions in search_field_types
					let arr = []
					for (let i = 0; i < this.search_field_types.length; ++i) {
						arr.push(node.cfitem[this.search_field_types[i]])
						// make 'notes' include supplementalNotes as well
						if (this.search_field_types[i] == 'notes') arr.push(node.cfitem.extensions?.supplementalNotes)
					}
					// add identifier and sourceItemIdentifier (if there) if it looks like it might actually be an identifier (> 16 chars)
					if (this.search_terms.length > 16) {
						arr.push(node.cfitem.identifier)
						if (node.cfitem.extensions.sourceItemIdentifier) arr.push(node.cfitem.extensions.sourceItemIdentifier)
					}

					if (U.strings_match_search_term_res(this.search_term_res, arr)) {
						this.basic_search_tree_key_hash[node.tree_key] = true
						rv = true
					}
				}
			}

			return rv
		},

		execute_search_start(is_user_initiated_search, advanced_search) {
			if (this.wiki_mode_view) advanced_search = true
			
			if (this.viewer_mode == 'table' && !this.wiki_mode_view) return		// in table mode the search bar controls searches of the table	

			this.execute_search_start_timestamp = Date.now()

			// this is a just in case...
			if (this.$store.state.case_tree_suppress_watcher) return false

			this.blur_text_input()

			// if is_user_initiated_search and search_terms is empty, show an alert
			if (is_user_initiated_search && empty(this.search_terms)) {
				this.empty_search_alert()
				return
			}

			// if framework isn't yet loaded, try again in 100 ms
			if (empty(this.framework_record.json)) {
				if (empty(this.execute_search_start_counter)) this.execute_search_start_counter = 0
				if (this.execute_search_start_counter < 1000) {
					setTimeout(()=>{
						this.execute_search_start(is_user_initiated_search, advanced_search)
						++this.execute_search_start_counter
					}, 100)
				}
				return
			}

			// if search params haven't changed since the last search, just show results
			let search_params = JSON.stringify([advanced_search==true, this.search_terms, this.search_grade_low, this.search_grade_high, this.search_item_types, this.search_item_for_limit, this.search_limit_to_item])

			// console.log(search_params)
			if (search_params == this.current_search_params) {
				this.show_search_results_page()
				return
			}

			// set current_search_params so that we don't rerun the same search again; also save current_search_terms for keydown handler
			this.current_search_params = search_params
			this.current_search_terms = this.search_terms

			// flash loader for user-initiated search?
			// console.warn(is_user_initiated_search)
			if (!empty(this.search_terms) && is_user_initiated_search) {
				if (advanced_search === true) {
					U.loading_start('<i class="fas fa-robot"></i>&nbsp;&nbsp;Searching…')
				} else {
					U.loading_start('Searching…')
					setTimeout(()=>U.loading_stop(), 100)
				}
			}

			// we do this in the timeout to ensure that the loader shows, at least briefly
			setTimeout(x=>{
				this.clear_search_params()

				// this.$store.commit('set', [this.framework_record, 'open_nodes', {}])
				// this.$store.commit('set', [this.framework_record, 'last_clicked_node', ''])

				this.search_term_res = U.create_search_re(this.search_terms)

				// execute_search will populate basic_search_tree_key_hash and tree_keys_for_advanced_search, and return true if something was found
				let terms_found = false
				// if we're not limiting to an item's children, execute_search on the tree
				if (!this.search_limit_to_item) {
					terms_found = this.execute_search(this.cftree)
				} else {
					// else execute_search on the selected item's children
					let node = this.framework_record.cfo.tree_nodes_hash[this.search_item_for_limit]
					terms_found = terms_found || (this.execute_search(node))
				}

				// proceed with advanced_search if parameter sent in and there is something to search -- whether or not terms were found in the basic search
				if (advanced_search === true && this.tree_keys_for_advanced_search.length > 0) {
					this.execute_advanced_search()

				// else if terms were found, populate search_result_entries
				} else if (terms_found) {
					for (let tree_key in this.basic_search_tree_key_hash) {
						// get a handle to this node
						let node = this.framework_record.cfo.tree_nodes_hash[tree_key]

						// see if we already have a search_result_entry for this node's fullStatement/hcs
						let nfs = U.normalize_string_for_sparkl_bot(node.cfitem.fullStatement)
						let sre = this.search_result_entries.find(x=>x.nfs == nfs && x.hcs ==node.cfitem.humanCodingScheme)

						// if not, create a new sre
						if (!sre) {
							let new_sre = {
								nfs: nfs,
								hcs: node.cfitem.humanCodingScheme,
								tree_keys: [],
							}
							new_sre.tree_keys.push(tree_key)

							// add to search_result_entries
							this.search_result_entries.push(new_sre)

						// else add to the existing sre
						} else {
							sre.tree_keys.push(tree_key)
						}
					}

					// then show first page of search results
					this.show_search_results_page(0)

					this.record_search()

				} else {
					// if we didn't find any terms and we have search_terms, show the panel to say that nothing was found
					if (!empty(this.search_terms)) {
						this.search_panel_showing = true
						this.search_results_panel_showing = true
						this.search_panel_to_front()
						this.reveal_search_bar()
						this.record_search()
					}
					// call loading_stop in case we started the loader for advanced search
					U.loading_stop()
				}
			}, 0)
		},

		show_search_results_page(results_page_index) {
			if (results_page_index == 'next') results_page_index = this.search_results_page_index + 1
			if (results_page_index == 'prev' && this.search_results_page_index > 0) results_page_index = this.search_results_page_index - 1

			if (typeof(results_page_index) == 'number') {
				this.search_results_page_index = results_page_index
				// this.$store.commit('set', [this.framework_record, 'open_nodes', {}])
				// this.$store.commit('set', [this.framework_record, 'last_clicked_node', ''])
				this.active_search_result_entries = []
				this.active_search_tree_keys = []

				// get the start/end of the page of search_result_entries we want to show, then for each of these entries...
				let start = this.search_results_page_index * this.search_results_page_length
				let end = (this.search_results_page_index + 1) * this.search_results_page_length
				for (let i = start; i < end; ++i) {
					let sre = this.search_result_entries[i]

					// if we get to the last sre, break
					if (empty(sre)) break

					// add to active_search_result_entries
					this.active_search_result_entries.push(sre)

					// add all tree_keys for this entry to active_search_tree_keys, and make sure they're all opened and highlighted in the tree
					for (let tree_key of sre.tree_keys) {
						this.active_search_tree_keys.push({
							tree_key: tree_key,
							entry: sre,
						})
						// this.make_node_open(tree_key)
						// this.make_node_parents_open(tree_key)
					}
				}
			}

			// show the results panel
			// console.log('show_search_results_page')
			this.search_panel_showing = true
			this.search_results_panel_showing = true
			this.search_panel_to_front()
			// put focus in the search bar, so that when the user clicks out, the results will disappear
			this.reveal_search_bar()
		},

		search_result_panel_html(o) {
			let entry = o.entry
			let tree_key = o.tree_key
			let cfitem = this.framework_record.cfo.tree_nodes_hash[tree_key]?.cfitem
			if (!cfitem) {
				// if this is empty, the user may have altered things in a way that this tree item doesn't exist anymore. in this case, clear the search terms, then fill the search box back in with the original terms
				let temp = this.search_terms
				this.clear_search_terms()
				this.search_terms = temp
				return
			}

			let s = ''

			// if this is the first node for this entry, show the sim_score and number of "sub-results"
			if (entry.tree_keys[0] == tree_key) {
				if (entry.tree_keys.length > 1) {
					s += sr('<div class="k-advanced-search-multiple-items">+$1</div>', entry.tree_keys.length-1)
				}
			} else {
				s += '<div class="k-advanced-search-multiple-items" style="padding-left:16px; font-size:24px; line-height:24px;">•</div>'
			}

			s += '<div class="k-advanced-search-full-statement">'

			// deal with wiki_mode substitutions
			if (cfitem.humanCodingScheme && !this.wiki_mode_view) s += sr('<b>$1</b> ', this.highlight_search_terms(cfitem.humanCodingScheme, tree_key))
			
			let fs = cfitem.fullStatement
			if (this.wiki_mode_view) fs = this.wiki_variable_substitutions(fs)
				
			s += this.highlight_search_terms(fs, tree_key)
			s += '</div>'

			return s
		},

		search_result_panel_tooltip_html(o) {
			let cfitem = this.framework_record.cfo.tree_nodes_hash[o.tree_key]?.cfitem
			if (!cfitem) return ''

			let s = cfitem.fullStatement
			if (cfitem.humanCodingScheme) s = sr('**$1**  $2', cfitem.humanCodingScheme, s)
			return sr('<div class="k-search-full-statement-tooltip k-case-tree-markdown">$1</div>', U.marked_latex(s))
		},

		search_result_panel_copy_val(o) {
			let cfitem = this.framework_record.cfo.tree_nodes_hash[o.tree_key]?.cfitem
			if (!cfitem) return ''

			let s = cfitem.fullStatement
			if (cfitem.humanCodingScheme) s = cfitem.humanCodingScheme + ' ' + s
			return s
		},

		show_item_in_search_panel(o) {
			// we show the item in the search panel if it's the first tree_key for the search result,
			if (o.tree_key == o.entry.tree_keys[0]) return true

			// or if one of its "search siblings" is currently showing
			if (o.entry.tree_keys.find(x=>x == this.search_result_highlighted_tree_key)) return true

			return false
		},

		search_result_clicked(o) {
			// if we have selected_items and are limiting to selected items and the clicked item isn't a selected item, stop limiting
			if (this.selected_items && this.limit_to_selected_items) {
				this.toggle_show_selected_items(false)
			}

			// set search_result_highlighted_tree_key to highlight the item in the tree
			this.search_result_highlighted_tree_key = o.tree_key

			this.$store.commit('set', [this.framework_record, 'open_nodes', {}])
			this.$store.commit('set', [this.framework_record, 'last_clicked_node', ''])
			// don't make items open -- if the items are folders with lots of children this makes things confusing
			// this.make_node_open(o.tree_key)
			this.make_node_parents_open(o.tree_key)

			// do what we would do if the item were opened
			this.$store.commit('set', [this.framework_record, 'active_node', this.search_result_highlighted_tree_key])
			this.$store.commit('set', [this.framework_record, 'last_clicked_node', this.search_result_highlighted_tree_key])
			let node = this.framework_record.cfo.tree_nodes_hash[o.tree_key]
			this.set_starting_lsitem_identifier(node.cfitem.identifier, o.tree_key)

			this.search_panel_to_back()
			// for now at least, always hide search results when an item is clicked
			// this.search_panel_showing = false
		},
		
		search_panel_to_front() {
			// console.log('search_panel_to_front')
			this.search_panel_behind = false
		},

		search_panel_to_back() {
			// console.log('search_panel_to_back')
			this.search_panel_behind = true
		},

		search_field_keydown(evt) {
			if (this.viewer_mode == 'table') return		// in table mode the search bar controls searches of the table

			if (evt.key == 'Enter' || evt.keyCode == 13) {
				// meta/alt key held down: advanced search
				if (U.meta_or_alt_key(evt)) {
					this.advanced_search_clicked()
				} else {
					this.execute_search_start(true)
				}
			} else {
				// if you type in the search field, clear current search params
				setTimeout(x=>{
					// we have to run this fn on keydown to capture meta/control key, so we have to delay to get the updated version of search_terms
					if (this.search_terms != this.current_search_terms) {
						this.clear_search_params()
					}
				},10)
			}
		},

		highlight_search_terms(s, tree_key) {
			// highlight search terms if we have them

			// if tree_key is specified, only apply highlights for standards that match all search terms
			if (tree_key && !this.basic_search_tree_key_hash[tree_key]) return s

			if (!empty(this.search_term_res)) {
				for (let re of this.search_term_res) {
					s = s.replace(re, 'XXXX$1ZZZZ')
				}
			}
			s = s.replace(/XXXX/g, '<span class="k-case-tree-searched-term">')
			s = s.replace(/ZZZZ/g, '</span>')
			return s
		},

		jump_to_search(direction) {
			// direction should be +1 or -1
			// if the search results panel is showing, have to set it to show again, because clicking the up/down btn will close it
			// if (this.search_results_panel_showing) {
			// 	setTimeout(x=>this.search_results_panel_showing = true, 10)
			// }

			let index = this.active_search_tree_keys.findIndex(x=>x.tree_key == this.search_result_highlighted_tree_key)
			if (index == -1) {
				index = 0
			} else {
				index += direction
			}

			// if index is < 0, move to the previous page
			if (index < 0) {
				// but if we're already on the first page, don't move
				if (this.search_results_page_index == 0) return

				this.show_search_results_page('prev')

				// then set the highlighted tree key to the last item in the page
				this.search_result_highlighted_tree_key = this.active_search_tree_keys[this.active_search_tree_keys.length - 1].tree_key

			// if index is > the last node currently showing, go to the next page
			} else if (index >= this.active_search_tree_keys.length) {
				// but if we're already on the last page, don't move
				if (this.search_results_page_index == this.max_search_results_page_index) return

				this.show_search_results_page('next')

				// then set the highlighted tree key to the first item in the page
				this.search_result_highlighted_tree_key = this.active_search_tree_keys[0].tree_key

			// else move to this item in the current page
			} else {
				this.search_result_highlighted_tree_key = this.active_search_tree_keys[index].tree_key
			}

			// 	// if minimized, scroll to the item (if maximized the scrolling will happen in conjunction with showing the tile)
			// 	let items = $('.k-case-tree-searched-term').closest('.k-case-tree-item-outer')
			// 	if (!this.maximized) {
			// 		this.$vuetify.goTo(items[this.search_result_highlighted_index], {container:$('.k-case-tree-inner-wrapper')[0], offset:150})
			// 	}

			// do what we would do if the item were opened -- except don't make items open -- if the items are folders with lots of children this makes things confusing
			this.$store.commit('set', [this.framework_record, 'open_nodes', {}])
			// this.make_node_open(this.search_result_highlighted_tree_key)
			this.make_node_parents_open(this.search_result_highlighted_tree_key)
			this.$store.commit('set', [this.framework_record, 'active_node', this.search_result_highlighted_tree_key])
			this.$store.commit('set', [this.framework_record, 'last_clicked_node', this.search_result_highlighted_tree_key])
			this.set_starting_lsitem_identifier(this.framework_record.cfo.tree_nodes_hash[this.search_result_highlighted_tree_key].cfitem.identifier, this.search_result_highlighted_tree_key)
		},

		advanced_search_clicked() {
			if (this.advanced_search_showing) {
				this.execute_search_start()
				return
			}
				
			// if search terms are empty, show alert
			if (empty(this.search_terms)) {
				this.empty_search_alert()
				return
			}

			// if we get to here, start by doing a "normal" search for the search terms; the second param will throw it to execute_advanced_search when that's done
			this.execute_search_start(true, true)
		},

		execute_advanced_search() {
			// load the sparkl_bot_vectors for the current framework, if we don't already have them
			if (empty(this.framework_record.sparkl_bot_vectors)) {
				let payload = {
					framework_identifier: this.framework_record.lsdoc_identifier, 
					// we will always include notes when we do an advanced search
					with_notes: 'yes'
				}
				this.$store.dispatch('get_framework_sparkl_bot_vectors', payload).then(x=>{
					this.execute_advanced_search()
				})
				// return here and come back once the vectors are loaded
				return
			}

			// now get the vector for the search terms
			let payload = {
				service_url: 'sparkl_bot_vectors',
				strings: JSON.stringify([U.normalize_string_for_sparkl_bot(this.search_terms)])
			}
			this.$store.dispatch('service', payload).then(result => {
				// console.log('vectors', result.vectors)
				if (!result.vectors) {
					console.error('Couldn’t get vector for search terms (1)')
					return
				}

				// got the vector for the search terms; also calculate search_terms_normalized
				let search_terms_vector = result.vectors[0]
				let search_terms_normalized = U.normalize_string_for_sparkl_bot(this.search_terms)

				// go through each tree_key in tree_keys_for_advanced_search
				for (let i = 0; i < this.tree_keys_for_advanced_search.length; ++i) {
					let tree_key = this.tree_keys_for_advanced_search[i]
					let cfitem = this.framework_record.cfo.tree_nodes_hash[tree_key].cfitem

					// skip the cftree
					if (cfitem == this.framework_record.cfo.cftree.cfitem) continue

					// see if we already have a search_result_entry for this item's fullStatement/hcs/item_type
					let nfs = U.normalize_string_for_sparkl_bot(cfitem.fullStatement)
					let item_type = U.item_type_string(cfitem).toLowerCase()
					let sre = this.search_result_entries.find(x=>x.nfs == nfs && x.hcs == cfitem.humanCodingScheme && x.item_type == item_type)

					// if not, create a new sre
					if (!sre) {
						let new_sre = {
							nfs: nfs,
							hcs: cfitem.humanCodingScheme,
							item_type: item_type,
							tree_keys: [],
							sim_score: 0,	// this will be filled in below
						}
						new_sre.tree_keys.push(tree_key)

						// add to search_result_entries
						this.search_result_entries.push(new_sre)

					// else add to the existing sre
					} else {
						sre.tree_keys.push(tree_key)
					}
				}

				console.log('search_result_entries.length = ' + this.search_result_entries.length)
				// console.log(this.search_result_entries)

				for (let sre of this.search_result_entries) {
					// get the sb vector for this sre's item, then get the sim_score between it and the search terms
					let item_identifier = this.framework_record.cfo.tree_nodes_hash[sre.tree_keys[0]].cfitem.identifier
					let item_vector = this.framework_record.sparkl_bot_vectors[item_identifier]
					let sim_score = U.cosine_similarity(item_vector, search_terms_vector)
					sim_score = (sim_score < 0) ? 0 : Math.round(sim_score * 1000)
					let original_sim_score = sim_score

					// SB sim_score is 0-1000; scale this so we have "room" for the other factors
					sim_score = sim_score * (1 - this.advanced_search_featured_item_types_weight - this.advanced_search_lcs_weight - this.advanced_search_normal_search_weight)

					// if we're using advanced_search_featured_item_types: bump score up in proportion to the item_type's advanced_search_featured_item_types value
					if (this.advanced_search_featured_item_types_weight > 0) {
						let asfit = this.advanced_search_featured_item_types[sre.item_type]
						if (asfit) sim_score += asfit.factor * 1000 * this.advanced_search_featured_item_types_weight	// if this is empty, the item doesn't have a type
					}

					// bump score up if the exact search terms were found in the "normal" search
					for (let tree_key of sre.tree_keys) {
						if (this.basic_search_tree_key_hash[tree_key] == true) {
							sim_score += this.advanced_search_normal_search_weight * 1000
							break
						}
					}

					// get lcs and add this value to sim_score (this will be similar to, but not exactly the same as, the "normal" search)
					let lcs = U.get_lcs_val(sre.nfs, search_terms_normalized)
					sim_score += lcs * this.advanced_search_lcs_weight * 1000

					// insert sim_scores into search_result_entries
					sre.sim_score = Math.round(sim_score)
					// console.log(original_sim_score, sim_score, item_identifier, sre.nfs)
				}

				// sort by sim_scores, highest first, then show the first page of results
				this.search_result_entries.sort((a,b)=>b.sim_score - a.sim_score)
				this.show_search_results_page(0)

				// this.blur_text_input()
				this.advanced_search_showing = true

				U.loading_stop()

				// record search now, so we capture the amount of time the service ran
				this.record_search()

			}).catch(result=>{
				console.error('Couldn’t get vector for search terms (2)', result)
				return
			})
		},

		empty_search_alert() {
			this.$confirm({
				text: 'You must enter terms to do a search.',
				acceptText: 'Show Search Help',
				cancelText: 'Got It',
			}).then(y => {
				U.show_help('search')
			}).catch(n=>{
			}).finally(f=>{})
		},

		blur_text_input() {
			// console.log('blur_text_input')
			// blur text input, so the keyboard gets hidden on a phone
			if (this.$refs.framework_search_bar) {
				// try to do it both immediately and after the next tick
				$(this.$refs.framework_search_bar.$el).find('input').blur()
				this.$nextTick(x=>{
					$(this.$refs.framework_search_bar.$el).find('input').blur()
				})
			}
		},

		record_search(error) {
			let payload = {service_url: 'record_search'}

			if (error) payload.error = error

			payload.framework_identifier = this.lsdoc_identifier
			payload.user_id = this.user_info.user_id

			payload.search_terms = this.search_terms
			payload.search_type = this.advanced_search_showing ? 'advanced' : 'basic'
			payload.grades = (this.search_grade_low == 0) ? '-' : (this.grades[this.search_grade_low].value + '-' + this.grades[this.search_grade_high].value)
			payload.item_types = this.search_item_types.join(',')
			// if search_limit_to_item is on, send the value of search_item_for_limit as limit_to_item; otherwise send 0
			payload.limit_to_item = (this.search_limit_to_item ? this.search_item_for_limit : 0)
			payload.basic_search_result_count = Object.keys(this.basic_search_tree_key_hash).length

			payload.processing_time = Date.now() - this.execute_search_start_timestamp

			// console.log(payload)
			this.$store.dispatch('service', payload).then((result)=>{
				// console.log(result)
			})
		},
	}
}
