// to run this script: tap cmd-shift-1 on keyboard; enter `change_siis_to_aliases`
if (!window.custom_scripts) window.custom_scripts = {}
window.custom_scripts.change_siis_to_aliases = {
	input_required: false,
	payload: null,
	log: '',

	pre_fn: function() {
		console.warn('in pre_fn')
		return new Promise((resolve, reject)=>{
			this.fn('pre').then((output)=>resolve(output))
		})
	},
	fn: function(input) {
		return new Promise((resolve, reject)=>{
			let add_to_log = (s) => { this.log += `${s}\n` }

			if (input == 'pre') {
				let framework_record = vapp.case_tree_component.framework_record
				let json = framework_record.json

				add_to_log(``)
				add_to_log(`Initial CFItem count: ${json.CFItems.length}`)
				add_to_log(`Initial CFAssociation count: ${json.CFAssociations.length}`)
				add_to_log(``)

				let payload = {
					update_document_date: 'yes',
					check_out_and_in: 'yes',
					lsdoc_identifier: framework_record.lsdoc_identifier,
					CFItems: [],			// we will add only the updated properties of CFItems
					CFAssociations: [],		// but we will add the entire CFAssociations
				}

				let corrected_originals = 0
				let derivatives_removed = 0
				let associations_changed = 0
				let associations_removed = 0
				// go through all CFItems
				for (let item of json.CFItems) {
					// if the item has a sourceItemIdentifier
					if (item.extensions?.sourceItemIdentifier) {
						// if sii == identifier, just remove the sii and siu
						if (item.extensions.sourceItemIdentifier == item.identifier) {
							let o = { identifier: item.identifier }

							let extensions = object_copy(item.extensions)
							delete extensions.sourceItemIdentifier
							delete extensions.sourceItemURI
							if (U.object_has_keys(extensions)) o.extensions = extensions
							else o.extensions = '*CLEAR*'

							payload.CFItems.push(o)
							++corrected_originals
							add_to_log(`Removing sourceItemIdentifier for original item: ${item.fullStatement}`)
						
						// else we want to make this an alias...
						} else {
							// so we *delete* this item (unless it's already been deleted)
							if (payload.CFItems.find(x=>x.identifier == item.identifier)) {
								// I don't think we'll ever actually get to this
								add_to_log(`Derivative already removed; replacing : ${item.fullStatement}`)
							} else {
								add_to_log(`Deleting derivative ${item.identifier} (replacing with alias): ${item.fullStatement}`)
								payload.CFItems.push({identifier:item.identifier, delete:'yes'})
							}
							++derivatives_removed
							
							// then for all associations with this item...
							for (let assoc of json.CFAssociations) {
								if (assoc.originNodeURI.identifier == item.identifier || assoc.destinationNodeURI.identifier == item.identifier) {
									// if it's the item's isChildOf...
									if (assoc.associationType == 'isChildOf') {
										add_to_log(`  - updating ${assoc.associationType} assoc to create alias`)
										// then we want to change this assoc so it's pointing to the original item -- that creates the alias
										assoc.originNodeURI.identifier = item.extensions.sourceItemIdentifier
										assoc.originNodeURI.uri = item.extensions.sourceItemURI

										// save the updated association
										payload.CFAssociations.push(assoc)
										++associations_changed

										// TODO?: remove sourceDocumentIdentifier from assoc extension??
									
									// else delete the association
									} else {
										add_to_log(`  - removing ${assoc.associationType} assoc for derivative`)
										payload.CFAssociations.push({identifier:assoc.identifier, delete:'yes'})
										++associations_removed
									}
								}
							}
						}
					}
				}

				add_to_log(`Corrected originals (i.e. removed sii/siu): ${corrected_originals}`)
				add_to_log(`Derivatives removed: ${derivatives_removed}`)
				add_to_log(`Updated associations (i.e. created aliases): ${associations_changed}`)
				add_to_log(`Associations removed: ${associations_removed}`)
				add_to_log(``)
				add_to_log('CLICK RUN TO SAVE CHANGES')
				add_to_log(``)
				add_to_log('NOTE: framework json has already been altered; if you don’t save changes, you should refresh your browser.')

				console.warn('done', payload)
				this.payload = payload
			
			// input isn't 'pre', so user has clicked to save changes
			} else {
				vapp.$store.dispatch('save_framework_data', this.payload).then(()=>{
					vapp.$alert('Reload to see updated framework data').then(x=>{
						vapp.reload_window()
					})
				})
			}

			resolve(this.log)
		})
	},

}