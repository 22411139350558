export class RubricParser {
	constructor(framework_record) {
		this.framework_record = framework_record;
		this.reset();
	}

	// Initializes parsing state and results collections
	reset() {
		// Current parsing state
		this.current_rubric = null;
		this.current_criterion = null;
		this.current_level = null;
		this.parsing_notes = false;
		this.parsing_notes_for = null;
		// Results
		this.rubrics = [];
		this.errors = [];
		this.log = [];
		// Line tracking
		this.line_number = 0;
		// Tracks invalid sections to prevent adding malformed data to the final output
		this.current_section_invalid = false;  // Tracks if current section has a fatal error
		this.invalid_sections = new Set();    // Tracks which sections had errors
	}

	/**
	 * Records an error and optionally marks the current section as invalid.
	 * Invalid sections are excluded from the final output to maintain data integrity.
	 */
	add_error(message, is_fatal = false) {
		this.errors.push({
			line: this.line_number,
			message: `Line ${this.line_number}: ${message}`,
			is_fatal
		});

		if (is_fatal) {
			this.current_section_invalid = true;
			// Track which section had the error
			if (this.current_level) {
				this.invalid_sections.add(this.current_level.id);
			} else if (this.current_criterion) {
				this.invalid_sections.add(this.current_criterion.id);
			} else if (this.current_rubric) {
				this.invalid_sections.add(this.current_rubric.id);
			}
		}
	}

	/**
	 * Main parsing entry point that processes text input line by line.
	 * Comments (lines starting with //) are ignored.
	 */
	parse(text) {
		this.reset();
		const lines = text.split('\n');

		for (const line of lines) {
			this.line_number++;
			
			if (line.trim().startsWith('//')) {
				continue;
			}

			try {
				this.parse_line(line);
			} catch (error) {
				this.add_error(error.message);
			}
		}

		// Validate final entries
		this.validate_current_entries();
		
		return {
			rubrics: this.rubrics,
			errors: this.errors,
			log: this.log
		};
	}

	/**
	 * Processes a single line of input, handling different line types:
	 * - Main entries (RUBRIC, CRITERION, LEVEL)
	 * - Metadata (QUALITY, NOTES, DESCRIPTION)
	 * - Continuation lines (bullet points, multi-line content)
	 */
	parse_line(line) {
		const trimmed_line = line.trim();

		// Skip empty lines unless in multi-line context
		if (!trimmed_line && !this.parsing_notes && !this.current_level) {
			return;
		}

		// Skip non-RUBRIC lines when in an invalid section to maintain hierarchy
		if (this.current_section_invalid && !trimmed_line.startsWith('RUBRIC:')) {
			return;
		}


		if (trimmed_line.startsWith('RUBRIC:')) {
			this.handle_rubric_line(trimmed_line);
			this.parsing_notes = false;
			this.parsing_notes_for = null;
		} else if (!this.current_section_invalid) {
			if (trimmed_line.startsWith('CRITERION:')) {
				this.handle_criterion_line(trimmed_line);
				this.parsing_notes = false;
				this.parsing_notes_for = null;
			} else if (trimmed_line.startsWith('LEVEL:')) {
				this.handleLevelLine(trimmed_line);
				this.parsing_notes = false;
				this.parsing_notes_for = null;
			} else if (trimmed_line.startsWith('QUALITY:')) {
				this.handleQualityLine(trimmed_line);
				this.parsing_notes = false;
				this.parsing_notes_for = null;
			} else if (trimmed_line.startsWith('NOTES:')) {
				this.handleNotesLine(line);
				this.parsing_notes = true;
				if (this.current_level) this.parsing_notes_for = 'level';
				else if (this.current_criterion) this.parsing_notes_for = 'criterion';
				else if (this.current_rubric) this.parsing_notes_for = 'rubric';
			} else if (trimmed_line.startsWith('DESCRIPTION:')) {
				this.handle_description_line(trimmed_line);
				this.parsing_notes = false;
				this.parsing_notes_for = null;
			} else if (trimmed_line.startsWith('FEEDBACK:')) {
				this.handle_feedback_line(trimmed_line);
				this.parsing_notes = false;
				this.parsing_notes_for = null;
			} else {
				this.handle_continuation_line(line);
			}
		}
	}

	/**
	 * Processes a RUBRIC line, which can include an optional GUID/HRC identifier.
	 * Format: RUBRIC: [:identifier] title
	 */
	handle_rubric_line(line) {
		// Check if we already have a rubric
		if (this.current_rubric || this.rubrics.length > 0) {
			this.add_error('Only one rubric can be imported at a time', true);
			this.current_section_invalid = true;
			return;
		}
		
		this.validate_current_entries();
		
		const match = line.match(/RUBRIC:\s*(:[^\s]+)?\s*(.+)?/);
		if (!match) {
			this.add_error('Invalid RUBRIC format', true);
			return;
		}

		const [, hrc_guid, title] = match;
		
		if (!title || !title.trim()) {
			this.add_error('Rubric title is required', true);
			this.current_rubric = null;
			this.current_section_invalid = true;
			return;
		}

		if (hrc_guid) {
			const identifier = hrc_guid.substring(1);
			if (!this.validate_identifier(identifier)) {
				this.add_error(`Invalid GUID/HRC "${identifier}" - No matching item found in framework`, true);
				this.current_section_invalid = true;
				return;
			}
		}

		this.current_section_invalid = false;

		this.current_rubric = {
			identifier: hrc_guid ? hrc_guid.substring(1) : null,
			id: this.generate_id(),
			title: title.trim(),
			qualities: new Map(),
			notes: '',
			criteria: []
		};

		this.add_log(`Started processing rubric: ${title.trim()}`);
	}

	/**
	 * Processes a CRITERION line, which can include:
	 * - Optional GUID/HRC identifier
	 * - Optional weight in brackets
	 * Format: CRITERION: [:identifier] *[weight] description
	 */
	handle_criterion_line(line) {
		if (!this.current_rubric) {
			this.add_error('CRITERION must be defined within a RUBRIC', true);
			return;
		}
	
		this.validate_current_entries();
	
		const match = line.match(/CRITERION:\s*(:[^\s]+)?\s*(\*\[([^\]]+)\])?\s*(.+)/);
		if (!match) {
			this.add_error('Invalid CRITERION format', true);
			return;
		}
	
		const [, hrc_guid, , weight, description] = match;

		if (hrc_guid) {
			const identifier = hrc_guid.substring(1);
			if (!this.validate_identifier(identifier)) {
				this.add_error(`Invalid GUID/HRC "${identifier}" - No matching item found in framework`, true);
				this.current_section_invalid = true;
				return;
			}
		}
		
		this.current_section_invalid = false;
		
		this.current_criterion = {
			identifier: hrc_guid ? hrc_guid.substring(1) : null,
			id: this.generate_id(),
			description: description.trim(),
			weight: weight ? parseFloat(weight) : 1,
			rubricId: this.current_rubric.identifier,
			notes: '',
			levels: []
		};
	
		this.add_log(`Started processing criterion: ${description.trim()}`);
	}

	/**
	 * Processes a LEVEL line, which requires a score that matches a defined quality.
	 * Format: LEVEL: [:identifier] score: [description]
	 */
	handleLevelLine(line) {
		if (!this.current_criterion) {
			this.add_error('LEVEL must be defined within a CRITERION', true);
			return;
		}

		if (this.current_level && !this.invalid_sections.has(this.current_level.uri)) {
			this.current_criterion.levels.push(this.current_level);
		}

		const match = line.match(/LEVEL:\s*(:[^\s]+)?\s*([^:]+):\s*(.+)?/);
		if (!match) {
			this.add_error('Invalid LEVEL format', true);
			return;
		}

		const [, hrc_guid, score] = match;

		if (hrc_guid) {
			const identifier = hrc_guid.substring(1);
			if (!this.validate_identifier(identifier)) {
				this.add_error(`Invalid GUID/HRC "${identifier}" - No matching item found in framework`, true);
				this.current_section_invalid = true;
				return;
			}
		}
		
		if (!this.current_rubric.qualities.has(score.trim())) {
			this.add_error(`Score "${score.trim()}" not defined in rubric qualities`, true);
			return;
		}

		this.current_section_invalid = false;

		this.current_level = {
			identifier: hrc_guid ? hrc_guid.substring(1) : null,
			id: this.generate_id(),
			description: '',
			quality: this.current_rubric.qualities.get(score.trim()),
			score: this.parse_score(score),
			rubricCriterionId: this.current_criterion.identifier,
			notes: ''
		};

		this.add_log(`Started processing level: Score ${score}`);
	}
	
	/**
	 * Processes a QUALITY line that defines score-to-quality mappings.
	 * Format: QUALITY: score: description
	 */
	handleQualityLine(line) {
		if (!this.current_rubric) {
			this.add_error('QUALITY must be defined within a RUBRIC', true);
			return;
		}
	
		const match = line.match(/QUALITY:\s*([^:]+):\s*(.+)/);
		if (!match) {
			this.add_error('Invalid QUALITY format', true);
			return;
		}
	
		const [, score, quality] = match;
		this.current_rubric.qualities.set(score.trim(), quality.trim());
		this.add_log(`Added quality level: ${score.trim()} -> ${quality.trim()}`);
	}

	/**
	 * Handles multi-line notes sections that can be added to any element type
	 */
	handleNotesLine(line) {
		const content = line.replace(/^NOTES:\s*/, '');
		
		if (this.current_level) {
			this.current_level.notes = content;
		} else if (this.current_criterion) {
			this.current_criterion.notes = content;
		} else if (this.current_rubric) {
			this.current_rubric.notes = content;
		} else {
			this.add_error('NOTES must be defined within a RUBRIC, CRITERION, or LEVEL', true);
			return;
		}
	}

	/**
	 * Processes description lines that can be added to any element type
	 */
	handle_description_line(line) {
		const content = line.replace(/^DESCRIPTION:\s*/, '').trim();
		
		if (this.current_level) {
			this.current_level.description = content;
		} else if (this.current_criterion) {
			this.current_criterion.description = content;
		} else if (this.current_rubric) {
			this.current_rubric.description = content;
		} else {
			this.add_error('DESCRIPTION must be defined within a RUBRIC, CRITERION, or LEVEL', true);
			return;
		}
	}

	/**
	 * Processes FEEDBACK lines that can only be added to levels
	 */
	handle_feedback_line(line) {
		if (!this.current_level || this.current_section_invalid) {
			return;
		}

		const content = line.replace(/^FEEDBACK:\s*/, '').trim();
		this.current_level.feedback = content;
	}

	/**
	 * Handles continuation lines for multi-line content:
	 * - Notes sections
	 * - Bullet points in level descriptions
	 * - General description continuations
	 */
	handle_continuation_line(line) {
		if (this.parsing_notes) {
			switch(this.parsing_notes_for) {
				case 'level':
					this.current_level.notes += '\n' + line;
					break;
				case 'criterion':
					this.current_criterion.notes += '\n' + line;
					break;
				case 'rubric':
					this.current_rubric.notes += '\n' + line;
					break;
			}
			return;
		}
	
		if (this.current_level && (line.trim().startsWith('•') || line.trim().startsWith('-'))) {
			const bullet_point = line;
			if (this.current_level.description) {
				this.current_level.description += '\n' + bullet_point;
			} else {
				this.current_level.description = bullet_point;
			}
			return;
		}
	
		// Handle other continuation lines
		if (this.current_level) {
			// Always append to level description, creating it if it doesn't exist
			if (this.current_level.description) {
				this.current_level.description += '\n' + line;
			} else {
				this.current_level.description = line;
			}
		} else if (this.current_criterion && this.current_criterion.description) {
			this.current_criterion.description += '\n' + line;
		} else if (this.current_rubric && this.current_rubric.description) {
			this.current_rubric.description += '\n' + line;
		} else if (line.trim()) {
			this.add_error('Unexpected continuation line', true);
		}
	}

	/**
	 * Validates and finalizes the current entries in the hierarchy.
	 * Ensures required fields are present and adds valid entries to their parents.
	 */
	validate_current_entries() {
		if (this.current_level && !this.invalid_sections.has(this.current_level.id)) {
			if (!this.current_level.description) {
				this.add_error(`Level missing required description`);
			} else {
				this.current_criterion.levels.push(this.current_level);
			}
		}
		this.current_level = null;

		if (this.current_criterion && !this.invalid_sections.has(this.current_criterion.id)) {
			if (!this.current_criterion.description) {
				this.add_error(`Criterion missing required description`);
			} else if (!this.current_rubric.criteria.includes(this.current_criterion)) {
				this.current_rubric.criteria.push(this.current_criterion);
			}
		}
		this.current_criterion = null;

		if (this.current_rubric && !this.invalid_sections.has(this.current_rubric.id)) {
			if (!this.current_rubric.title) {
				this.add_error(`Rubric missing required title`);
			} else if (!this.rubrics.includes(this.current_rubric)) {
				this.rubrics.push(this.current_rubric);
			}
		}

		this.current_section_invalid = false;
	}

	/**
	 * Validates that provided identifiers exist in the framework context.
	 * An empty identifier is considered valid as it indicates a new association
	 * should be created rather than linking to an existing item.
	 */
	validate_identifier(identifier) {
		if (!identifier) return true;
		
		if (this.framework_record.lsdoc_identifier === identifier) {
			return true;
		}

		if (this.framework_record.cfo.cfitems[identifier]) {
			return true;
		}

		return false;
	}


	/**
	 * Generates a unique temporary identifier for tracking elements during parsing.
	 * Uses a random base-36 string to ensure uniqueness within the current session.
	 */
	generate_id() {
		return 'generated-' + Math.random().toString(36).substr(2, 9);
	}

	/**
	 * Attempts to convert score values to numbers while preserving non-numeric
	 * scores (e.g., "A", "B", "Pass") as strings.
	 */
	parse_score(score) {
		const num = parseFloat(score);
		return isNaN(num) ? score : num;
	}

	/**
	 * Records an error with its line number for later reporting.
	 */
	add_error(message) {
		this.errors.push({
			line: this.line_number,
			message: `Line ${this.line_number}: ${message}`
		});
	}

	/**
	 * Records processing information for debugging and user feedback.
	 */
	add_log(message) {
		this.log.push({
			line: this.line_number,
			message: message
		});
	}
}