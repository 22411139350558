// Various utility functions, some created by PW and some brought in from the intertubes (all open source)
// Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams

/** Checks whether a value is empty, defined as null or undefined or "".
 *  Note that 0 is defined as not empty.
 *  @param {*} val - value to check
 *  @returns {boolean}
 */
window.empty = function(val) {
	// you can also call this fn as empty(o, 'foo', 'bar'), which will return true if:
	// - o is empty OR o is not an object
	// - o.foo is empty OR o.foo is not an object
	// - o.foo.bar is empty OR o.foo.bar is not an object
	// this simplifies an if clause like `if (empty(o) || empty(o.foo) || empty(o.foo.bar))` to `if (empty(o, 'foo', 'bar'))`
	if (arguments.length > 1) {
		if (empty(val) || typeof(val) != 'object') {
			return true
		}

		// if we get to here, arguments[1] might be an array or the first of a series of strings; handle both cases
		let args
		if ($.isArray(arguments[1])) {
			args = arguments[1]
		} else {
			// copy arguments, then use splice to get everything from index 1 on
			args = $.merge([], arguments).splice(1)
		}

		// if we're here, we know that val is itself an object
		if (empty(val[args[0]])) {
			return true
		} else if (args.length > 1) {
			// shift the first value out of args and recurse
			val = val[args[0]]
			args.shift()
			return empty(val, args)
		}
		return false
	}

	// note that we need === because (0 == "") evaluates to true
	return (val === null || val === "" || val === undefined)
}

// look for a nested property of an object; if any property in the list is empty, return null
// obj = {alpha: {bravo:'charlie'}}
// oprop(obj, 'alpha', 'bravo') // == 'charlie'
window.oprop = function(obj) {
	if (empty(obj)) return null
	if (arguments.length == 1 || typeof(obj) != 'object') return obj

	// if we get to here, arguments[1] might be an array or the first of a series of strings; handle both cases
	let args
	if ($.isArray(arguments[1])) {
		args = arguments[1]
	} else {
		// copy arguments, then use splice to get everything from index 1 on
		args = $.merge([], arguments).splice(1)
	}

	// if args[0] isn't a property of obj, return null
	if (empty(obj[args[0]])) {
		return null

	// if we still have more than 1 arg, shift the first value out of args and recurse
	} else if (args.length > 1) {
		obj = obj[args[0]]
		args.shift()
		return oprop(obj, args)

	} else {
		return obj[args[0]]
	}
}

/** Return val or default_val, depending on whether val isn't or is empty (as defined by window.empty above)
 *  SHORTCUT: dv()
 */
window.default_value = function(val, default_val) {
	if (!empty(val)) return val
	return default_val
}
window.dv = window.default_value

// use isNaN to determine if a variable can be coerced to a numeric value. Works for strings, but also for numbers.
window.is_numeric = function(s) {
	return !isNaN(s)
}

/** Set a property of an object to a default value if the property is currently empty (as defined by window.empty above)
 *  SHORTCUT: sdp()
 *  Two versions:
 *      1. sdp(o, 'foo', 'bar')
 *          - sets o.foo to 'bar', unless o.foo is already set
 *      2. sdp(o, p, 'foo', 'bar')
 *          - if p.foo is set, then set o.foo to p.foo; otherwise set o.foo to 'bar'
 *
 *  Also do some limited type checking: if default_val is a Boolean or a number, make sure the value we ultimately set
 *      is a Boolean or number; if converting it to the correct type fails, throw an error
 *  Version 2 can also include a "legal_vals" array, for enumeration checking
 */
window.set_default_property = function(o) {
	var prop, default_val, val, legal_vals
	if (arguments.length >= 4) {
		legal_vals = arguments[4]
		default_val = arguments[3]
		prop = arguments[2]
		var p = arguments[1]
		if (!empty(p) && !empty(p[prop])) val = p[prop]
		else val = default_val
	} else {
		prop = arguments[1]
		default_val = arguments[2]
		if (!empty(o[prop])) val = o[prop]
		else val = default_val
	}

	// if default_val is true or false, make sure the value we set is also a boolean
	if (default_val === true || default_val === false) {
		if (val === 'true') val = true
		if (val === 'false') val = false
		if (val != true && val != false) {
			if (typeof(prop) == 'object') prop = '[object]'
			throw new Error(sr('Boolean argument expected for property $1; $2 received', prop, val))
		}
		// convert 1/0 to true/false
		val = (val == true)

	// if default_val is a number, make sure the value we set is also a number
	} else if (typeof(default_val) == 'number') {
		let new_val = val * 1
		if (isNaN(new_val)) {
			throw new Error(sr('Numeric argument expected for property $1; $2 received', prop, val))
		}
		val = new_val
	}

	// if we got legal_vals (which must be an array), check to make sure the value is one of those; use default_val otherwise
	if (!empty(legal_vals)) {
		if (legal_vals.find(x => x == val) == null) {
			console.log(sr('illegal value found for prop “$1”: “$2”', prop, val))
			val = default_val
		}
	}

	o[prop] = val
}
window.sdp = window.set_default_property

/** Replace variables in a string, a la PHP
 * e.g.:
 * str_replace("replace value $bar.", {bar: "foo"})
 *    =>
 * "replace value foo."
 *
 * o.bar can be either a scalar value or a function that returns a scalar value
 *
 * Or, you can pass variables in directly, and specify them with $1, $2, $3, etc.
 * str_replace("replace value $1.", "foo")
 *    =>
 * "replace value foo."
 *
 * SHORTCUT: sr()
 *
 *  @param {string} s
 *  @param {object|array} [o] - if this is an array, it will be assumed to be an array of objects. if this is not an array or an object, it will treat the arguments array as a list of scalars
 *  @returns {*}
 */
window.str_replace = function(s, o) {
	// if o is an array, recursively process each object in the array
	if ($.isArray(o)) {
		for (var i = 0; i < o.length; ++i) {
			s = str_replace(s, o[i])
		}

	} if (o && typeof(o) == "object") {
		// find all instances of $xxx
		var matches = s.match(/\$(\w+)\b/g)
		if (!empty(matches)) {
			for (var i = 0; i < matches.length; ++i) {
				var key = matches[i].substr(1)
				var val = null
				// scalars
				if (typeof(o[key]) == "string" || typeof(o[key]) == "number") {
					val = o[key]
				} else if (typeof(o[key]) == "function") {
					val = o[key]()
				}
				if (val !== null) {
					var re = new RegExp("\\$" + key + "\\b", "g")
					s = s.replace(re, val)
				}
			}
		}
	} else {
		for (var i = 1; i < arguments.length; ++i) {
			var re = new RegExp("\\$" + i + "\\b", "g")
			s = s.replace(re, arguments[i])
		}
	}
	return s
}
// shortcut
window.sr = str_replace

// shortcut for doing a jquery extend; this is useful for console.logging vue reactive objects
window.extobj = function(o, stringify) {
	o = $.extend(true, {}, o)
	if (stringify) return JSON.stringify(o, null, 4)
	return o
}
// actually this works better in many circumstances
window.object_copy = function(o, stringify) {
	o = JSON.parse(JSON.stringify(o))
	if (stringify) return JSON.stringify(o, null, 4)
	return o
}

window.U = {}

// we use this to transmit data to services when the data might include html that might get flagged/rejected by firewalls (e.g. saving Sparkl exercises to the GaDOE velocity server)
U.encode_blocked_keywords = function(s) {
	s = s.replaceAll('iframe', 'ifxzxzxrame')
	s = s.replaceAll('embed', 'emxzxzxbed')
	s = s.replaceAll('object', 'obxzxzxject')
	s = s.replaceAll('http', 'htxzxzxtp')		// this will also deal with "https"
	s = s.replaceAll('script', 'scxzxzxript')	// this will also deal with "javascript"
	s = s.replaceAll('onclick', 'oncxzxzxlick')
	s = s.replaceAll('style', 'stxzxzxyle')
	s = s.replaceAll('data:image/webp;base64', 'encodedxzxzximage')

	return s
}

U.ajax = function(service_name, data, callback_fn, override_options) {
	// ajax calls are posted to to ajax.php, with the service_name included in the post data
	let url
	// local development with 'npm run serve'
	if (document.location.host.indexOf('localhost') > -1) {
	 	url = "/src/ajax.php"
	// server, or local testing with 'npm run build'
	} else {
		url = "/src/ajax.php"
	}

	data.service_name = service_name

	// if we're using OIDC or cglt_sso, we may have a session_id that we have to pass through to the service
	if (U.session_id) {
		data.session_id = U.session_id
	}

	var options = {
		type: "POST",
		url: url,
		cache: false,
		data: data,
		dataType: "text",
		success: function(str, text_status) {
			var result
			if (empty(str)) {
				result = {"status": "Ajax returned with no status"}
			} else {
				try {
					result = JSON.parse(str)
				} catch(e) {
					result = {"status": str}
				}
			}

			if (empty(result.status)) {
				result.status = "Ajax returned with no status"
			}

			if (result.status != "ok") {
				// error
				console.log("ajax success but not 'ok'", result)
			}

			if (!empty(callback_fn)) {
				callback_fn(result)
			}

		},
		error: function(jqXHR, textStatus, errorThrown) {
			var result = {
				"status": "Ajax server error",
				"ajax_name": service_name,
				"textStatus": textStatus,
				"errorThrown": errorThrown,
				"responseText": jqXHR.responseText
			}

			if (!empty(callback_fn)) {
				callback_fn(result)
			}
		}
	}

	// override any options coming in
	if (!empty(override_options)) {
		for (var key in override_options) {
			options[key] = override_options[key]
		}
	}

	$.ajax(options)
}

U.retrieve_file = function(filename, callback_fn) {
	// callback_fn is required; caller can optionally send extra data to send to the service as the second argument, in which case callback_fn will be the 3rd arg.
	let data = {}
	let dataType = 'text'
	if (typeof(callback_fn) == 'object') {
		// extra data sent in would be, e.g., access_type, user_id, and framework_identifier, so we can save a framework_access record
		data = callback_fn
		callback_fn = arguments[2]

		// data can also include a `dataType` argument; if so, that's used as the ajax dataType, and removed from the data to be sent in
		if (data.dataType) {
			dataType = data.dataType
			delete data.dataType
		}
	}

	// ajax calls are posted to to ajax.php, with the service_name included in the post data
	let url
	// local development with 'npm run serve'
	if (document.location.host.indexOf('localhost') > -1) {
	 	url = "/src/ajax.php"
	// server, or local testing with 'npm run build'
	} else {
		url = "/src/ajax.php"
	}

	// add the service_name and the incoming filename to data
	data.service_name = 'retrieve_file'
	data.filename = filename

	// NOTE: if the file does not exist, or if it doesn't return valid json, callback_fn will return an empty json ({});
	// if the service doesn't complete for some unknown reason, it will return an empty string
	var options = {
		type: "POST",
		url: url,
		cache: false,	// true??
		data: data,
		dataType: dataType,
		success: function(str, text_status) {
			// note that we don't do any checking of the contents here; str could be an empty file, for example.
			callback_fn(str)
		},
		error: function(jqXHR, textStatus, errorThrown) {
			var result = {
				"status": "Ajax server error",
				"ajax_name": service_name,
				"textStatus": textStatus,
				"errorThrown": errorThrown,
				"responseText": jqXHR.responseText
			}
			console.log(`An error occurred when retrieving file ${filename} (U.retrieve_file)`)
			console.log(result)

			if (!empty(callback_fn)) {
				callback_fn('')
			}
		}
	}

	$.ajax(options)
}

U.get_json_file = function(filename, callback_fn) {
	// callback_fn is required; caller can optionally send extra data to send to the service as the second argument, in which case callback_fn will be the 3rd arg.
	let data = {}
	if (typeof(callback_fn) == 'object') {
		// extra data sent in would be, e.g., access_type, user_id, and framework_identifier, so we can save a framework_access record
		data = callback_fn
		callback_fn = arguments[2]
	}

	// ajax calls are posted to to ajax.php, with the service_name included in the post data
	let url
	// local development with 'npm run serve'
	if (document.location.host.indexOf('localhost') > -1) {
	 	url = "/src/ajax.php"
	// server, or local testing with 'npm run build'
	} else {
		url = "/src/ajax.php"
	}

	// add the service_name and the incoming filename to data
	data.service_name = 'retrieve_file'
	data.filename = filename

	// NOTE: if the file does not exist, or if it doesn't return valid json, callback_fn will return an empty json ({});
	// if the service doesn't complete for some unknown reason, it will return an empty string
	var options = {
		type: "POST",
		url: url,
		cache: false,	// true??
		data: data,
		dataType: "text",
		success: function(str, text_status) {
			// before converting to json, check to see if the str has a $; if so, we may need to parse latex
			let may_have_latex = false
			let json = {}
			if (!empty(str)) {
				try {
					may_have_latex = str.includes('$')
					json = JSON.parse(str)
				} catch(e) {
					// we got a non-empty return value, but the json could not be parsed
					console.log('An error occurred when parsing the JSON for retrieved file ' + filename)
					console.log(str)
					json = {}
				}
			}

			// note that the callback_fn is free to just receive the first variable (json) and ignore may_have_latex
			callback_fn(json, may_have_latex)
		},
		error: function(jqXHR, textStatus, errorThrown) {
			var result = {
				"status": "Ajax server error",
				"ajax_name": service_name,
				"textStatus": textStatus,
				"errorThrown": errorThrown,
				"responseText": jqXHR.responseText
			}
			console.log('An error occurred when retrieving file ' + filename)
			console.log(result)

			if (!empty(callback_fn)) {
				callback_fn('', false)
			}
		}
	}

	$.ajax(options)
}

U.ajax_unauthorized = function(result) {
	return (typeof(result) == 'object' && result.errorThrown == 'Unauthorized')
}

U.loading_start = function(msg, identifier) {
	$('#spinner-wrapper').show()
	if (!empty(msg)) {
		$('#spinner-wrapper').append(sr('<div id="spinner-wrapper-msg" style="text-align:center;margin:20% 30px 0 30px;font-size:24px;font-weight:bold;font-family:sans-serif;color:#fff;">$1</div>', msg))
	}

	// if we receive an identifier, set U.loading_start_identifier to make sure we don't close the loading message too soon
	if (typeof(identifier) == 'string') {
		U.loading_start_identifier = identifier
	} else {
		U.loading_start_identifier = ''
	}
}

U.loading_stop = function(identifier) {
	// see above
	if (typeof(identifier) == 'string') {
		if (U.loading_start_identifier != identifier) {
			return
		}
	}

	$('#spinner-wrapper').hide()
	$('#spinner-wrapper-msg').remove()
}

// clear the location search string without reloading the page or generating a new history entry
U.clear_location_search = function() {
	window.history.replaceState(null, '', window.location.pathname)
}

U.object_has_keys = function(o) {
	if (typeof(o) != 'object') return false
	return Object.keys(o).length > 0
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 * https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 */
U.shuffle_array = function(array) {
	for (var i = array.length - 1; i > 0; i--) {
		var j = Math.floor(Math.random() * (i + 1));
		var temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
}

// if one argument is specified, it's max and we return >= 0  and < max (use for an array index, e.g.)
// if two arguments are specified, they're min and max, and we return >= min and <= max
// if the first argument is a function, assume it's a random number generator to use instead of Math.random()
U.random_int = function() {
	let rng = Math.random
    let args
	if (arguments.length > 0 && typeof(arguments[0]) == "function") {
		rng = arguments[0]
        args = [arguments[1], arguments[2]]
	} else {
        args = arguments
    }

	if (empty(args[0])) return 0

	let min, max
	if (empty(args[1])) {
		min = 0
		max = args[0] * 1
	} else {
		min = args[0] * 1
		max = args[1] * 1 + 1
	}
	if (isNaN(min) || isNaN(max)) {
		return 0
	}
	return min + Math.floor(rng() * (max-min))
}

U.word_count = function(text) {
	if (empty(text)) return 0
	text = '' + text

	// remove entities
	text = text.replace(/\&[#\w]+/g, ' ')

	// insert spaces at breaks
	text = text.replace(/\b/g, ' ')

	// remove tags
	text = text.replace(/<.*?>/g, ' ')

	// remove non-letters
	text = text.replace(/[^\w\s]/g, '')

	// trim
	text = $.trim(text)

	// split on spaces and return count
	let arr = text.split(/\s+/)
	return arr.length;
}

U.copy_to_clipboard = function(s) {
	// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
	// https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
	let fallback = (s) => {
		$('body').append('<textarea class="k-copy-to-clipboard-input-textarea"></textarea>')
		let jq = $('body').find('.k-copy-to-clipboard-input-textarea')
		jq.val(s)
		jq[0].select()
		document.execCommand("copy")
		jq.remove()
	}

	if (!navigator.clipboard) {
		fallback(s)
	} else {
		navigator.clipboard.writeText(s).then(function() {
			console.log('Async: Copy to clipboard was successful')
		}, function(err) {
			console.error('Async: Could not copy text; trying fallback', err)
			fallback(s)
		})
	}
}

U.tabber = function(event) {
	let text = event.target.value
	let original_selection_start = event.target.selectionStart
	let text_start = text.slice(0, original_selection_start)
	let text_end = text.slice(original_selection_start)
	event.target.value = `${text_start}\t${text_end}`
	event.target.selectionEnd = event.target.selectionStart = original_selection_start + 1
}

// plural string / plural string with number
// ps('word', 1) => 'word'
// ps('word', 2) => 'words'
// psn('word', 2) => '2 words'
// ps('a word', 2, 'the words') => 'the words'
U.ps = function(s, val, plural_s) {
	if (val*1 == 1) return s
	if (!empty(plural_s)) return plural_s
	return s + 's'
}
U.psn = function(s, val, plural_s) {
	s = U.ps(s, val, plural_s)
	return sr('$1 $2', val, s)
}

U.capitalize_word = function(s) {
	if (empty(s) || typeof(s) != 'string') return ''
	return s[0].toUpperCase() + s.substr(1)
}

U.truncate_to_word = function(str, lenth) {
	// Limit the string to the first lenth characters
	let truncated = str.slice(0, lenth);
  
	// If the truncated string ends within a word, remove the partial word
	if (str.length > lenth && str[lenth] !== ' ' && !truncated.endsWith(' ')) {
		truncated = truncated.slice(0, truncated.lastIndexOf(' '));
	}
  
	return truncated;
}

U.remove_accents = function(str) {
	const accents     = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
	const accents_out = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'
	str = str.split('')
	let sl = str.length
	let i, x
	for (i = 0; i < sl; i++) {
		if ((x = accents.indexOf(str[i])) != -1) {
			str[i] = accents_out[x]
		}
	}
	return str.join('')
}

// easy natural sort algorithm that actually seems to work!
// https://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
U.natural_sort = function(a, b) {
	return a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true})
}

// parse and return unique characters in a string
// note that the last copy of each repeated letter will be returned
// note that this is case sensitive -- U.unique_chars('fFoobbar') returns 'fFobar'; use U.unique_chars(s.toLowerCase) to convert to lc first
U.unique_chars = function(s) {
	return s.replace(/(.)(?=.*\1)/g, "")
}

U.html_to_text = function(html) {
	return $.trim($('<div>' + html + '</div>').text())
}

// strip out tags, all chars except alphanumeric characters and spaces, reduce space runs to a single space, and make all letters lower case
U.alphanum_only = function(s) {
	return $.trim(U.html_to_text(s)).replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, ' ').toLowerCase()
}

// https://stackoverflow.com/a/63698925
U.find_highest_z = () =>
  [...document.querySelectorAll('body *')]
	.map(elt => parseFloat(getComputedStyle(elt).zIndex))
	.reduce((highest, z) => z > highest ? z : highest, 1)

// extend the marked library...
setTimeout(()=>{
	window.original_marked_function = window.marked
	window.markedInline = window.marked.parseInline
	window.marked = function(src, opt, callback) {
		if (!src) return ''
		// console.log('BEF: ', src)

		// mark newlines, spaces, and tabs within code blocks for replacement after applying marked
		src = src.replace(/`([^`]+)`/g, ($0, $1) => {
			let s = $1.replace(/ /g, 'XXXCODE_SPACEXXX')
			s = s.replace(/\t/g, 'XXXCODE_TABXXX')
			s = s.replace(/\n/g, 'XXXCODE_NLXXX')
			return `\`${s}\``
		})

		// and mark lines that start with a newline, then one or more spaces, to start with `<br>`, then nbsp's for each space *except the first space*
		// this makes it so that you can guarantee a newline by adding a space at the start of the newline; and you can indent by adding more spaces
		src = src.replace(/\n([ ]+)([^* ])/g, ($0, $1, $2) => {
			$1 = $1.substr(1)
			return 'XXXCODE_NLXXX' + $1.replace(/\s/g, ' ') + $2
		})

		// 2024/03/07: I'm not sure why we had this, but it seems to screw up other things, and it seems to not be in synch with the regexp above, so removing it
		// src = src.replace(/(\n+)\*/g, ($0, $1) => {
		// 	// $1 = $1.substr(1)
		// 	// we need a space before the XXXCODE_NLXXX; otherwise a line with just *italics* in the line won't get marked with the italics tag
		// 	return $1.replace(/\n/g, ' XXXCODE_NLXXX\n') + '*'
		// })

		// for text such as `a < b means *x*, but a > b means *y*`, original_marked_function will fail to convert the *x*, presumably because it interprets `< b means *x*, but a >` as a tag.
		// to deal with this, we put some code in to preserve ` > `
		// src = src.replace(/>/g, 'XXXCODE_GTXXX')
		src = src.replace(/ > /g, 'XXXCODE_GT1XXX')
		src = src.replace(/KKXXKK>KKXXKK/g, 'XXXCODE_GT2XXX')	// preserve_latex will replace spaces with KKXXKK in latex phrases
		
		// add superscript caret check
		src = src.replace(/\^(\S+?)\^/g, '<sup>$1</sup>')

		// add subscript tilde check 
		src = src.replace(/~(\S+?)~/g, '<sub>$1</sub>')

		src = window.original_marked_function(src, opt, callback)

		src = src.replace(/XXXCODE_GT2XXX/g, 'KKXXKK>KKXXKK')
		src = src.replace(/XXXCODE_GT1XXX/g, ' > ')
		// src = src.replace(/XXXCODE_GTXXX/g, '>')

		// note in the below that we keep \n's at the start of each line; this doesn't seem to cause any issues.

		// handle roman numeral ordered lists (see also in the AP spreadsheet export, in CASEFrameworkViewer
		src = src.replace(/(^|\n|<p>)([ivx]+)\. (.*)/g, ($0, $1, $2, $3) => {
			let start = ['', 'i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x', 'xi', 'xii', 'xiii', 'xiv', 'xv', 'xvi', 'xvii', 'xviii', 'xix', 'xx', 'xi', 'xii', 'xiii', 'xiv', 'xv'].indexOf($2)
			return `${$1}<ol type="i" start="${start}"><li>${$3}</li></ol>`
		})

		// handle lettered ordered lists
		src = src.replace(/(^|\n|<p>)([a-z])\. (.*)/g, ($0, $1, $2, $3) => {
			let start = ['', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'].indexOf($2)
			return `${$1}<ol type="a" start="${start}"><li>${$3}</li></ol>`
		})
		src = src.replace(/(^|\n|<p>)([A-Z])\. (.*)/g, ($0, $1, $2, $3) => {
			let start = ['', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].indexOf($2)
			return `${$1}<ol type="A" start="${start}"><li>${$3}</li></ol>`
		})

		src = src.replace(/XXXCODE_NLXXX/g, '<br>')
		src = src.replace(/XXXCODE_TABXXX/g, '    ')
		src = src.replace(/XXXCODE_SPACEXXX/g, ' ')
		// console.log('AFT: ', src)

		return src
	}
}, 0)

// set the following to true to debug latex
U.verbose_latex = false

// partial results of experiments with other rendering systems are still here...
// U.latex_rendering_system = 'katex'
// U.latex_rendering_system = 'mathjax'
// U.latex_rendering_system = 'mathlive-delayed'
U.latex_rendering_system = 'mathlive'

U.inject_mathlive_styles = function() {
	// call this when the app is initialized; we do it this way so that we can also include the styles in print view
	// this is modeled on the `injectStylesheet` fn in https://github.com/arnog/mathlive/blob/master/src/common/stylesheet.ts
	// we have a static version of the MathLive core css file in `mathlive_core_css.js` (included in main.js), set to U.mathlive_core_css
	// (adapted from https://github.com/arnog/mathlive/blob/49f11e27e44cb71f02fbcd99336199e41335a1c7/css/core.less)
	const styleNode = window.document.createElement('style');
    styleNode.id = `mathlive-style-core`;
    styleNode.append(window.document.createTextNode(U.mathlive_core_css));
    window.document.head.appendChild(styleNode);
}

U.render_latex = function(s, from_preview=false, latex_alt_text_object) {
	// skip expensive regexp if no latex
	if (!s.includes('$')) return s

	let original_s = s
	// by convention, latex is coded like this:
	// For example, we define $5^{(1/3)}$ to be the cube root of 5 because we want $[5^{(1/3)}]^3$ = $5^{[(1/3) x 3]}$ to hold, so $[5^{(1/3)}]^3$ must equal 5.
	s = '[' + s + ']'
	// s = s.replace(/([\s({\[>]|\&nbsp;)\$(\S([^$]*?\S)?)\$([\s)}\].,;:%!?<]|\&nbsp;)/g, ($0, $1, $2, $3, $4) => {
	// s = s.replace(/([\s({\[>]|\&nbsp;)\$(\S([^$]*?\S)?)\$([\s)}\].,;:%!?<-]|\&nbsp;)/g, ($0, $1, $2, $3, $4) => {
	s = s.replace(/([\s({\[>]|\/|\&nbsp;)\$(\S([^$]*?\S)?)\$([\s)}\].,;:%!?<\u2014-]|\/|\&nbsp;)/g, ($0, $1, $2, $3, $4) => {		// 8/14/2024 -- allow for / after/between latex strings
		// note that we search for $ followed immediately by a non-space char at the start, and a non-space char followed immediately by $ at the end
		// also there needs to be a space, >, or an opening paren, bracket, or brace before the opening $, then a space, <, a closing paren, bracket, or brace, or a punctuation mark after the closing $
		// (this is why we add [] around the full string at the start and take the [] back out below; this makes the fn work if the string has a LaTeX formula at the start or end of the string)
		// we render everything between the two $'s, and replace everything including the $'s with the rendered html

		let ks
		if (U.latex_rendering_system == 'mathjax') {
			// let mathjax_options = {em: 12, ex: 6, display: false, containerWidth:100, scale:20}	// https://docs.mathjax.org/en/latest/web/typeset.html
			let mathjax_options = {display: false}	// https://docs.mathjax.org/en/latest/web/typeset.html
			ks = MathJax.tex2svg($2, mathjax_options)
			if (ks) ks = ks.outerHTML	// MathJax will return DOM

			// get a "clearspeak" translation of the equation for screenreaders
			ks = U.clearspeak_span($2, ks)

			return $1 + ks + $4

		} else if (U.latex_rendering_system == 'mathlive-delayed') {
			// ks = `<span data-latex="${$2}$" aria-hidden="true" translate="no" style="display: inline-flex;">${MathLive.convertLatexToMarkup($2)}</span>`
			// ks = MathLive.convertLatexToMarkup($2)
			ks = `\\(${$2}\\)`

			// get a "clearspeak" translation of the equation for screenreaders
			ks = U.clearspeak_span($2, ks)

			return $1 + ks + $4

		} else {
			if (U.verbose_latex) console.log($2)

			// dollar signs within equations need to elaborately coded so we find them properly; do a transform and then un-transform here to find them properly
			// This LaTeX formula includes dollar signs: $&amp;dollar;33 \times 2 = &amp;dollar;66$
			$2 = $2.replace(/&amp;dollar;/g, '\\textnormal{DXD}')

			// replace other &amp;'s with &
			$2 = $2.replace(/&amp;/g, '&')

			// replace &lt;/$gt;/etc. with latex codes
			$2 = $2.replace(/&(lt|gt|le|ge|ne);/g, '\\$1')

			// take out \left and \right (?)
			$2 = $2.replace(/\\(left|right)\b/g, '')

			// MathLive wants "textrm" instead of "rm"
			$2 = $2.replace(/\\rm/g, '\\textrm')

			// replace newlines? currently seems that this isn't necessary
			// $2 = $2.replace(/\\\\/g, '\\newline')

			// matrices
			$2 = $2.replace(/\[ *\{\\begin\{array\}\{\*\{20}\{c\}\}(.*?)\\end\{array\}\} *\]/g, '\\begin{bmatrix}$1\\end{bmatrix}')
			$2 = $2.replace(/\\begin\{array\}\{\*\{20}\{c\}\}(.*?)\\end\{array\}/g, '\\begin{matrix}$1\\end{matrix}')

			if (U.verbose_latex) console.log($2)
			if (U.verbose_latex) console.log('------')

			let span_attributes = ''
			if (U.latex_rendering_system == 'mathlive') {
				ks = MathLive.convertLatexToMarkup($2, {mathstyle: 'textstyle'})	// mathstyle:'textstyle' uses 'inline' mode; alternative is 'displaystyle'
				// if we're using this method, add additional span_attributes when we generate the clearspeak_span
				span_attributes = `data-latex="${$2}" class="k-mathlive-span" aria-hidden="true" translate="no" style="display: inline-flex;"`
				// ks = `<span data-latex="${$2}" class="k-mathlive-span" aria-hidden="true" translate="no" style="display: inline-flex;">${ks}</span>`
				// ks = `<span class="k-mathlive-span" aria-hidden="true" translate="no">${ks}</span>`
			} else {
				ks = window.katex.renderToString($2, {throwOnError: false})
			}

			// we have to replace with the entity, instead of $, so that we don't re-process it below
			ks = ks.replace(/<span class="mord textrm">DXD<\/span>/g, '&dollar;')

			// get a "clearspeak" translation of the equation for screenreaders
			ks = U.clearspeak_span($2, ks, span_attributes, '', latex_alt_text_object)

			return $1 + ks + $4
		}
	})

	s = s.replace(/^\[([\s\S]*)\]$/, '$1')
	if (from_preview && Object.keys(latex_alt_text_object).length > 0) {
		const rendered_keys_object = Object.keys(latex_alt_text_object).reduce((acc, key) => {
			const temp = document.createElement('div');
			temp.innerHTML = MathLive.convertLatexToMarkup(key, {mathstyle: 'textstyle'})
			const text = temp.textContent
			acc[text] = latex_alt_text_object[key];
			return acc;
		}, {});
		const div = document.createElement('div');
		div.innerHTML = s;

		// Find all the LaTeX spans 
		const latex_spans = div.querySelectorAll('.k-mathlive-span');

		// Add an asterisk before each LaTeX span
		latex_spans.forEach(span => {
			const asterisk = document.createElement('span');
			if (span.textContent in rendered_keys_object) {
				asterisk.textContent = '*'; // Add the asterisk
				span.parentNode.insertBefore(asterisk, span); // Insert the asterisk before the LaTeX span
			}
		});
		const info_div = document.createElement('div');
		info_div.textContent = "* means alt_text has been modified"; // The message
		div.appendChild(info_div);
		s = div.innerHTML
	}

	// if we made a change and there is still a $ in the string, we may need to run through the re again, so try that here
	// e.g. `$x = 0.4444444\ldots$ $9x = 4\ldots$`
	if (s != original_s && s.indexOf('$') > -1) {
		s = U.render_latex(s)
	}

	return s
}

U.clearspeak_text = function(latex_string) {
	// have to take out the 'displaystyle' 
	latex_string = latex_string.replace(/^\\displaystyle{(.*)}$/, '$1')
	// return U.mathjax.generate_clearspeak(latex_string)		// mathjax format
	return MathLive.convertLatexToSpeakableText(latex_string)
}

U.clearspeak_span = function(latex_string, rendered_string, span_attributes, latex_alt_text, latex_alt_text_object) {
	let cs = latex_alt_text ? latex_alt_text : U.clearspeak_text(latex_string)
	if (latex_alt_text_object && Object.keys(latex_alt_text_object).length > 0) {
		// NOTE: THIS IS ONLY WORKING IF U.latex_rendering_system == mathlive 
		// mutate object to be markup string, this seems to be cleaner than escaping the latex string
		const rendered_keys_object = Object.keys(latex_alt_text_object).reduce((acc, key) => {
			const temp = document.createElement('div');
			temp.innerHTML = MathLive.convertLatexToMarkup(key, {mathstyle: 'textstyle'})
			const text = temp.textContent
			acc[text] = latex_alt_text_object[key];
			return acc;
		}, {});
		const temp = document.createElement('div');
		temp.innerHTML = rendered_string;
		const text = temp.textContent;
		if (text && text in rendered_keys_object) {
			cs = rendered_keys_object[text]
		}
	}

	// transforms on clearspeak output
	// cs = cs.replace(/ backslash /g, ' ')	// when MathJax fails to render things, sometimes it will just print out " backslash degree"

	if (U.verbose_latex) console.log('clearspeak: ' + cs)

	// add additional span_attributes if provided
	if (!empty(span_attributes)) span_attributes = ' ' + span_attributes
	else span_attributes = ''

	// // adding a comma to the start and the end is necessary because otherwise, the screen reader will often ignore the initial letter in the equation (e.g. it will read “${g_n}$” as "sub n", skipping the "g")
	// return `<span aria-label=" , ${cs} , ">${rendered_string}</span>`
	return `<span aria-label="${cs}"${span_attributes}>${rendered_string}</span>`
}

U.preserve_latex = function(s) {
	if (U.latex_rendering_system == 'mathjax') return s

	// skip expensive regexp if no latex
	if (!s.includes('$')) return s

	// we have to preserve some special characters that marked will otherwise mess up
	s = '[' + s + ']'
	s = s.replace(/([\s({\[>])\$(\S([^$]*?\S)?)\$([\s)}\].,;:%!?<^\u2014])/g, ($0, $1, $2, $3, $4) => {
		// $2 is the LaTeX formula itself
		$2 = $2.replace(/\^/g, 'KKCARKK')
		$2 = $2.replace(/ /g, 'KKXXKK')
		$2 = $2.replace(/\\/g, 'KKBSKK')
		$2 = $2.replace(/\&/g, 'KKAMPKK')
		$2 = $2.replace(/\*/g, 'KKASTKK')
		$2 = $2.replace(/\_/g, 'KKUNDKK')
		$2 = $2.replace(/\~/g, 'KKTILKK')
		return $1 + '$' + $2 + '$' + $4
	})
	s = s.replace(/^\[([\s\S]*)\]$/, '$1')
	/**
	 *  Replace < and > with their respective HTML codes to prevent visual bugs.
	 *  For example, This could cause issues when trying to render
	 *  *is less than* (<), *is greater than* (>)
	 */
	s = s.replace(/<(?=[^a-zA-Z\/])/g, 'KKKltKKK');
	return s
}

U.preserve_latex_reverse = function(s) {
	if (U.latex_rendering_system == 'mathjax') return s

	// skip expensive regexps if nothing was preserved
	if (!s.includes('KK')) return s
	
	s = s.replace(/KKTILKK/g, '~')
	s = s.replace(/KKCARKK/g, '^')
	s = s.replace(/KKUNDKK/g, '_')
	s = s.replace(/KKASTKK/g, '*')
	s = s.replace(/KKAMPKK/g, '&')
	s = s.replace(/KKBSKK/g, '\\')
	s = s.replace(/KKKltKKK/g, '<')
	return s.replace(/KKXXKK/g, ' ')
}

U.marked_latex = function(s, from_preview=false, latex_alt_text_object={}) {
	if (empty(s)) return s

	// if we're currently viewing a framework in wiki_mode_view, do wiki variable substitutions
	if (vapp.case_tree_component?.wiki_mode_view) {
		s = vapp.case_tree_component.wiki_variable_substitutions(s)
	}
	
	// preserve latex; then do marked, then unpreserve and render latex
	if (U.verbose_latex) console.log('original: ' + s)
	s = U.preserve_latex(s)
	if (U.verbose_latex) console.log('preserve: ' + s)
	s = marked(s)
	if (U.verbose_latex) console.log('  marked: ' + s)

	s = U.preserve_latex_reverse(s)
	if (U.verbose_latex) console.log('reversed: ' + s)
	s = U.render_latex(s, from_preview, latex_alt_text_object)
	if (U.verbose_latex) console.log('=================')
	return s
}

// return a sortable numeric value for grade labels, which can be "g-K", "g-1", etc.
U.grade_val = function(g) {
	if (g == 'g-K') return 0
	return g.substr(2) * 1
}

// formats seconds into "hh:mm:ss", with leading zeros inserted as appropriate and hours optional
// if return_seconds is explicitly set to false, seconds won't be added and hours will always be added (0 if necessary)
U.time_string = function(seconds, return_seconds) {
	seconds = Math.round(seconds)
	let hours = Math.floor(seconds / 3600)
	let minutes = Math.floor((seconds - hours*3600) / 60)
	seconds = seconds % 60

	let ts
	if (return_seconds === false) {
		if (minutes < 10) minutes = '0' + minutes
		ts = sr('$1:$2', hours, minutes)

	} else {
		if (seconds < 10) seconds = '0' + seconds
		ts = sr('$1:$2', minutes, seconds)
		if (hours > 0) {
			if (minutes < 10) ts = '0' + ts
			ts = sr('$1:$2', hours, ts)
		}
	}

	return ts
}

U.convert_to_local_date = function(date_to_convert, offset_adjustment) {
	// if incoming date is a string, assume a mysql date string or lastChangeDateTime string; convert to a Date object
	if (typeof(date_to_convert) == 'string') {
		if (date_to_convert.includes('T')) {
			date_to_convert = new Date(date_to_convert)
		} else {
			date_to_convert = date.parse(date_to_convert, 'YYYY-MM-DD HH:mm:ss')
		}
	}

	// if offset_adjustment is -1, just return the date as-is
	if (offset_adjustment == -1) return date_to_convert

	// else we assume the date was GMT, and adjust from there

	// if offset_adjustment is not supplied, look in store for convert_to_local_date_offset_adjustment (300 for GMT - EST)
	if (empty(offset_adjustment)) {
		offset_adjustment = vapp.$store.state.convert_to_local_date_offset_adjustment
		if (empty(offset_adjustment)) offset_adjustment = 0
	}

	// now determine the offset to use to correct the date: the difference between the client TZ and GMT, minus offset_adjustment
	let offset = new Date().getTimezoneOffset() - offset_adjustment
	date_to_convert.setMinutes(date_to_convert.getMinutes() - offset)
	return date_to_convert

	// if caller wants to convert back to a string:
	// let ds = date.format(U.convert_to_local_date(gmt_date), 'MMM D, YYYY h:mm A')	// Jan 1, 2019 3:12 PM
}

// generates RFC-compliant GUIDs
// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
// this is "e7" of Jeff Ward's answer
U.guid_lut = []; for (var i=0; i<256; i++) { U.guid_lut[i] = (i<16?'0':'')+(i).toString(16); }
U.new_uuid = function() {
	var d0 = Math.random()*0xffffffff|0;
	var d1 = Math.random()*0xffffffff|0;
	var d2 = Math.random()*0xffffffff|0;
	var d3 = Math.random()*0xffffffff|0;
	return U.guid_lut[d0&0xff]+U.guid_lut[d0>>8&0xff]+U.guid_lut[d0>>16&0xff]+U.guid_lut[d0>>24&0xff]+'-'+
		U.guid_lut[d1&0xff]+U.guid_lut[d1>>8&0xff]+'-'+U.guid_lut[d1>>16&0x0f|0x40]+U.guid_lut[d1>>24&0xff]+'-'+
		U.guid_lut[d2&0x3f|0x80]+U.guid_lut[d2>>8&0xff]+'-'+U.guid_lut[d2>>16&0xff]+U.guid_lut[d2>>24&0xff]+
		U.guid_lut[d3&0xff]+U.guid_lut[d3>>8&0xff]+U.guid_lut[d3>>16&0xff]+U.guid_lut[d3>>24&0xff];
}

// checks to see if s has the basic form of a uuid
U.is_uuid = function(s) {
	// 97c883b4-8590-454f-b222-f28298ec9a81
	return s.search(/^[0123456789abcdef]{8}-[0123456789abcdef]{4}-[0123456789abcdef]{4}-[0123456789abcdef]{4}-[0123456789abcdef]{12}$/i) > -1
}

U.is_letter = function(char) {
	return ('ABCDEFGHIJKLMNOPQRSTUVWXYZ'.indexOf(char) > -1)
}

// utility fn to convert a "coded" numeric query answer to its numeric value (e.g. '1/4' => .25)
U.decode_numeric_query_answer = function(s) {
	let arr = s.split('/')
	if (arr.length == 1) {
		return s * 1
	}

	return arr[0] / arr[1]
}

// utility fn to "encode" a number into the format used for numeric queries (?)
U.encode_numeric_query_answer = function(n) {
	// TODO: deal with symbols...
	let s = n + ''

	return s
}

// utility fn to determine if a string is a valid correct_answer for a numeric query
U.query_answer_is_numeric = function(s) {
	return !isNaN(this.decode_numeric_query_answer(s))
}

U.numeric_display_html = function(s) {
	s = s + ''

	// convert hyphen surrounded by spaces to n-dash
	s = s.replace(/ - /g, ' – ')

	// convert * to times
	s = s.replace(/\*/g, '×')

	// italicize letters
	s = s.replace(/(^|[^$])([a-zA-Z])/g, '$1<i>$2<xi>')

	// convert fractions
	let arr = s.split('/')
	if (arr.length == 2) {
		s = sr('<span class="k-fraction-wrapper"><span class="k-fraction-numerator">$1</span><span class="k-fraction-denominator">$2</span></span>', arr[0], arr[1])
	}

	s = s.replace(/xi/g, '/i')

	s = sr('<span class="k-math">$1</span>', s)

	return s
}

// utility fn to determine the number of places for a numeric value
U.num_places = function(n) {
	if (isNaN(n*1)) return 0

	n = '' + n
	if (n.search(/\.(\d+)$/) > -1) {
		return RegExp.$1.length
	}
	return 0
}

// utilities for using local storage to save and retrieve settings
U.local_storage_set = function(key, val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	// can't set to localStorage in safari incognito mode
	try {
		window.localStorage.setItem(key, JSON.stringify(val));
	} catch(e) {
		console.log('error in local_storage_set', e)
	}
}

U.local_storage_clear = function(key) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	window.localStorage.removeItem(key);
}

U.local_storage_get = function(key, default_val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	let val = window.localStorage.getItem(key)
	if (!empty(val)) {
		try {
			val = JSON.parse(val);
		} catch(e) {
			console.log('error parsing JSON in local_storage_get: ' + val)
			val = ''
		}
	}

	if (empty(val)) {
		return default_val
	} else {
		// do some type checking
		if (default_val === true || default_val === false) {
			if (val === 'true') val = true
			if (val === 'false') val = false
			if (val != true && val != false) {
				throw new Error(sr('Boolean argument expected for key $1; $2 received', key, val))
			}

		} else if (typeof(default_val) == 'number') {
			if (isNaN(val*1)) {
				throw new Error(sr('Numeric argument expected for key $1; $2 received', key, val))
			}
			val = val * 1

		} else if (typeof(default_val) == 'string') {
			if (typeof(val) != 'string') {
				throw new Error(sr('String argument expected for key $1; $2 received', key, val))
			}
			val = val + ''
		}
		return val
	}
}

U.cookie_set = function(key, val, use_prefix = true) {
	// use a consistent prefix for every key
	if (use_prefix) key = 'pwet__' + key

	document.cookie = sr('$1=$2', key, val)
}

U.cookie_clear = function(key, use_prefix = true) {
	// use a consistent prefix for every key
	if (use_prefix) key = 'pwet__' + key

	document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
}

U.cookie_get = function(key, default_val, use_prefix = true) {
	// use a consistent prefix for every key
	if (use_prefix) key = 'pwet__' + key

	let cookies = document.cookie.split(';')
	for (let c of cookies) {
		let arr = c.split('=')
		if ($.trim(arr[0]) == key) {
			let val = arr[1]
			// do some type checking
			if (default_val === true || default_val === false) {
				if (val === 'true') val = true
				if (val === 'false') val = false
				if (val != true && val != false) {
					throw new Error(sr('Boolean argument expected for key $1; $2 received', key, val))
				}

			} else if (typeof(default_val) == 'number') {
				if (isNaN(val*1)) {
					throw new Error(sr('Numeric argument expected for key $1; $2 received', key, val))
				}
				val = val * 1

			} else if (typeof(default_val) == 'string') {
				if (typeof(val) != 'string') {
					throw new Error(sr('String argument expected for key $1; $2 received', key, val))
				}
				val = val + ''
			}
			return val
		}
	}
	// if not found, return default_val
	return default_val
}

U.meta_or_alt_key = function(evt) {
	// key on metaKey ("command") for macos and altKey ("alt") for windows ("alt" on mac keyboard *isn't* what we want)
	if (!evt) return false	// shouldn't happen
	if (window.navigator.platform.indexOf('Win') > -1 && evt.altKey === true) return true
	if (window.navigator.platform.indexOf('Mac') > -1 && evt.metaKey === true) return true
	return false
}

U.meta_or_alt_key_noun = function() {
	// sr('Hold down the $1 key while you click…', U.meta_or_alt_key_noun())
	if (window.navigator.platform.indexOf('Mac') > -1) return 'COMMAND'
	return 'ALT'
}

U.meta_or_alt_key_symbol = function() {
	// sr('Hold down the $1 key while you click…', U.meta_or_alt_key_symbol())
	if (window.navigator.platform.indexOf('Mac') > -1) return '⌘'
	return 'ALT'
}

// Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)
// Returns a function, that, as long as it continues to be invoked, will not be triggered.
// The function will be called after it stops being called for N milliseconds.
// If `immediate` is passed, trigger the function on the leading edge, instead of the trailing.
// To call:
	// // establish the debounce fn if necessary
	// if (empty(this.fn_debounced)) {
	// 	this.fn_debounced = U.debounce(function(x) {
	// 		...
	// 	}, 1000)
	// }
	// // call the debounce fn
	// this.fn_debounced(x)
U.debounce = function(func, wait, immediate) {
  	var timeout

  	// This is the function that is actually executed when the DOM event is triggered.
  	return function executedFunction() {
    	// Store the context of this and any parameters passed to executedFunction
    	var context = this
    	var args = arguments

    	// The function to be called after the debounce time has elapsed
    	var later = function() {
      		// null timeout to indicate the debounce ended
      		timeout = null

      		// Call function now if you did not on the leading end
      		if (!immediate) func.apply(context, args)
    	}

    	// Determine if you should call the function on the leading or trail end
    	var callNow = immediate && !timeout

    	// This will reset the waiting every function execution. This is the step that prevents the function
    	// from being executed because it will never reach the inside of the previous setTimeout
    	clearTimeout(timeout)

    	// Restart the debounce waiting period. setTimeout returns a truthy value (it differs in web vs node)
    	timeout = setTimeout(later, wait)

    	// Call immediately if you're dong a leading end execution
    	if (callNow) func.apply(context, args)
  	}
}

/*
seedrandom

// Make a predictable pseudorandom number generator.
// AS OF 6/2021, the below fn doesn't work; use `npm i seedrandom` with this in main.js:
// import seedrandom from 'seedrandom'
// Math.seedrandom = seedrandom

var myrng = new Math.seedrandom('hello.');
console.log(myrng());                // Always 0.9282578795792454
console.log(myrng());                // Always 0.3752569768646784
*/
// !function(f,a,c){var s,l=256,p="random",d=c.pow(l,6),g=c.pow(2,52),y=2*g,h=l-1;function n(n,t,r){function e(){for(var n=u.g(6),t=d,r=0;n<g;)n=(n+r)*l,t*=l,r=u.g(1);for(;y<=n;)n/=2,t/=2,r>>>=1;return(n+r)/t}var o=[],i=j(function n(t,r){var e,o=[],i=typeof t;if(r&&"object"==i)for(e in t)try{o.push(n(t[e],r-1))}catch(n){}return o.length?o:"string"==i?t:t+"\0"}((t=1==t?{entropy:!0}:t||{}).entropy?[n,S(a)]:null==n?function(){try{var n;return s&&(n=s.randomBytes)?n=n(l):(n=new Uint8Array(l),(f.crypto||f.msCrypto).getRandomValues(n)),S(n)}catch(n){var t=f.navigator,r=t&&t.plugins;return[+new Date,f,r,f.screen,S(a)]}}():n,3),o),u=new m(o);return e.int32=function(){return 0|u.g(4)},e.quick=function(){return u.g(4)/4294967296},e.double=e,j(S(u.S),a),(t.pass||r||function(n,t,r,e){return e&&(e.S&&v(e,u),n.state=function(){return v(u,{})}),r?(c[p]=n,t):n})(e,i,"global"in t?t.global:this==c,t.state)}function m(n){var t,r=n.length,u=this,e=0,o=u.i=u.j=0,i=u.S=[];for(r||(n=[r++]);e<l;)i[e]=e++;for(e=0;e<l;e++)i[e]=i[o=h&o+n[e%r]+(t=i[e])],i[o]=t;(u.g=function(n){for(var t,r=0,e=u.i,o=u.j,i=u.S;n--;)t=i[e=h&e+1],r=r*l+i[h&(i[e]=i[o=h&o+t])+(i[o]=t)];return u.i=e,u.j=o,r})(l)}function v(n,t){return t.i=n.i,t.j=n.j,t.S=n.S.slice(),t}function j(n,t){for(var r,e=n+"",o=0;o<e.length;)t[h&o]=h&(r^=19*t[h&o])+e.charCodeAt(o++);return S(t)}function S(n){return String.fromCharCode.apply(0,n)}if(j(c.random(),a),"object"==typeof module&&module.exports){module.exports=n;try{s=require("crypto")}catch(n){}}else"function"==typeof define&&define.amd?define(function(){return n}):c["seed"+p]=n}("undefined"!=typeof self?self:this,[],Math);

// https://stackoverflow.com/questions/1293147/javascript-code-to-parse-csv-data
window.CSV = {
	parse: function(csv, reviver) {
	    reviver = reviver || function(r, c, v) { return v; };
	    var chars = csv.split(''), c = 0, cc = chars.length, start, end, table = [], row;
	    while (c < cc) {
	        table.push(row = []);
	        while (c < cc && '\r' !== chars[c] && '\n' !== chars[c]) {
	            start = end = c;
	            if ('"' === chars[c]){
	                start = end = ++c;
	                while (c < cc) {
	                    if ('"' === chars[c]) {
	                        if ('"' !== chars[c+1]) { break; }
	                        else { chars[++c] = ''; } // unescape ""
	                    }
	                    end = ++c;
	                }
	                if ('"' === chars[c]) { ++c; }
	                while (c < cc && '\r' !== chars[c] && '\n' !== chars[c] && ',' !== chars[c]) { ++c; }
	            } else {
	                while (c < cc && '\r' !== chars[c] && '\n' !== chars[c] && ',' !== chars[c]) { end = ++c; }
	            }
	            row.push(reviver(table.length-1, row.length, chars.slice(start, end).join('')));
	            if (',' === chars[c]) { ++c; }
	        }
	        if ('\r' === chars[c]) { ++c; }
	        if ('\n' === chars[c]) { ++c; }
	    }
	    return table;
	},

	stringify: function(table, replacer) {
	    replacer = replacer || function(r, c, v) { return v; };
	    var csv = '', c, cc, r, rr = table.length, cell;
	    for (r = 0; r < rr; ++r) {
	        if (r) { csv += '\r\n'; }
	        for (c = 0, cc = table[r].length; c < cc; ++c) {
	            if (c) { csv += ','; }
	            cell = replacer(r, c, table[r][c]);
	            if (/[,\r\n"]/.test(cell)) { cell = '"' + cell.replace(/"/g, '""') + '"'; }
	            csv += (cell || 0 === cell) ? cell : '';
	        }
	    }
	    return csv;
	}
};

// simple TSV equivalent to CSV fns above
window.TSV = {
	parse: function(tsv) {
		let lines = tsv.split('\n')
		for (let i = 0; i < lines.length; ++i) {
			let line = $.trim(lines[i])

			if (empty(line)) {
				lines[i] = []
			} else {
				lines[i] = line.split('\t')
			}
		}
		return lines
	},

	stringify:function(lines) {
		let tsv = ''
		for (let i = 0; i < lines.length; ++i) {
			tsv += lines[i].join('\t') + '\n'
		}
		return tsv
	}
};

U.normalize_string_for_sparkl_bot = function(s) {
	if (empty(s)) return ''

	// remove html
	s = s.replace(/<[\w|\/].*?>/g, ' ')

	// remove entities
	s = s.replace(/\&\w+;/g, ' ');

	// normalize single-quotes and remove single-quotes that aren't contractions
	s = s.replace(/[‘’]/g, "'")
	s = s.replace(/'\s/g, ' ')
	s = s.replace(/\s'/g, ' ')

	// remove all chars except letters, numbers, and basic punctuation (see list in regex) (?)
	s = s.replace(/[^\w'().,;:?!]/g, ' ')

	// collapse multiple spaces and trim
	s = $.trim(s.replace(/\s+/g, ' '))

	// use lower case for everything (?)
	s = s.toLowerCase()
	return s
}

// SB sim_score will generally be between 0-1000, but might be outside this range; standardize to a value between 0 and 100
U.normalize_sim_score = function(sim_score) {
	if (sim_score < 0) return 0
	if (sim_score > 1000) return 1000
	return Math.round(sim_score / 10)
}

// https://github.com/trekhleb/javascript-algorithms/blob/master/src/algorithms/string/longest-common-substring/longestCommonSubstring.js
U.longest_common_substring = function(string1, string2) {
	// Convert strings to arrays to treat unicode symbols length correctly.
	// For example:
	// '𐌵'.length === 2
	// [...'𐌵'].length === 1
	const s1 = [...string1];
	const s2 = [...string2];

	// Init the matrix of all substring lengths to use Dynamic Programming approach.
	const substringMatrix = Array(s2.length + 1).fill(null).map(() => {
		return Array(s1.length + 1).fill(null);
	});

	// Fill the first row and first column with zeros to provide initial values.
	for (let columnIndex = 0; columnIndex <= s1.length; columnIndex += 1) {
		substringMatrix[0][columnIndex] = 0;
	}

	for (let rowIndex = 0; rowIndex <= s2.length; rowIndex += 1) {
		substringMatrix[rowIndex][0] = 0;
	}

	// Build the matrix of all substring lengths to use Dynamic Programming approach.
	let longestSubstringLength = 0;
	let longestSubstringColumn = 0;
	let longestSubstringRow = 0;

	for (let rowIndex = 1; rowIndex <= s2.length; rowIndex += 1) {
		for (let columnIndex = 1; columnIndex <= s1.length; columnIndex += 1) {
			if (s1[columnIndex - 1] === s2[rowIndex - 1]) {
				substringMatrix[rowIndex][columnIndex] = substringMatrix[rowIndex - 1][columnIndex - 1] + 1;
			} else {
				substringMatrix[rowIndex][columnIndex] = 0;
			}

			// Try to find the biggest length of all common substring lengths
			// and to memorize its last character position (indices)
			if (substringMatrix[rowIndex][columnIndex] > longestSubstringLength) {
				longestSubstringLength = substringMatrix[rowIndex][columnIndex];
				longestSubstringColumn = columnIndex;
				longestSubstringRow = rowIndex;
			}
		}
	}

	if (longestSubstringLength === 0) {
		// Longest common substring has not been found.
		return '';
	}

	// Detect the longest substring from the matrix.
	let longestSubstring = '';

	while (substringMatrix[longestSubstringRow][longestSubstringColumn] > 0) {
		longestSubstring = s1[longestSubstringColumn - 1] + longestSubstring;
		longestSubstringRow -= 1;
		longestSubstringColumn -= 1;
	}

	return longestSubstring;
}

// using the longest_common_substring fn above, get a decimal value (0.0-1.0) that reflects the amount of overlap between the two strings, taking the lengths of the strings into account
U.get_lcs_val = function(s1, s2) {
	if (empty(s1) || empty(s2)) return 0

	let lcs = U.longest_common_substring(s1, s2)

	// calculate % of the *harmonic mean* of the lengths of the left and right statements, then multiply by comp_factor_value
	// so if they're identical, we get 100%; if nothing matches we get 0%; harmonic mean is closer to the lower value than the upper value
	let lcs_den = 2 * s1.length * s2.length / (s1.length + s2.length)

	return lcs.length / lcs_den
}

U.download_file = function(data, filename) {
	// this works for a csv or a tsv; probably also for other data types
	// slightly hacky solution from here: https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
	// 3/7/2023 adde "Byte Order Marker" (BOM) so unicode characters open properly in excel - https://stackoverflow.com/questions/19492846/javascript-to-csv-export-encoding-issue
	var data_str = 'data:text/json;charset=utf-8,%EF%BB%BF' + encodeURIComponent(data)
	var download_anchor_node = document.createElement('a')
	download_anchor_node.setAttribute('href', data_str)
	// set filename of downloaded file
	download_anchor_node.setAttribute('download', filename)
	document.body.appendChild(download_anchor_node) // required for firefox
	download_anchor_node.click()
	download_anchor_node.remove()
}

U.download_json_file = function(json, filename) {
	// slightly hacky solution from here: https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
	let dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json))
	let downloadAnchorNode = document.createElement('a')
	downloadAnchorNode.setAttribute('href', dataStr)
	// set filename of downloaded json file
	downloadAnchorNode.setAttribute('download', sr('$1.json', filename))
	document.body.appendChild(downloadAnchorNode) // required for firefox
	downloadAnchorNode.click()
	downloadAnchorNode.remove()
}

// compress an image selected by the user from the filesystem (or a picture taken with a phone camera) client-side, and return the dataURL that represents the image
U.create_image_data_url = function(image_file, params) {
	// console.log('create_image_data_url: ' + params.max_width)
	// params: max_width is required; max_height is optional
	let max_width = params.max_width
	let max_height = params.max_height
	// required callback fn to receive the image data_url and placeholder
	let callback_fn = params.callback_fn

	// 0.9 = slightly compressed jpg
	let compression_level = dv(params.compression_level, 0.9)

	let image_format = dv(params.image_format, 'webp')	// jpeg, webp, or png; png is the only one that's required for browsers to support...

	// create a canvas element to do the conversion
	let canvas = document.createElement("canvas")

	// load the image into memory using a FileReader to get the image size
	let reader = new FileReader()
	reader.onload = e => {
		let image = new Image()
		image.onload = e => {
			let natural_image_width = (image.naturalWidth) ? image.naturalWidth : image.width
			let natural_image_height = (image.naturalHeight) ? image.naturalHeight : image.height

			let scaled_image_width, scaled_image_height
			// if image is smaller than width/height, or if max_width is 'full', return as is
			if (max_width == 'full' || natural_image_width < max_width*1) {
				scaled_image_width = natural_image_width
				scaled_image_height = natural_image_height
			} else {
				// else start by scaling to make the image width fit in the max_height
				scaled_image_width = max_width
				scaled_image_height = natural_image_height / (natural_image_width / scaled_image_width)
			}

			// if we have max_height and this makes the height taller than max_height, scale to make the image height fit in the vertical space
			if (max_height && max_height != 'full' && scaled_image_height > max_height) {
				scaled_image_height = max_height
				scaled_image_width = natural_image_width / (natural_image_height / scaled_image_height)
			}

			// set the canvas width, then draw the image to the canvas
			canvas.width = scaled_image_width
			canvas.height = scaled_image_height
			canvas.getContext('2d').drawImage(image, 0, 0, scaled_image_width, scaled_image_height)

			// extract the image dataURL
			let img_url = canvas.toDataURL('image/' + image_format, compression_level)
			// console.log('img_url size:' + img_url.length)

			callback_fn({
				img_url: img_url,
				width: scaled_image_width,
				height: scaled_image_height,
				natural_width: natural_image_width,
				natural_height: natural_image_height,
			})
		}
		image.onerror = e => {
			console.log('IMAGE ONERROR!!', e)
			callback_fn({error: e, source:'image'})
		}
		image.src = e.target.result
	}
	reader.onerror = e => {
		console.log('READER ONERROR!', e)
		callback_fn({error: e, source:'reader'})
	}
	// trigger the FileReader to load the image file
	reader.readAsDataURL(image_file)
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
U.format_bytes = function(bytes, decimals) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = (!decimals || decimals < 0) ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
// https://stackoverflow.com/questions/14890994/javascript-c-style-type-cast-from-signed-to-unsigned
// not "secure", but works to reduce an arbitrary-length string to a unique unsigned integer value
U.hash_code = function(s) {
	if (!s) return 0

	var hash = 0,
	  i, chr;
	for (i = 0; i < s.length; i++) {
	  chr = s.charCodeAt(i);
	  hash = ((hash << 5) - hash) + chr;
	  hash |= 0; // Convert to 32bit integer
	}
	// return hash.toString(16)
	return (new Uint32Array([hash]))[0]
} 

// modified from:
// https://johnresig.com/projects/javascript-diff-algorithm/
U.diff_string = function ( o, n, flag ) {
	if (!flag) flag = ''
	function escape(s) {
		return s

		// var n = s;
		// n = n.replace(/&/g, "&amp;");
		// n = n.replace(/</g, "&lt;");
		// n = n.replace(/>/g, "&gt;");
		// n = n.replace(/"/g, "&quot;");

		// return n;
	}

	function preserve_latex(s, latex) {
		s = s.replace(/(\$[^$]+\$)/g, ($0, $1) => {
			latex.push($1)
			return ` XXXLATEX${U.hash_code($1)}XXX `
			// note that adding the spaces before and after each tag (then removing them below) are essential for not getting spurious things marked as changed around the latex (e.g. when the list formatting has changed)
		})
		return s
	}

	function restore_latex(s, latex) {
		for (let l of latex) {
			s = s.replace(/( ?)XXXLATEX\d+XXX( ?)/, l)
		}
		return s
	}

	function preserve_tags(s, tags) {
		s = s.replace(/(<[^>]+>)/g, ($0, $1) => {
			tags.push($1)
			return ' XXXTAGXXX '
			// note that adding the spaces before and after each tag (then removing them below) are essential for not getting spurious things marked as changed around the tags (e.g. when the list formatting has changed)
		})
		return s
	}

	function restore_tags(s, tags) {
		for (let tag of tags) {
			s = s.replace(/( ?)XXXTAGXXX( ?)/, tag)
		}
		return s
	}

	function preserve_non_alphanum(s, non_alphanum) {
		s = s.replace(/([^a-zA-Z0-9 ])/g, ($0, $1) => {
			non_alphanum.push($1)
			return ' XXXYYYXXX '
			// note that adding the spaces before and after each tag (then removing them below) are essential for not getting spurious things marked as changed around the non_alphanum (e.g. when the list formatting has changed)
		})
		return s
	}

	function restore_non_alphanum(s, non_alphanum) {
		for (let na of non_alphanum) {
			s = s.replace(/( ?)XXXYYYXXX( ?)/, na)
		}
		return s
	}

	function diff( o, n ) {
		var ns = {}
		var os = {}
	
		for ( var i = 0; i < n.length; i++ ) {
		  if (!ns.hasOwnProperty(n[i])) ns[ n[i] ] = { rows: [], o: null };
		  ns[ n[i] ].rows.push( i );
		}
	
		for ( var i = 0; i < o.length; i++ ) {
		  if (!os.hasOwnProperty(o[i])) os[ o[i] ] = { rows: [], n: null };
		  os[ o[i] ].rows.push( i );
		}
	
		for ( var i in ns ) {
		  if ( ns[i].rows.length == 1 && os.hasOwnProperty(i) && os[i].rows.length == 1 ) {
			n[ ns[i].rows[0] ] = { text: n[ ns[i].rows[0] ], row: os[i].rows[0] };
			o[ os[i].rows[0] ] = { text: o[ os[i].rows[0] ], row: ns[i].rows[0] };
		  }
		}
	
		for ( var i = 0; i < n.length - 1; i++ ) {
		  if ( n[i].text != null && n[i+1].text == null && n[i].row + 1 < o.length && o[ n[i].row + 1 ].text == null && 
			   n[i+1] == o[ n[i].row + 1 ] ) {
			n[i+1] = { text: n[i+1], row: n[i].row + 1 };
			o[n[i].row+1] = { text: o[n[i].row+1], row: i + 1 };
		  }
		}
	
		for ( var i = n.length - 1; i > 0; i-- ) {
		  if ( n[i].text != null && n[i-1].text == null && n[i].row > 0 && o[ n[i].row - 1 ].text == null && 
			   n[i-1] == o[ n[i].row - 1 ] ) {
			n[i-1] = { text: n[i-1], row: n[i].row - 1 };
			o[n[i].row-1] = { text: o[n[i].row-1], row: i - 1 };
		  }
		}
	
		return { o: o, n: n };
	}

	// if flag includes 'preserve_latex', do some fanciness to make sure latex doesn't get screwed up; 
	// if flag is 'preserve_tags', do some fanciness to make sure html tags don't get screwed up;
	// and if flag includes 'alphanum_only', do some fanciness so that we don't take into account non-alphanumeric chars, and don't include punctuation, when doing comparison
	// Note: when preserve_tags is on, we also don't mark any *changes* in tags
	let o_latex = [], n_latex = [], o_tags = [], n_tags = [], o_non_alphanum = [], n_non_alphanum = []
	if (flag.includes('preserve_latex')) {
		o = preserve_latex(o, o_latex)
		n = preserve_latex(n, n_latex)
	}
	
	if (flag.includes('preserve_tags')) {
		o = preserve_tags(o, o_tags)
		n = preserve_tags(n, n_tags)

		if (flag.includes('alphanum_only')) {
			o = preserve_non_alphanum(o, o_non_alphanum)
			n = preserve_non_alphanum(n, n_non_alphanum)
		}

	} else {
		if (flag.includes('alphanum_only')) {
			o = preserve_non_alphanum(o, o_non_alphanum)
			n = preserve_non_alphanum(n, n_non_alphanum)
		}

		// if comparing characters, preserve spaces in actual word(d), then split each character into a "word"
		if (flag.includes('char_compare')) {
			o = o.replace(/ /g, 'Z').split('').join('XXX XXX')
			n = n.replace(/ /g, 'Z').split('').join('XXX XXX')
		}

		// adding a space after leading tags fixes a problem with marking changes to the first word
		o = o.replace(/^(<[^>]+?>)/, '$1 ')
		n = n.replace(/^(<[^>]+?>)/, '$1 ')
	}
	
	o = o.replace(/\s+$/, '');
	n = n.replace(/\s+$/, '');

	var out = diff(o == "" ? [] : o.split(/\s+/), n == "" ? [] : n.split(/\s+/) );
  
	var oSpace = o.match(/\s+/g);
	if (oSpace == null) {
	  oSpace = ["\n"];
	} else {
	  oSpace.push("\n");
	}
	var nSpace = n.match(/\s+/g);
	if (nSpace == null) {
	  nSpace = ["\n"];
	} else {
	  nSpace.push("\n");
	}

	// keep track of whether or not there are any changes, excluding tags if the preserve_tags is on
	let differences_found = false
	var os = "";
	for (var i = 0; i < out.o.length; i++) {  
		if (out.o[i].text != null) {
			os += escape(out.o[i].text) + oSpace[i];
		} else {
			if (out.o[i].includes('XXXYYYXXX')) os += escape(out.o[i]) + oSpace[i];
			else os += '<span class="k-diff-old">' + escape(out.o[i]) + oSpace[i] + '</span>';
			if (!out.o[i].includes('XXXTAGXXX') && !out.o[i].includes('XXXYYYXXX')) differences_found = true
		}
	}
  
	var ns = "";
	for (var i = 0; i < out.n.length; i++) {
		if (out.n[i].text != null) {
			ns += escape(out.n[i].text) + nSpace[i];
		} else {
			if (out.n[i].includes('XXXYYYXXX')) ns += escape(out.n[i]) + nSpace[i]
			else ns += '<span class="k-diff-new">' + escape(out.n[i]) + nSpace[i] + '</span>';
			if (!out.n[i].includes('XXXTAGXXX') && !out.n[i].includes('XXXYYYXXX')) differences_found = true
		}
	}

	if (flag.includes('preserve_tags')) {
		os = restore_tags(os, o_tags)
		ns = restore_tags(ns, n_tags)
	}
	if (flag.includes('alphanum_only')) {
		os = restore_non_alphanum(os, o_non_alphanum)
		ns = restore_non_alphanum(ns, n_non_alphanum)
	}
	if (flag.includes('char_compare')) {
		os = os.replace(/XXX( ?)/g, '').replace(/Z/g, ' ')
		ns = ns.replace(/XXX( ?)/g, '').replace(/Z/g, ' ')
	}
	if (flag.includes('preserve_latex')) {
		os = restore_latex(os, o_latex)
		ns = restore_latex(ns, n_latex)
	}

	return { o : os , n : ns, differences_found: differences_found };
}

// string_similarity fns; also used in Sparkl
// return only the common text between o and n; for use in string_similarity fns below
U.common_text = function ( o, n ) {
	function diff( o, n ) {
		var ns = {}
		var os = {}
	
		for ( var i = 0; i < n.length; i++ ) {
		  if (!ns.hasOwnProperty(n[i])) ns[ n[i] ] = { rows: [], o: null };
		  ns[ n[i] ].rows.push( i );
		}
	
		for ( var i = 0; i < o.length; i++ ) {
		  if (!os.hasOwnProperty(o[i])) os[ o[i] ] = { rows: [], n: null };
		  os[ o[i] ].rows.push( i );
		}
	
		for ( var i in ns ) {
		  if ( ns[i].rows.length == 1 && os.hasOwnProperty(i) && os[i].rows.length == 1 ) {
			n[ ns[i].rows[0] ] = { text: n[ ns[i].rows[0] ], row: os[i].rows[0] };
			o[ os[i].rows[0] ] = { text: o[ os[i].rows[0] ], row: ns[i].rows[0] };
		  }
		}
	
		for ( var i = 0; i < n.length - 1; i++ ) {
		  if ( n[i].text != null && n[i+1].text == null && n[i].row + 1 < o.length && o[ n[i].row + 1 ].text == null && 
			   n[i+1] == o[ n[i].row + 1 ] ) {
			n[i+1] = { text: n[i+1], row: n[i].row + 1 };
			o[n[i].row+1] = { text: o[n[i].row+1], row: i + 1 };
		  }
		}
	
		for ( var i = n.length - 1; i > 0; i-- ) {
		  if ( n[i].text != null && n[i-1].text == null && n[i].row > 0 && o[ n[i].row - 1 ].text == null && 
			   n[i-1] == o[ n[i].row - 1 ] ) {
			n[i-1] = { text: n[i-1], row: n[i].row - 1 };
			o[n[i].row-1] = { text: o[n[i].row-1], row: i - 1 };
		  }
		}
	
		return { o: o, n: n };
	}

	o = $.trim(o)
	n = $.trim(n)
	
	var out = diff(o == "" ? [] : o.split(/\s+/), n == "" ? [] : n.split(/\s+/) );
  
	var oSpace = o.match(/\s+/g);
	if (oSpace == null) {
	  oSpace = [" "];
	} else {
	  oSpace.push(" ");
	}
	var nSpace = n.match(/\s+/g);
	if (nSpace == null) {
	  nSpace = [" "];
	} else {
	  nSpace.push(" ");
	}
  
	var os = "";
	for (var i = 0; i < out.o.length; i++) {  
		if (out.o[i].text != null) {
			os += out.o[i].text + oSpace[i];
		}
	}

	return $.trim(os)
}

// for fns below
U.clean_string_for_similarity = function(s) {
	s = s.toLowerCase()
	s = s.replace(/<.*?>/g, '')		// clear tags
	s = s.replace(/(\w+)/g, '$1 ')	// separate words
	s = s.replace(/\s+/g, ' ')		// collapse spaces
	s = $.trim(s)
	return s
}

U.string_similarity_words = function(s1, s2) {
	if (empty(s1) || empty(s2)) return 0

	// clean s1 and s2 for our process
	s1 = U.clean_string_for_similarity(s1)
	s2 = U.clean_string_for_similarity(s2)

	// get the common text between s1 and s2
	let ct = U.common_text(s1, s2)

	// if nothing in common, return 0
	if (!ct) return 0

	// split into words
	s1 = s1.split(' ')
	s2 = s2.split(' ')
	ct = ct.split(' ')

	// return the # of words in common divided by the number of words in the longer of the two strings
	// (if we use the shorter string length as the denominator, 'the ear' would have a similarity of 100 to 'ear'
	let den = (s1.length > s2.length) ? s1.length : s2.length

	// console.log(s1.join(' '))
	// console.log(ct.join(' '))
	// console.log(s2.join(' '))
	// console.log(sr('$1 / $2 = $3', ct.length, den, ct.length / den))

	return ct.length / den
}

// dot_product and cosine_similarity fn
U.dot_product = function(x, y) {
	function dotp_sum(a, b) { return a + b; }
	function dotp_times(a, i) { return x[i] * y[i]; }
	return x.map(dotp_times).reduce(dotp_sum, 0);
}
  
U.cosine_similarity = function(A,B) {
	return U.dot_product(A, B) / (Math.sqrt(U.dot_product(A,A)) * Math.sqrt(U.dot_product(B,B)));
}

// not currently used: created this when I was thinking about encoding each sparkl_bot vector as a string of unicode characters
U.decode_sparkl_bot_utf_vectors = function(strings) {
	// note that the constant below must be mirrored by the code on the server that translates vectors to utf characters

	let vectors = []
	for (let s of strings) {
		let vector = []
		for (let i = 0; i < s.length; ++i) {
			vector.push(s.charCodeAt(i) - 12000)
		}
		vectors.push(vector)
	}
	return vectors
}

U.avg = function(arr) {
	if (arr.length === 0) {
		return 0
	}

	return arr.reduce((a,b) => a + b, 0) / arr.length
}

U.shade_color = function(color, shade) {
	// if there is no shade, we don't want a shade, just the base color
	// Helper functions
	const hslToHex = (h, s, l) => {
		l /= 100;
		const a = s * Math.min(l, 1 - l) / 100;
		const f = n => {
			const k = (n + h / 30) % 12;
			const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
			return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and pad with zeros if needed
		};
		return `#${f(0)}${f(8)}${f(4)}`;
	}

	const hex_to_rgb = (color) => {
		color = color.replace(/^#/, '')
		// 3 character rgb probably won't happen
		if (color.length === 3) color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2]
		let [r, g, b] = color.match(/.{2}/g);
		// normalized RGB
		[r, g, b] = [parseInt(r, 16) / 255, parseInt(g, 16) / 255, parseInt(b, 16) / 255]
		return [r, g, b]
	}

	const [r, g, b] = hex_to_rgb(color)

	const max = Math.max(r, g, b)
	const min = Math.min(r, g, b);

	let h, s, l = (max + min) / 2;
	
	if(max == min){
		h = s = 0;
	} else {
		const d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch(max) {
			case r: h = (g - b) / d + (g < b ? 6 : 0); break;
			case g: h = (b - r) / d + 2; break;
			case b: h = (r - g) / d + 4; break;
		}
		h /= 6;
	}
	
	s = s*100;
	s = Math.round(s);
	l = l*100;
	l = Math.round(l);
	h = Math.round(360*h);

	let hex = ''
	if (shade === 'lighten-5') {
		// more towards 1 is lighter
		const weight = .9
		hex = hslToHex(h, s, l + (100 - l) * weight)
	} else if (shade === 'lighten-4') {
		// more towards 1 is darker
		const weight = .1
		hex = hslToHex(h, s, l * (1 - weight))
	} else if (shade === 'lighten-3') {
		// more towards 1 is darker
		const weight = .2
		hex = hslToHex(h, s, l * (1 - weight))
	} else if (shade === 'lighten-2') {
		// more towards 1 is darker
		const weight = .3
		hex = hslToHex(h, s, l * (1 - weight))
	} else if (shade === 'lighten-1') {
		// more towards 1 is darker
		const weight = .4
		hex = hslToHex(h, s, l * (1 - weight))
	} else if (shade === 'darken-1') {
		// more towards 1 is darker
		const weight = .6
		hex = hslToHex(h, s, l * (1 - weight))
	} else if (shade === 'darken-2') {
		// more towards 1 is darker
		const weight = .7
		hex = hslToHex(h, s, l * (1 - weight))
	} else if (shade === 'darken-3') {
		// more towards 1 is darker
		const weight = .8
		hex = hslToHex(h, s, l * (1 - weight))
	} else if (shade === 'darken-4') {
		// more towards 1 is darker
		const weight = .9
		hex = hslToHex(h, s, l * (1 - weight))
	}
	return hex.toUpperCase()
}

/**
 * Checks if framework records contain sandbox configurations
 * @param {Array} framework_records - Array of framework record objects
 * @returns {boolean} True if sandbox configuration exists
 * @throws {Error} If framework_records is not an array
 */
U.has_sandbox = function(current_framework_id) {
    if (!Array.isArray(vapp.$store.state.framework_records)) {
        throw new Error('Framework records must be an array');
    }

    return vapp.$store.state.framework_records.some(record => {
        return record?.ss_framework_data?.sandboxOfIdentifier && 
               current_framework_id === record.ss_framework_data.sandboxOfIdentifier;
    });
}


  
/*
date and time functions:
npm i date-and-time
https://github.com/knowledgecode/date-and-time

in main.js:
import date from 'date-and-time'
import 'date-and-time/plugin/meridiem';
date.plugin('meridiem')
window.date = date

to convert from timestamp:
date.format(new Date(this.entry.date_added*1000), 'MMMM D, YYYY h:mm A')	// January 1, 2019 3:12 PM
date.format(new Date(this.post.date_created*1000), 'MMM D, YYYY h:mm A')	// Jan 1, 2019 3:12 PM

to convert from mysql date to js Date, then to an alternate format:
let d = date.parse(data.results.created_at, 'YYYY-MM-DD HH:mm:ss')
date.format(d, 'MMM D, YYYY h:mm A')	// Jan 3, 2020 3:04 PM

token   meaning	  example
YYYY    year      0999, 2015
YY      year      05, 99
Y       year      2, 44, 888, 2015
MMMM    month     January, December
MMM     month     Jan, Dec
MM      month     01, 12
M       month     1, 12
DDD (*) day       1st, 2nd, 3rd
DD      day       02, 31
D       day       2, 31
dddd    day/week  Friday, Sunday
ddd     day/week  Fri, Sun
dd      day/week  Fr, Su
HH      24-hour   23, 08
H       24-hour   23, 8
A       meridiem  AM, PM
a (*)   meridiem  am, pm
AA (*)  meridiem  A.M., P.M.
aa (*)  meridiem  a.m., p.m.
hh      12-hour   11, 08
h       12-hour   11, 8
mm      minute    14, 07
m       minute    14, 7
ss      second    05, 10
s       second    5, 10
SSS     msec      753, 022
SS      msec      75, 02
S       msec      7, 0
Z       timezone  +0100, -0800
*/
